
import { Col, Layout, Select, Card, Row } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import STYLES from '../../box_styles'
// import '../index.css';
// https://react-icons.github.io/react-icons/#/
// https://stackoverflow.com/questions/39979325/partially-fill-a-shapes-border-with-colour
require('react-web-vector-icons/fonts');

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

export default class ShapesText extends React.Component {
    constructor(props) {
        super(props)


    }
    render() {

        var entries = this.props.style2
        // alert(entries)
        var items = []
        for (var i in entries) {
            var eachStyle = entries[i]
            var p = Object.assign({}, STYLES[eachStyle['_obj']], eachStyle['style'])
            // items.push(<font style={p,{webkitTransform:'rotate(-10deg)',display:'block', MozTransform:'rotate(-90deg)'}}>{eachStyle['_value']}</font>)
            // items.push(<font style={{WebkitTransform:'rotate(-90deg)'}}>{eachStyle['_value']}</font>)
            items.push(<div style={{ display: 'flex' }, p} >{eachStyle['_value']}</div>)
        }

        return (
            <div>
                {items}
            </div>
        )
    }
}

export default  { 
  PROBLEM_TYPE: {
    Number_Box: '數字箱',
    Number_Cross: '數字十字',
    Number_Pyramid: '數字金字塔',
  },
  LOGITICA_HEADER_1: "LOGITICA:",
  LOGITICA_HEADER_2: "數字拼圖" ,
  welcome: '選擇以下難題之一繼續',
  logitica_logo: '一起學習邏輯與數學',
  wisgo_copyright: '**(c) Wisgo Limited**',

  Number_box_Description: "**數字箱**由一個包含三個水平連接的數字單元的框組成，如示例1所示。每個數字箱的中間數字是通過使用在框的左側和右側指示的兩個數字來計算的。",
  Number_box_Description_1: "我們使用了多種算術運算來構建數字箱問題。",
  ParamScreen_Exam: "考試",
  PAGE: "頁",
  ParamScreen_Practice: "實踐",
  ParamScreen_More: "更多..",
  Level_1: '1級',
  Level_2: '2級',
  Level_3: '3級',
  Level_4: '4級',
  Level_5: '5級',
  ParamScreen_Number_Of_Question: '問題數',
  ParamScreen_Enable_Timer: '啟用計時器',
  ParamScreen_Enable_Rating: '啟用評分',
  ParamScreen_POSITIVE_NUMBER: '僅正數',
  Test_Find_Missing: '查找丟失的號碼。',
  Continue: '繼續',
  Reivew_Your_Answer: ' 查看您的答案',
  NEXT_BUTTON: '下一個',
  PREV_BUTTON: '上一個',
  REVIEW_BUTTON: '再檢查一遍',
  EVALUATE_BUTTON: '評估',
  SHOW_SUMMARY_BUTTON: '顯示摘要',
  HOME_BUTTON: '主頁',
  CONFIRM_BUTTON: '確認',




  CANCEL_BUTTON: '取消',
  OK_BUTTON: '確定',
  ATTEMPTED_YES: '是',
  ATTEMPTED_NO: '沒有',
  REVIEW_HEADER: '再檢查一遍?',
  YOUR_ANSWER_HEADER: '你的答案',
  CORRECT_ANSWER_HEADER: ' 正確',
  EXIT_CONFIRM_MSG: '你確定要離開？',
  EXIT_EXAM_CONFIRM_MSG: '您確定要退出此考試嗎？',
  ADDITION: '加法',
  ADDITION_POSITIVE_NUMBER: '加法（正數）',
  SUBTRACTION: '減法',
  MULTIPLICATION: '乘法',
  DIVISION: '除法',
  MIXED_OPERATION: '其他計算',
  SUM_OF_SQUARES: '平方和',
  ABOUT_NUMBER_BOX_PAGE: "關於數字箱",
  ABOUT_NUMBER_CROSS_PAGE: "關於數字十字",
  ABOUT_NUMBER_PYRAMID_PAGE: "關於數字金字塔",
  COPYRIGHT_PAGE: "版權",
  DISCLAIMER_PAGE: "免責聲明",
  TOTAL_NUMBER_OF_QUESTION: '總題數',
  ATTEMPTED: '嘗試的問題',
  CORRECT: '正確',
  CORRECT_YN: '正確?',
  TIME_SECONDS: '時間（秒）',
  SCORE_PERCENTAGE: '得分了（％）',
  SCORE: '得分了',
  RATING: '評分',
  RESULT: '成績單',
  RESULT_ITEM: '項目',
  RESULT_DETAIL: '詳情',
  TRY_NEXT: '嘗試另一個',
  REVIEW_COMMENT: '評論',
  SKIPPED_QUESTION_MSG: '您跳過了這個問題。',
  BACK_BUTTON: '返回',
  GO_TO_HOME_MSG: '您確定要轉到主頁嗎？單擊確定後，您將失去答案。',
  CONFIRM_TO_EVALUATE_MSG: '您確定要評估答案嗎？單擊確定後，您將無法更改答案。',
  START_BUTTON: '開始',
  PREPARING_QUESTIONS: '處理中..',
  PREPARING_QUESTIONS_MSG: '正在創建考試題，請稍候...', //todo check
  CLICK_START_CONTINUE: '單擊開始按鈕繼續。',
  FOR_PAID_VERSION_ONLY_LABEL: ' （僅限付費版本）',
  PURCHASE_FROM_APP_STORE: '從App Store購買 ',
  PURCHASE_FROM_GOOGLE_PLAY_STORE: '從Google Play商店購買',
  CLICK_TO_BUY_FULL_VERSION: '單擊以購買完整版本。',
  DISMISS_BUTTON: '關閉',
  CLICK_FOR_EVALUATION_MESSAGE: '單擊評估按鈕以檢查您的答案。',
  ANSWER_CORRECT: '您的回答是正確的：',
  ANSWER_WRONG: '您的答案是錯誤的：',
  NUMBER_BOX_EXPLANATION_5: "解決更多數字箱問題時，您會發現它涉及不同類型的算術運算，例如加，減，乘，平方和等。",
  FOR_EXAMPLE: '例如：',
  AND_SO_ON: '， 等等。',
  NUMBER_BOX_EXPLANATION_6: " (1) 這裡的a和b表示框的左側和右側單元格中的數字。",
  NUMBER_BOX_EXPLANATION_7: ' (2) 我們使用“ ^”表示指數。例如：2 ^ 3 = 2 x 2 x 2 = 8。',
  EXAMPLE: '例',
  EXPLANATION_OF_MIDDLE_NUMBER: '中間數字的說明：',
  EXPLANATION_OF_CENTER_NUMBER: '中間數字的說明：',
  NUMBER_PYRAMID_ROW_3: "第3行",
  NUMBER_PYRAMID_ROW_4: "第4行",
  NUMBER_PYRAMID_ROW_5: "第5行",
  NUMBER_PYRAMID_ROW_3_POSITIVE: "第3行（正數）",
  NUMBER_PYRAMID_ROW_4_POSITIVE: "第4行（正數）",
  NUMBER_BOX_FIRST_ROW: "第一箱",
  NUMBER_BOX_SECOND_ROW: "第二箱",
  NUMBER_BOX_THIRD_ROW: "第三箱",
  EXPLANATION_SOLUTION_MSG: '這是我們的答案。因此，缺少的數字是',
  NUMBER_CROSS_FIRST: '第一個數字',
  NUMBER_CROSS_SECOND: '第二個數字十字',
  NUMBER_CROSS_THIRD: '第三數字十字',
  NUMBER_CROSS_EXPLANATION_6: '數字十字是由五個以十字形排列的數字組成的結構，如下圖所示。每個十字數字的中間數字是使用十字中存在的四個數字計算得出的。我們使用了多種算術運算來構建數字交叉問題。',
  NUMBER_CROSS_EXPLANATION_7: '我們使用“ ^”表示指數。例如：2 ^ 3 = 2 x 2 x 2 = 8。',
  NUMBER_CROSS_EXPLANATION_8: '如果將l，t，r，b表示為十字的左，上，右，下側的數字，則根據問題，可以通過以下任一操作來確定中心的數字：',
  NUMBER_CROSS_EXPLANATION_9: '當您遇到更多的數字交叉問題時，您會發現它涉及不同類型的算術運算，例如加，減，乘，平方和等。',
  NUMBER_CROSS_EXPLANATION_10: '十字中心的數字是通過對四個周圍數字應用算術運算的某些組合來確定的。 “數字十字架”問題由三個十字架組成，並且已經提供了前兩個十字架中的所有數字。使用此信息，我們需要在第三個十字圖中找到缺失的數字。通常，確定所有中心的數字的邏輯必須相同。',
  REVIEW_PAGE_NUMBER_BOX_MISSING_NUMBER: '缺少的數字是：',
  REVIEW_PAGE_NUMBER_CROSS_MISSING_NUMBER: '缺少的數字是：',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y: '假設缺少的數字是y。',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y_AND_Z: '假設缺少的數字是y和z。',
  NUMBER_PYRAMID_USING_3_ROW_FORMULA: '使用3行公式：',
  NUMBER_PYRAMID_USING_4_ROW_FORMULA: '使用4行公式：',
  NUMBER_PYRAMID_USING_5_ROW_FORMULA: '使用5行公式：',
  NUMBER_PYRAMID_SHOW_CORRECT_ANSWER: '顯示正確答案',
  NUMBER_PYRAMID_SHOW_MY_ANSWER: '顯示我的答案',
  NOTE: '注意',
  NUMBER_PYRAMID_ABOUT: "數字金字塔是排列在金字塔中的單元格的集合。金字塔中的每個單元格都包含一個數字，該數字是其正下方的單元格中兩個數字的總和。在金字塔問題中，我們需要找到缺失的數字。",
  GO_TO_NUMBER_PYRAMID_MSG: '轉到“ **關於數字金字塔**”以了解有關該主題的更多信息。',
  NUMBER_PYRAMID_A_3_ROW: '三排金字塔',
  NUMBER_PYRAMID_A_4_ROW: '四排金字塔',
  NUMBER_PYRAMID_FORMULA_FOR_A_3_ROW: '3行金字塔的公式',
  NUMBER_PYRAMID_FORMULA_FOR_A_4_ROW: '4行金字塔的公式',
  EXPLANATION: '說明',
  NUMBER_PYRAMID_EXPLANATION_5: '當您解決更多數字金字塔問題時，您將學習有關解決簡單線性方程式的知識。',
  NUMBER_PYRAMID_ROW_2: '第2行',
  NUMBER_PYRAMID_ROW_3: '第3行',
  FORMULA_FOR_3_ROW_MSG: '3行的公式如下所示：',
  FORMULA_FOR_4_ROW_MSG: '4行的公式如下所示：',
  NUMBER_PYRAMID_MSG_1: '任意單元格中的數字是其下面兩個數字的總和，如下所示。',
  NUMBER_PAYRMID_MSG_2: '有關更多詳細信息，請單擊：繼續->更多...->關於數字金字塔'
 };
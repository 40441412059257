
export default  { 
  PROBLEM_TYPE: {
    Number_Box: '数字箱',
    Number_Cross: '数字十字',
    Number_Pyramid: '数字金字塔',
  },
  LOGITICA_HEADER_1: "LOGITICA:",
  LOGITICA_HEADER_2: "数字拼图" ,
  welcome: '选择以下难题之一继续',
  logitica_logo: '一起学习逻辑与数学',
  wisgo_copyright: '**(c) Wisgo Limited**',

  Number_box_Description: "**数字箱**由一个包含三个水平连接的数字单元的框组成，如示例1所示。每个数字箱的中间数字是通过使用在框的左侧和右侧指示的两个数字来计算的。",
  Number_box_Description_1: "我们使用了多种算术运算来构建数字箱问题。",
  ParamScreen_Exam: "考试",
  PAGE: "页",
  ParamScreen_Practice: "实践",
  ParamScreen_More: "更多..",
  Level_1: '1级',
  Level_2: '2级',
  Level_3: '3级',
  Level_4: '4级',
  Level_5: '5级',
  ParamScreen_Number_Of_Question: '问题数',
  ParamScreen_Enable_Timer: '启用计时器',
  ParamScreen_Enable_Rating: '启用评分',
  ParamScreen_POSITIVE_NUMBER: '仅正数',
  Test_Find_Missing: '查找丢失的号码。',
  Continue: '继续',
  Reivew_Your_Answer: ' 查看您的答案',
  NEXT_BUTTON: '下一个',
  PREV_BUTTON: '上一个',
  REVIEW_BUTTON: '再检查一遍',
  EVALUATE_BUTTON: '评估',
  SHOW_SUMMARY_BUTTON: '显示摘要',
  HOME_BUTTON: '主页',
  CONFIRM_BUTTON: '确认',
  CANCEL_BUTTON: '取消',
  OK_BUTTON: '确定',
  ATTEMPTED_YES: '是',
  ATTEMPTED_NO: '没有',
  REVIEW_HEADER: '再检查一遍?',
  YOUR_ANSWER_HEADER: '你的答案',
  CORRECT_ANSWER_HEADER: ' 正确',
  EXIT_CONFIRM_MSG: '你确定要离开？',
  EXIT_EXAM_CONFIRM_MSG: '您确定要退出此考试吗？',
  ADDITION: '加法',
  ADDITION_POSITIVE_NUMBER: '加法（正数）',
  SUBTRACTION: '减法',
  MULTIPLICATION: '乘法',
  DIVISION: '除法',
  MIXED_OPERATION: '其他计算',
  SUM_OF_SQUARES: '平方和',
  ABOUT_NUMBER_BOX_PAGE: "关于数字箱",
  ABOUT_NUMBER_CROSS_PAGE: "关于数字十字",
  ABOUT_NUMBER_PYRAMID_PAGE: "关于数字金字塔",
  COPYRIGHT_PAGE: "版权",
  DISCLAIMER_PAGE: "免责声明",
  TOTAL_NUMBER_OF_QUESTION: '总题数',
  ATTEMPTED: '尝试的问题',
  CORRECT: '正确',
  CORRECT_YN: '正确?',
  TIME_SECONDS: '时间（秒）',
  SCORE_PERCENTAGE: '得分了（％）',
  SCORE: '得分了',
  RATING: '评分',
  RESULT: '成绩单',
  RESULT_ITEM: '项目',
  RESULT_DETAIL: '详情',
  TRY_NEXT: '尝试另一个',
  REVIEW_COMMENT: '评论',
  SKIPPED_QUESTION_MSG: '您跳过了这个问题。',
  BACK_BUTTON: '返回',
  GO_TO_HOME_MSG: '您确定要转到主页吗？单击确定后，您将失去答案。',
  CONFIRM_TO_EVALUATE_MSG: '您确定要评估答案吗？单击确定后，您将无法更改答案。',
  START_BUTTON: '开始',
  PREPARING_QUESTIONS: '处理中..',
  PREPARING_QUESTIONS_MSG: '正在创建考试题，请稍候...', //todo check
  CLICK_START_CONTINUE: '单击开始按钮继续。',
  FOR_PAID_VERSION_ONLY_LABEL: ' （仅限付费版本）',
  PURCHASE_FROM_APP_STORE: '从App Store购买',
  PURCHASE_FROM_GOOGLE_PLAY_STORE: '从Google Play商店购买',
  CLICK_TO_BUY_FULL_VERSION: '单击以购买完整版本。',
  DISMISS_BUTTON: '关闭',
  CLICK_FOR_EVALUATION_MESSAGE: '单击评估按钮以检查您的答案。',
  ANSWER_CORRECT: '您的回答是正确的：',
  ANSWER_WRONG: '您的答案是错误的：',
  NUMBER_BOX_EXPLANATION_5: "解决更多数字箱问题时，您会发现它涉及不同类型的算术运算，例如加，减，乘，平方和等。",
  FOR_EXAMPLE: '例如：',
  AND_SO_ON: '， 等等。',
  NUMBER_BOX_EXPLANATION_6: " (1) 这里的a和b表示框的左侧和右侧单元格中的数字。",
  NUMBER_BOX_EXPLANATION_7: ' (2) 我们使用“ ^”表示指数。例如：2 ^ 3 = 2 x 2 x 2 = 8。',
  EXAMPLE: '例',
  EXPLANATION_OF_MIDDLE_NUMBER: '中间数字的说明：',
  EXPLANATION_OF_CENTER_NUMBER: '中间数字的说明：',
  NUMBER_PYRAMID_ROW_3: "第3行",
  NUMBER_PYRAMID_ROW_4: "第4行",
  NUMBER_PYRAMID_ROW_5: "第5行",
  NUMBER_PYRAMID_ROW_3_POSITIVE: "第3行（正数）",
  NUMBER_PYRAMID_ROW_4_POSITIVE: "第4行（正数）",
  NUMBER_BOX_FIRST_ROW: "第一箱",
  NUMBER_BOX_SECOND_ROW: "第二箱",
  NUMBER_BOX_THIRD_ROW: "第三箱",
  EXPLANATION_SOLUTION_MSG: '这是我们的答案。因此，缺少的数字是',
  NUMBER_CROSS_FIRST: '第一个数字',
  NUMBER_CROSS_SECOND: '第二个数字十字',
  NUMBER_CROSS_THIRD: '第三数字十字',
  NUMBER_CROSS_EXPLANATION_6: '数字十字是由五个以十字形排列的数字组成的结构，如下图所示。每个十字数字的中间数字是使用十字中存在的四个数字计算得出的。我们使用了多种算术运算来构建数字交叉问题。',
  NUMBER_CROSS_EXPLANATION_7: '我们使用“ ^”表示指数。例如：2 ^ 3 = 2 x 2 x 2 = 8。',
  NUMBER_CROSS_EXPLANATION_8: '如果将l，t，r，b表示为十字的左，上，右，下侧的数字，则根据问题，可以通过以下任一操作来确定中心的数字：',
  NUMBER_CROSS_EXPLANATION_9: '当您遇到更多的数字交叉问题时，您会发现它涉及不同类型的算术运算，例如加，减，乘，平方和等。',
  NUMBER_CROSS_EXPLANATION_10: '十字中心的数字是通过对四个周围数字应用算术运算的某些组合来确定的。 “数字十字架”问题由三个十字架组成，并且已经提供了前两个十字架中的所有数字。使用此信息，我们需要在第三个十字图中找到缺失的数字。通常，确定所有中心的数字的逻辑必须相同。',
  REVIEW_PAGE_NUMBER_BOX_MISSING_NUMBER: '缺少的数字是：',
  REVIEW_PAGE_NUMBER_CROSS_MISSING_NUMBER: '缺少的数字是：',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y: '假设缺少的数字是y。',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y_AND_Z: '假设缺少的数字是y和z。',
  NUMBER_PYRAMID_USING_3_ROW_FORMULA: '使用3行公式：',
  NUMBER_PYRAMID_USING_4_ROW_FORMULA: '使用4行公式：',
  NUMBER_PYRAMID_USING_5_ROW_FORMULA: '使用5行公式：',
  NUMBER_PYRAMID_SHOW_CORRECT_ANSWER: '显示正确答案',
  NUMBER_PYRAMID_SHOW_MY_ANSWER: '显示我的答案',
  NOTE: '注意',
  NUMBER_PYRAMID_ABOUT: "数字金字塔是排列在金字塔中的单元格的集合。金字塔中的每个单元格都包含一个数字，该数字是其正下方的单元格中两个数字的总和。在金字塔问题中，我们需要找到缺失的数字。",
  GO_TO_NUMBER_PYRAMID_MSG: '转到“ **关于数字金字塔**”以了解有关该主题的更多信息。',
  NUMBER_PYRAMID_A_3_ROW: '三排金字塔',
  NUMBER_PYRAMID_A_4_ROW: '四排金字塔',
  NUMBER_PYRAMID_FORMULA_FOR_A_3_ROW: '3行金字塔的公式',
  NUMBER_PYRAMID_FORMULA_FOR_A_4_ROW: '4行金字塔的公式',
  EXPLANATION: '说明',
  NUMBER_PYRAMID_EXPLANATION_5: '当您解决更多数字金字塔问题时，您将学习有关解决简单线性方程式的知识。',
  NUMBER_PYRAMID_ROW_2: '第2行',
  NUMBER_PYRAMID_ROW_3: '第3行',
  FORMULA_FOR_3_ROW_MSG: '3行的公式如下所示：',
  FORMULA_FOR_4_ROW_MSG: '4行的公式如下所示：',
  NUMBER_PYRAMID_MSG_1: '任意单元格中的数字是其下面两个数字的总和，如下所示。',
  NUMBER_PAYRMID_MSG_2: '有关更多详细信息，请单击：继续->更多...->关于数字金字塔'
 };
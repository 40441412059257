export default class AbstractSheet {

    constructor(listOfThings) {
        this.listOfThings = listOfThings
        this.internalMap = {}
        for (const index in this.listOfThings) {
            const current = this.listOfThings[index];
            this.internalMap[current.getProblemRef()] = current

        }
    }

    findThing(problemRef) {
        return this.internalMap[String(problemRef)]
    }

    static findThingFor(sheet, problemRef) {
        return sheet.internalMap[String(problemRef)]
    }

    getAllThings() {
        return this.listOfThings
    }

    getCountOfThings() {
        if (typeof this.listOfThings !== 'undefined') {
            return this.listOfThings.length;
        }
        return 0;
    }
}
import { Button, Layout, Popconfirm } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import strings from '../../resource/i18n_resource';
import CommonConstants from '../common_constant';
import '../index.css';
import UserSession from '../user_session';
import ExamManager from '../exam/exam_manager';
import * as ExamConfig from '../exam/exam_config'
import ExamApi from '../api/exam_api';
import UIPageRouterCommon from './ui_page_router_common';


const { Header, Content, Footer, Sider } = Layout;
class ExitExamDialog extends React.Component {
    constructor(props) {
        super(props)

    }
    cancelExamPopup() {
        UIPageRouterCommon.closeTheCurrentExam(this)
    
    }
    
    findExamSheet() {
        const studentRef = this.getStudentRef()
        return UserSession.getInstance().getUserExamSheet()
    }

    getStudentRef() {
        return CommonConstants.STUDENT_REF;
    }
    render() {
        var examSheet = UserSession.getInstance().getUserExamSheet()
        var status = examSheet == null ? null : examSheet.status
        var examInProgress = status == ExamConfig.EXAM_SHEET_STATUS.ATTEMPTING
        var buttonText = examInProgress ? strings.CANCEL_EXAM : strings.START_A_NEW_EXAM

        return (
            <>
                <Popconfirm placement="topLeft" title={<div>{strings.EXAM_EXIT_CONFIRM}</div>} onCancel={() => this.cancelExamPopup()} okText={strings.EXIT_NO} cancelText={strings.EXIT_EXAM_YES}>
                    <Button style={{ fontSize: '15px', height: '50px', marginRight: '20px' }} type="primary" danger ghost >{buttonText}</Button>
                </Popconfirm>
            </>
        )

    }
}

export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <ExitExamDialog location={location} history={history} />
    )
});

import CommonConstants from "./common_constant";
import CurrentExam from "./api/current_exam";
import * as ExamConfig from "./exam/exam_config";
import UserSelection from "./exam/user_selection";
import UserSelectionEntry from "./exam/user_selection_entry";

export default class UserSession {
    private static _instance: UserSession
    private currentExamSheet: CurrentExam | null = null;
    private authenticated: boolean = false;
    private sessionCode: number = -1;
    userExamSession: { [key: string]: number } = {}
    private currentExam: CurrentExam | null = null;
    constructor() {

        if (!UserSession._instance) {
            UserSession._instance = this;
            this.rebuildSelection()

        }
        
        this.resetCurrentExam()
        return UserSession._instance;
    }
    rebuildSelection() {
        var defaultProblem = ExamConfig.PROBELM_TYPE_KEY.LogicMatrix
        var defaultLevel = ExamConfig.PROBLEM_LEVEL.ONE
        var defaultProblemCount = ExamConfig.PROBLEM_COUNT.FIVE
        var userSelection = new UserSelection()
        var entry = new UserSelectionEntry()
        entry.setProblemCount(defaultProblemCount)
        entry.setProblemLevel(defaultLevel)
        entry.setProblemType(defaultProblem)
        userSelection.addUserSelectionEntry(entry)
        UserSession._instance.setUserSelection(userSelection)
    }
    static getInstance() {
        if (!UserSession._instance) {
            UserSession._instance = new UserSession()
        }
        return UserSession._instance;
    }
    registerForExam(examSheet) {
        if (examSheet.problemCount == 0) {
            return
        }
        console.log("examSheet returned from server")
        console.log(examSheet)
        var studentRef = UserSession._instance.getStudentRef();
        console.log("registering for exam student ref " + studentRef)
        this.userExamSession[studentRef] = examSheet
        this.currentExamSheet = examSheet
        this.currentExam = new CurrentExam(examSheet, 0, 0)
    }
    resetCurrentExam() {
        this.currentExam = new CurrentExam(null, 0, 0)
    }
    getCurrentExam() {
        return this.currentExam
    }
    registerPostEvaulationAnswer(rightAnswerSheet) {
        this.rightAnswerSheet = rightAnswerSheet
    }
    findRightAnswerFor(problemRef) {
        console.log('finding for ')
        console.log(problemRef)
        console.log(this.rightAnswerSheet.internalMap)
        var rightAnswer = this.rightAnswerSheet.internalMap[problemRef].answerValue
        console.log("rightAnswer")
        console.log(rightAnswer)
        return rightAnswer
    }
    findHintFor(problemRef) {
        console.log('finding for ')
        console.log(problemRef)
        console.log(this.rightAnswerSheet.internalMap)
        return this.rightAnswerSheet.internalMap[problemRef].hint
    }


    deregisterForExam(studentRef) {
        this.currentExamSheet = null
        this.rightAnswerSheet = null
        this.userExamSession = {}
        // delete userExamSession[studentRef]
        this.resetCurrentExam()
    }
    determineProblemLevel() {
        if (this.userSelection != undefined) {
            return this.userSelection.findExamLevel()
        }
        return -8888

    }
    isExamInProgress() {
        console.log("this.userExamSession")
        console.log(this.userExamSession)
        var examSheet = this.getUserExamSheet()

        var status = examSheet == null ? null : examSheet.status
        return status == ExamConfig.EXAM_SHEET_STATUS.ATTEMPTING

        // return this.userExamSession.has(this.getStudentRef())
    }
    getUserExamSheet() {
        return this.currentExam.getExamSheet()
    }
    getStudentRef() {
        return CommonConstants.STUDENT_REF;
    }
    setUserSelection(userSelection) {
        this.userSelection = userSelection
    }

    getUserSelection() {
        return this.userSelection
    }
    isAuthenticated() {
        return this.authenticated
    }
    validateCode(value) {
        this.authenticated = false
        if (value) {
            if (String(value).length > 3 && String(Math.PI).includes(value)) {
                this.authenticated = true
            }
        }
        return this.authenticated

    }
    updateSessionCode(sessionCode) {
        this.sessionCode = sessionCode
    }
    getSessionCode() {
        return this.sessionCode
    }
}
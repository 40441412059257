import { Layout } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import NumberBox from '../../types/number_box/screen/number_box';
import '../index.css';
import * as ExamConfig from "../exam/exam_config";
import UserSession from '../user_session';
import LogicExamPage from '../../types/logic/screen/logic_exam_page';

// import ExamConfig from "../../../common/exam/exam_config";
const { Header, Content, Footer, Sider } = Layout;
export default class ExamPageMiddle extends React.Component {
    constructor(props) {
        super(props)

    }
    buildMiddlePage() {
        var currentProblem = this.props.currentProblem
        console.log("currentProblem")
        console.log(currentProblem)
        let correctAnswer = "?"
        let examSheet = UserSession.getInstance().getUserExamSheet()
        console.log("examSheet after selection")
        console.log(examSheet)
        let evaluatedAlready = examSheet!=null && (examSheet.status == ExamConfig.EXAM_SHEET_STATUS.EVALUATED 
            || examSheet.status == ExamConfig.EXAM_SHEET_STATUS.COMPLETED)
        let solutionHint = ""
        if(evaluatedAlready){
            // alert('evaluatedAlready')
            correctAnswer=UserSession.getInstance().findRightAnswerFor(this.props.problemRef)
            console.log("correctAnswer---->")
            console.log(correctAnswer)
            solutionHint=UserSession.getInstance().findHintFor(this.props.problemRef)
            // alert(correctAnswer.answerValue)
        }
        if (currentProblem._problemType == ExamConfig.PROBELM_TYPE_KEY.NumberBox) {
            return ((
                <NumberBox problemRef={this.props.problemRef}
                evaluatedAlready={evaluatedAlready}
                correctAnswer={correctAnswer}
                solutionHint={solutionHint}
                userAnswer={this.props.userAnswer}
                    currentProblem={this.props.currentProblem}
                    actionNameCallbackFn={this.props.actionNameCallbackFn} />
            ))
        }
        else if (currentProblem._problemType == ExamConfig.PROBELM_TYPE_KEY.LogicMatrix) {
            return ((
                
                <LogicExamPage problemRef={this.props.problemRef}
                evaluatedAlready={evaluatedAlready}
                correctAnswer={correctAnswer}
                solutionHint={solutionHint}
                userAnswer={this.props.userAnswer}
                    currentProblem={this.props.currentProblem}
                    actionNameCallbackFn={this.props.actionNameCallbackFn} />
            ))
        }
        else {
            return (<div>Not configured {currentProblem._problemType}</div>)
        }
    }

    render() {
        return (
            this.buildMiddlePage()
        )
    }
}




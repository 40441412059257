import axios from 'axios';
import ExamApi from '../api/exam_api';
import UserSession from "../user_session";
import ExamHandler from '../api/exam_handler';

export default class ExamManager {


    constructor() {

        if (!ExamManager._instance) {
            ExamManager._instance = this;
        }
        this.examSession = {}
        return ExamManager._instance;
    }
    static getInstance() {
        if (!ExamManager._instance) {
            ExamManager._instance = new ExamManager()
        }
        return ExamManager._instance;
    }

    evaluateExamSheet(studentRef, uiRouter) {
            ExamHandler.evaluateExamFlow(uiRouter)
            return
    
    }
    startTheExam(uiRouter){
        ExamHandler.startExam(uiRouter)
    }
    

    deregisterExam(examSheet) {
        let studentRef = examSheet.studentRef
        let examRef = examSheet.examRef
        delete this.examSession[examRef]
        UserSession.getInstance().deregisterForExam(studentRef)
    }

}


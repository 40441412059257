import {Layout} from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import UserSession from '../user_session';
import ExamHandler from '../api/exam_handler';
import UIPageRouterCommon from './ui_page_router_common';
import ExamManager from '../exam/exam_manager';
import LoginHandler from "../api/login_handler.js";
import AppRoutes from "../../app_routes.js";
import DataAccessApi from "./data_access_api.js";
import AppConstant from "./app_constant.js";

const {Header, Content, Footer, Sider} = Layout;
export default class FlowManager {

    static startNewExamFlow(uiRouter) {
        console.log("starting a new exam")
        uiRouter.setState({'examLoadingStatusMessage': 'Loading a new exam, please wait ...'});
        if (UserSession.getInstance().getUserSelection() == null) {
            console.log("No selection done.....")
            return
        }

        if (UserSession.getInstance().getUserExamSheet() == null) {
            ExamManager.getInstance().startTheExam(uiRouter)
        }

    }

    static evaluateExamFlow(uiRouter) {
        const studentRef = UserSession.getInstance().getStudentRef()
        ExamManager.getInstance().evaluateExamSheet(studentRef, uiRouter)
    }

    static cancelCurrentExam() {

    }

    static chooseExam() {

    }

    static EvaluateCurrentExam() {

    }

    static reviewCurrentResult() {

    }

    static retryTheCurrentExam(self) {
        console.log("Retry new exam")
        ExamHandler.retryThisAgain(self)
    }

    static myExamHistories(self) {
        console.log("My Exam Histories")
        ExamHandler.getMyAllExams(self)
    }

    static loadMyExam(self, ref) {
        console.log("loading my exam " + ref)
        ExamHandler.getMyExam(self, ref)
    }

    static tryLogin(self, username, password) {
        console.log("try logging .... " + username + " pwd " + password)
        LoginHandler.tryLogin(self, username, password)
    }

    static goToMyPage(self) {
        // alert("sending")
        var userRole = DataAccessApi.getUserInfo()._role
        // console.log(userRole)
        // alert(userRole)
        if (userRole === AppConstant.ADMIN_ROLE) {
            // alert("admin")
            self.props.history.push({
                pathname: AppRoutes.ADMIN_PAGE,
            })
        } else {
            // alert("non admin")
            self.props.history.push({
                pathname: AppRoutes.MY_PAGE,
            })
        }

    }


}

import { Checkbox, Divider, Layout, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import '../index.css';

const CheckboxGroup = Checkbox.Group;

// const plainOptions = ['Apple', 'Pear', 'Orange'];
// const defaultCheckedList = ['Apple', 'Orange'];


const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
export default class UserSubSelectionPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedList: this.props.plainOptions,
            selectedCheckList: this.props.selectedOptions,
            // indeterminate: true,
            // setIndeterminate: true,
            // checkAll: false,
            // setCheckAll: false
        }


    }
    onChange = (selectedList) => {
        // alert('onChange')
        // alert(selectedList)
        this.setState({
            selectedCheckList: selectedList
            // setIndeterminate: !!selectedList.length && selectedList.length < this.props.plainOptions.length,
            // setCheckAll: !selectedList.length === this.props.plainOptions.length
        })
        console.log("inside onchange " + this.state.selectedCheckList)

    };

    getSelectionOptions() {
        return this.state.selectedCheckList
    }


    render() {
        console.log("inside render " + this.state.selectedCheckList)
        // this.state.selectedCheckList=[]
        return (
            <div style={{ paddingBottom: '20px', width: '40%' }}>
                {/* <Checkbox indeterminate={this.state.indeterminate} onChange={(value)=>this.onCheckAllChange(value)} checked={this.state.checkAll}>
                    Check all
              </Checkbox> 
                 <Divider /> */}
                <Divider orientation="left" plain>
                    {this.props.label}:
    </Divider>
                <CheckboxGroup options={this.state.checkedList} defaultValue={this.state.selectedCheckList} onChange={(value) => this.onChange(value)} />


            </div>
        );
    }

}

// export default withRouter(({ location }) => {
//     // const classes = useStyles();
//     const history = useHistory();
//     console.log("location----->")
//     console.log(location)
//     return (


//         <UserSelectionPage location={location} history={history} />
//     )
// });

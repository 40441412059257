import axios from 'axios';
import UserSession from '../user_session';
import ApiHelper from './api_helper';
import ServerApi from './server_api';

// export const API_URL = "serv"; //TODO for prod
// export const API_URL = "";
export default class MyExamAPi {
    static getAll(body, sessionCode, updateResultFn, updateErrorFn) {
        let url = ApiHelper.get_api_path() + "/api/myexam/1"
        ServerApi.sendToServer(body, url, 'get', sessionCode, updateResultFn, updateErrorFn)
    }
    static getOne(examRef:string, sessionCode, updateResultFn, updateErrorFn) {
        let url = ApiHelper.get_api_path() + "/api/myexam/1/"+examRef
        ServerApi.sendToServer(null, url, 'get', sessionCode, updateResultFn, updateErrorFn)
    }
    static get(self, callBackFn) {
        let url = ApiHelper.get_api_path() + "/api/exam/1"
        let headerConfig = {}
        axios.get(url, headerConfig)
            .then((response) => {
                return response.data
            })
            .then((data) => {
                callBackFn(self, data)
                // this.createNewExamSheet(data)
            })
            .catch(console.log)
    }

    static post(body, sessionCode, updateResultFn, updateErrorFn) {
        let url = ApiHelper.get_api_path() + "/api/myexam/1"
        ServerApi.sendToServer(body, url, 'post', sessionCode, updateResultFn, updateErrorFn)

    }


}

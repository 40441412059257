import * as Icons_AI from "react-icons/ai";
import * as Icons_BI from "react-icons/bi";
import * as Icons_BS from "react-icons/bs";
import * as Icons_CG from "react-icons/cg";
import * as Icons_DI from "react-icons/di";
import * as Icons from "react-icons/fa";
import * as Icons_FI from "react-icons/fi";
import * as Icons_GI from "react-icons/gi";
import * as Icons_GO from "react-icons/go";
import * as Icons_GR from "react-icons/gr";
import * as Icons_HI from "react-icons/hi";
import * as Icons_IO4 from "react-icons/io";
import * as Icons_IO5 from "react-icons/io5";
import * as Icons_MD from "react-icons/md";
import * as Icons_RI from "react-icons/ri";
import * as Icons_SI from "react-icons/si";
import * as Icons_TI from "react-icons/ti";
import * as Icons_FC from "react-icons/fc";
import * as Icons_IM from "react-icons/im";

export default class IconFinder {
    static findIconSet(iconName) {
        var IconComponent = Icons[iconName];
        if (IconComponent == undefined) {
            IconComponent = Icons_BS[iconName]
        }

        if (IconComponent == undefined) {
            IconComponent = Icons_BI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_MD[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_RI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_CG[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GI[iconName]

        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GR[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_HI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_SI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_TI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_FI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_IO5[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_IO4[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GO[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_DI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_AI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_FC[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_IM[iconName]
        }





        return IconComponent
    }
}
import UserSession from '../user_session'
import ExamManager from '../exam/exam_manager'
import ExamApi from './exam_api'
import MyExamAPi from './my_exam_api'
import AppConstant from '../screen/app_constant'
import UIPageRouterCommon from '../screen/ui_page_router_common'
class ServerRepnoseHandler {
    constructor(updateFn, updateErrorFn) {
        this.updateFn = updateFn
        this.updateErrorFn = updateErrorFn
    }


}
export default class ExamHandler {
    static getMyAllExams(self) {
        

        var updateFn = (examSheet) => {
            self.notifyReady(self, examSheet, AppConstant.MY_EXAM_HISTORIES_RESPONSE)
        }

        var updateErrorFn = (serverMessage) => {
            self.notifyError(serverMessage)
        }
        var sessionCode = UserSession.getInstance().getSessionCode()
        let body = {
            "limit": "last 10 exams",
        }
        // var userSelection = UserSession.getInstance().getUserSelection()
        MyExamAPi.getAll(body,sessionCode, updateFn, updateErrorFn)

    }
    static getMyExam(self, examRef) {
        

        var updateFn = (examSheet) => {
            self.notifyReady(self, examSheet, AppConstant.LOAD_EXAM_RESPONSE)
        }

        var updateErrorFn = (serverMessage) => {
            self.notifyError(serverMessage)
        }
        var sessionCode = UserSession.getInstance().getSessionCode()
        let body = {
            "limit": "last 10 exams",
        }
        // var userSelection = UserSession.getInstance().getUserSelection()
        MyExamAPi.getOne(examRef,sessionCode, updateFn, updateErrorFn)

    }
    static retryThisAgain(self) {
        UserSession.getInstance().resetCurrentExam()
        let body = {
            "action": "Retry_Exam",
            "kv_pair": {
                "orig_exam_ref": self.props.location.origExamRef
            }
        }

        var updateFn = (examSheet) => {
            UserSession.getInstance().registerForExam(examSheet);
            self.notifyReady(self, examSheet, AppConstant.RETRY_EXAM_CREATED)
        }

        var updateErrorFn = (serverMessage) => {
            self.notifyError(serverMessage)
        }
        var sessionCode = UserSession.getInstance().getSessionCode()

        // var userSelection = UserSession.getInstance().getUserSelection()
        MyExamAPi.post(body, sessionCode, updateFn, updateErrorFn)
    }
    static startExam(uiRouter) {

        if (UserSession.getInstance().getUserSelection() == null) {
            return
        }
        const examSheet = UserSession.getInstance().getUserExamSheet()
        if (examSheet == null) {
            var responseHandler = ExamHandler.createResponseHandler(uiRouter, AppConstant.EXAM_CREATED)
            var sessionCode = UserSession.getInstance().getSessionCode()
            var userSelection = UserSession.getInstance().getUserSelection()
            return ExamApi.post(userSelection, sessionCode, responseHandler.updateFn, responseHandler.updateErrorFn)

        }
        else {
            ExamHandler.setQuestionPage(examSheet)
        }
    }


    static createResponseHandler(uiRouter, successResponseAction) {
        var updateFn = (examSheet) => {
            console.log("received result----> ")
            console.log(uiRouter)
            uiRouter.notifyReady(uiRouter, examSheet, successResponseAction)
        }

        var updateErrorFn = (serverMessage) => {
            console.log("serverMessage<><><><>><")
            console.log(serverMessage)
            uiRouter.notifyError(uiRouter, serverMessage)
        }
        return new ServerRepnoseHandler(updateFn, updateErrorFn)
    }

    static evaluateExamFlow(uiRouter) {
        const studentRef = UserSession.getInstance().getStudentRef()
        let examSheet = UserSession.getInstance().getUserExamSheet()
        var sessionCode = UserSession.getInstance().getSessionCode()
        let userSelection = examSheet.userSelection
        let examResult = examSheet.examResult
        let status = examSheet.status
        let userAnswerSheet = examSheet.userAnswerSheet
        var examRef = examSheet.examRef
        let simpleObj = {
            'examRef': examRef,
            'userSelection': userSelection,
            'examResult': examResult,
            'status': status,
            'studentRef': studentRef,
            'userAnswerSheet': userAnswerSheet,
        }
        var sessionCode = UserSession.getInstance().getSessionCode()

        var responseHandler = ExamHandler.createResponseHandler(uiRouter, AppConstant.EVALATE_EXAM_COMPLETED_RESPONSE)
        var sessionCode = UserSession.getInstance().getSessionCode()
        
        
        ExamApi.put(examRef, simpleObj, sessionCode, responseHandler.updateFn, responseHandler.updateErrorFn)


    }

    static createNewExamSheet(examSheet) {
        console.log("examSheet received")
        UserSession.getInstance().registerForExam(examSheet)
    }

}
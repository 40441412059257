const MARGIN_BOTTOM = '40px'
const MARGIN_BOTTOM_EVALUATE = '10px'
const BOX_STYLES = {
  width: '90px',
  height: '50px',
  borderColor: 'black',
  borderWidth: '3px',
  fontSize: 28,
  textAlign: 'center',

}
const _TRIANGLE2 = {

  width: 0,
  height: 0,
  backgroundColor: 'transparent',
  borderStyle: 'solid',
  borderTopWidth: 0,
  borderRightWidth: 20,
  borderBottomWidth: 35,
  borderLeftWidth: 20,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'blue',
  borderLeftColor: 'transparent',
  transform: 'rotate(-90deg)',
  // border: '3px solid black',
  
}
const _SQUARE = {
  display: "inline-block",
  // position:'absolute',
  backgroundColor: 'white',
  borderLeft: '20px solid black',
  borderRight: '3px solid black',
  borderTop: '3px solid black',
  borderBottom: '3px solid black',
  // border: '3px solid black',
  // borderRadius: "50%",
  width: 60,
  height: 60,
  left: 0,
  top: 0
}

const _TRIANGLE = {
  width: 0,
  height: 0,
  backgroundColor: "transparent",
  borderStyle: "solid",
  borderTopWidth: 0,
  borderLeftWidth: 40,
  borderRightWidth: 40,
  borderBottomWidth: 70,
  borderLeftColor: "transparent",
  borderRightColor: "transparent",
  borderBottomColor: "red",
  
  borderBottomColor: 'linearGradient(to right, #430089, #82ffa1)',
  // borderBottomColor: 'linear-gradient(to right, #430089, #82ffa1)'
  // linear-gradient(to bottom, Transparent 0%,Transparent 50%,red 50%,red 100%
}


const _ARROW= {
  border: 'solid black',
  borderWidth: '0 3px 3px 0',
  display: 'inline-block',
  padding: '10px',
  margin:'1px',
  transform: 'rotate(-45deg)'
  // width:40
}

const _RIGHT_CC ={
  transform: 'rotate(-225deg)'
}
const _LEFT_CC ={
  transform: 'rotate(-45deg)'
}
// .left {
//   transform: rotate(135deg);
//   -webkit-transform: rotate(135deg);
// }

// .up {
//   transform: rotate(-135deg);
//   -webkit-transform: rotate(-135deg);
// }

// .down {
//   transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
// }

const _BOX_COMMON_MIDDLE = {
  ...BOX_STYLES,
  color: 'black',
  background: 'black',
  color: 'white',
}
const MAIN_COLOR = {
  backgroundColor: '#FFEED5',
}
const EXAM_PAGE_STYLE_MAIN = {
  backgroundColor: 'white', //hack #FFEED5
  // height: "calc(100vh)"
  height: "400vh"
  // "calc(100vh - 155px)" 
}
const _BOX_COMMON_RIGHT = {
  ...BOX_STYLES,
  marginRight: '50px',
  color: 'black',
}

const _BOX_COMMON_LEFT = {
  ...BOX_STYLES,
  color: 'black',
}
const BOX_STYLES_MIDDLE = {
  ..._BOX_COMMON_MIDDLE,
  marginBottom: MARGIN_BOTTOM

}
const BOX_STYLES_MIDDLE_SOLVE = {
  ...BOX_STYLES_MIDDLE,
  color: 'black',
  background: 'black',
  color: 'yellow',
}
const BOX_STYLES_LEFT = {
  ..._BOX_COMMON_LEFT,
  marginBottom: MARGIN_BOTTOM

}
const BOX_STYLES_RIGHT = {
  ..._BOX_COMMON_RIGHT,
  marginBottom: MARGIN_BOTTOM

}
// const BOX_STYLES_RIGHT_EVALUATED = {

//   marginBottom: MARGIN_BOTTOM_EVALUATE,
//   ..._BOX_COMMON_RIGHT,

// }
const BOX_STYLES_LEFT_EVALUATED = Object.assign({}, BOX_STYLES_LEFT, { marginBottom: MARGIN_BOTTOM_EVALUATE });
const BOX_STYLES_MIDDLE_EVALUATED = Object.assign({}, BOX_STYLES_MIDDLE_SOLVE, { marginBottom: MARGIN_BOTTOM_EVALUATE });
const BOX_STYLES_RIGHT_EVALUATED = Object.assign({}, _BOX_COMMON_RIGHT, { marginBottom: MARGIN_BOTTOM_EVALUATE });

const TRIANGLE_UP= {
	width: 0,
	height: 0,
	borderLeft: '25px solid transparent',
	borderRight: '25px solid transparent',
	borderBottom: '50px solid blue',
}
const RECTANGLE= {
	width: 50,
	height: 50,
  // backgroundColor:'white',
  border:'1px solid black',
  // background:'repeating-linear-gradient(-45deg,transparent 0 20px,black 20px 40px);',
  // background:'repeating-linear-gradient(rgba(250,0,0,0.5),transparent)',
  // background:'linear-gradient(to right, #430089, #82ffa1)',
  // background:'repeating-linear-gradient( 45deg,white,black 3%)',
  // background:'repeating-linear-gradient( 45deg,white,black 3%)',
  // background:'repeating-linear-gradient(45deg,yellow, yellow 10px,red 10px,red 20px)',
  // background:'repeating-linear-gradient(45deg,white, black 5px)',
  // background:'linear-gradient(to bottom, ${colors[0]} 0%, ${colors[1]} 100%)',
  // background:'linear-gradient( white,black 5%)',
  // backgroundColor:'orange' 
}
const TEE= {
  // width:0,
  // height:0,
  borderLeft: '25px solid black',
  borderRight: '25px solid black',
  borderBottom: '25px solid transparent',
  borderTop: '25px solid black',
	// borderRight: '25px solid red',
	
	// borderTop: '30px solid white ',
  // background:'repeating-linear-gradient(-45deg,transparent 0 20px,black 20px 40px);',
  // background:'repeating-linear-gradient(rgba(250,0,0,0.5),transparent)',
  // background:'linear-gradient(to right, #430089, #82ffa1)',
  // background:'repeating-linear-gradient( 45deg,white,black 3%)',
  // background:'repeating-linear-gradient( 45deg,white,black 3%)',
  // background:'repeating-linear-gradient(45deg,yellow, yellow 10px,red 10px,red 20px)',
  // background:'repeating-linear-gradient(45deg,white, black 5px)',
  // background:'linear-gradient(to bottom, ${colors[0]} 0%, ${colors[1]} 100%)',
  // background:'linear-gradient( white,black 5%)',
  // backgroundColor:'orange' 
}
const RECTANGLE_SMALL= {
  
  width: 20,
	height: 20,
  backgroundColor:'black',
  transform: 'rotate(-45deg)',
  background:'repeating-linear-gradient(45deg,white, black 5px)',
}
const RECTANGLE_SMALL_FILL= {
  
  width: 20,
	height: 20,
  backgroundColor:'black',
  transform: 'rotate(-45deg)',
  background:'black',
}
 const GRID_CONTAINER={
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  // transform:'rotate(90deg)',
  // backgroundColor: '#2196F3',
  // backgroundColor: '#2196F3',
  // padding: '10px',
  margin:'10px'
  
}
const GRID_ITEM_EMPTY= {
  // 'backgroundColor': 'rgba(255, 255, 255, 0.8)',
  'backgroundColor': 'transparent',
  // 'border': '1px solid rgba(0, 0, 0, 0.8)',
  // 'padding': '20px',
  'fontSize': '30px',
  // outline: '1px solid, transparent',
  'textAlign': 'center',
  width:'30px',
  margin: '1px',
  height:'30px',
  outline: '2px solid transparent',
  
  
}


const GRID_ITEM= {
  // 'backgroundColor': 'rgba(255, 255, 255, 0.8)',
  'backgroundColor': 'white',
   outline: '3px solid black',
  // 'padding': '20px',
  'fontSize': '30px',
  'textAlign': 'center',
  width:'30px',
  height:'30px',
  margin:'1px'
  
}
const GRID_ITEM_COLOR= {
  'backgroundColor': 'black',
  outline: '1px solid black',
  margin: '1px',
  'textAlign': 'center',
  width:'30px',
  height:'30px',
  outline: '3px solid',
  outlineColor:'black'
}
const RECTANGLE_SMALL3= {
	width: 20,
	height: 20,
  backgroundColor:'black',
  transform: 'rotate(-45deg)',
  // border:'3px solid black',
}
const RECTANGLE_SMALL2= {
	width: 20,
	height: 20,
  backgroundColor:'black',
  transform: 'rotate(-45deg)',
  // backgroundImage: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVQYV2NkIAKckTrzn5GQOpAik2cmjHgVwhSBDMOpEFkRToXoirAqxKYIQyEuRSgK8SmCKySkCKyQGEUghQD+Nia8BIDCEQAAAABJRU5ErkJggg==")
  // border:'3px solid black',
}


export default {
  BOX_STYLES,
  BOX_STYLES_MIDDLE,
  BOX_STYLES_MIDDLE_SOLVE,
  BOX_STYLES_LEFT,
  BOX_STYLES_RIGHT,
  BOX_STYLES_RIGHT_EVALUATED,
  BOX_STYLES_LEFT_EVALUATED,
  BOX_STYLES_MIDDLE_EVALUATED,
  EXAM_PAGE_STYLE_MAIN,
  MAIN_COLOR,
  _TRIANGLE,
  _TRIANGLE2,
  _SQUARE,
  _ARROW,
  _RIGHT_CC,
  TRIANGLE_UP,
  RECTANGLE,
  RECTANGLE_SMALL,
  RECTANGLE_SMALL_FILL,
  TEE,
  GRID_ITEM,
  GRID_CONTAINER,
  GRID_ITEM_COLOR,GRID_ITEM_EMPTY
}


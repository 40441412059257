import { Button, Col, Layout, Popconfirm, Row, Space } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { withRouter } from "react-router";
import { Redirect } from 'react-router'
import { useHistory } from "react-router-dom";
import strings from '../../resource/i18n_resource';
import CommonConstants from '../common_constant';
import ExamManager from '../exam/exam_manager';
import '../index.css';
import { QuestionNumber } from '../question_number';
import QuestionPage from '../question_page';
import UserSession from '../user_session';
import ExamPageMiddle from './exam_page_middle';
import ExitExamDialog from './exit_exam_dialog';
import ExamApi from '../api/exam_api';
import STYLES from '../../types/box_styles'
// import ExamConfig from "./exam_config"
import axios from 'axios';
import * as ExamConfig from "../exam/exam_config";
import StopwatchUI from '../util/stopwatch_ui';
import StopwatchDisplay from '../util/stopwatch_display';
import Stopwatch from '../util/stopwatch';
import PageUtil from './page_util';
import UserAnswerSheet from './user_answer_sheet';
import ExamHandler from '../api/exam_handler';
const { Header, Content, Footer, Sider } = Layout;
export default class UIRouterStatusMessage{
    constructor(status, message, buttonText, pageActionFnForRetry, enableButton, retryCount) {
        this.status=status
        this.message=message
        this.buttonText=buttonText
        this.pageActionFnForRetry=pageActionFnForRetry
        this.enableButton=enableButton
        this.retryCount=retryCount
        

    }
}
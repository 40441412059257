
import { Col, Row, Layout, Select, Radio } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import STYLES from '../../../types/box_styles'
// import '../index.css';
// https://react-icons.github.io/react-icons/#/
// https://stackoverflow.com/questions/39979325/partially-fill-a-shapes-border-with-colour
require('react-web-vector-icons/fonts');

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

export default class ShapeOptions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        // alert('hi')
        console.log("this.props._comp")
        console.log(this.props._comp)
        var options=null
        var border2 = '2px solid black'
        if (this.props._value) {
            options = <div style={{ fontSize: 35, textAlign: 'center', }}>
                ({this.props._value}) <Radio value={this.props._value} ></Radio>
            </div>
            
        }
        if(this.props.noBorder){
            border2=""
        }
        

        return (

            <Col style={{ width: 200, height: 220, margin: '20px' }}  >

                <Row style={{ height: '200px', border: border2, alignItems: 'center', justifyContent: 'center' }}>
                    <div >
                        {this.props._comp}
                    </div>
                    {/* <div style={{...STYLES.GRID_ITEM_EMPTY, height: '160px'}}>
                   
                </div> */}
                </Row>
                <Row style={{ height: '32%', justifyContent: 'center' }}>
                    {options}
                </Row>
            </Col>
        )
    }
}
export default class UserSelectionEntry {
    getProblemType() {
        return this.problemType
    }
    setProblemType(problemType) {
        this.problemType = problemType
    }
    getProblemLevel() {
        return this.problemLevel
    }
    setProblemLevel(problemLevel) {
        return this.problemLevel = problemLevel
    }
    setProblemCount(problemCount) {
        return this.problemCount = problemCount
    }
    getProblemCount() {
        return this.problemCount
    }
    getFilterMetaKeyTags(){
        return this.filterMetaKeyTags
    }
    setFilterMetaKeyTags(values){
        this.filterMetaKeyTags=values
    }
    getFilterMetaCategoryKeyTags(){
        return this.filterMetaCategoryKeyTags
    }
    setFilterMetaCategoryKeyTags(values){
        this.filterMetaCategoryKeyTags=values
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import { withRouter } from "react-router";
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link, useHistory } from "react-router-dom";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
const { Header, Content, Footer, Sider } = Layout;
 class NumberUI extends React.Component {
    render() {
        return <Content style={{ margin: '0 16px',alignSelf:'left' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             Number Logic UI
            </div>
          </Content>
       
    }
}


  
  export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
      <NumberUI location={location}  history={history} />
    )
  });
  

import STYLES from '../../types/box_styles';
export default class ShapeBuilder {
    ShapeBuilder() {

    }
    build(settings) {
        // settings = [settings]
        var shapeBuilder = new ShapePropertyBuilder().start();
        settings.forEach((s, index1) => {
            var props = []
            s.forEach((entry, index2) => {
                var p = Object.assign({}, STYLES[entry['_obj']], entry)
                props.push(p)
                
            })
            shapeBuilder.next(props)
        })

        // settings.forEach((entry, index) => {
        //     var p = Object.assign({}, STYLES[entry['_obj']], entry)
        //     shapeBuilder.next(p)
        // })
        return shapeBuilder.build()
    }

}
class ShapePropertyBuilder {

    ShapePropertyBuilder() {
        // this.properties ={}
        this.propertiesList = []
        // alert(this.propertiesList)
    }

    start() {
        this.propertiesList = []
        return this;
    }

    next(prop) {

        this.propertiesList.push(JSON.parse(JSON.stringify(prop)))
        return this;
    }

    
    build() {
        return this.propertiesList
    }

}
class ShapePropertyBuilder2 {

    ShapePropertyBuilder2() {
        // this.properties ={}
        // this.propertiesList =[]
        // alert(this.propertiesList)
    }

    start(prop) {
        this.startProp = prop
        // this.properties = STYLES.RECTANGLE
        this.properties = prop
        this.propertiesList = []
        return this;
    }
    next() {
        // alert(this.propertiesList)
        // alert((JSON.stringify(this.properties)))
        this.propertiesList.push(JSON.parse(JSON.stringify(this.properties)))
        this.properties = this.startProp
        // this.properties ={}

        return this;
    }
    rotate(rot_angel) {
        this.properties = Object.assign({}, this.properties, { transform: `rotate(${rot_angel})` })
        return this;
    }
    background(color) {
        this.properties = Object.assign({}, this.properties, { backgroundColor: color })
        return this;
    }
    strip() {
        this.properties = Object.assign({}, this.properties, { background: 'repeating-linear-gradient(45deg,white, black 5px)' })
        // repeating-linear-gradient(45deg,white, black 5px)
        return this
    }
    build() {
        this.next()
        // this.propertiesList.push(JSON.parse(JSON.stringify(this.properties)))
        return this.propertiesList
    }

}
import axios from 'axios';
import UserSession from '../user_session';
import ApiHelper from './api_helper';
import ErrorMessages from '../exam/error_messages';
import ServerApi from './server_api';

// export const API_URL = "serv"; //TODO for prod
// export const API_URL = "";
export default class ExamApi {
    

    // static get(self, callBackFn) {
    //     let url = ExamApi.get_api_path() + "/api/exam/1"
    //     let headerConfig = {}
    //     axios.get(url, headerConfig)
    //         .then((response) => {
    //             return response.data
    //         })
    //         .then((data) => {
    //             callBackFn(self, data)
    //             // this.createNewExamSheet(data)
    //         })
    //         .catch(console.log)
    // }

    

    static post(body, sessionCode, updateResultFn, updateErrorFn) {
        let url = ApiHelper.get_api_path() + "/api/exam/1"
        ServerApi.sendToServer(body, url, 'post', sessionCode, updateResultFn, updateErrorFn)

    }

    static put(examRef, body, sessionCode, updateResultFn, updateErrorFn) {
        let url = ApiHelper.get_api_path() + "/api/exam/" + examRef
        ServerApi.sendToServer(body, url, 'put', sessionCode, updateResultFn, updateErrorFn)

    }


    
    static closeExam() {
        // alert("evaluting")
        // UserSession
        let examSheet = UserSession.getInstance().getUserExamSheet()
        // let currentExam = UserSession.getInstance().getCurrentExam()
        console.log(examSheet)
        let examRef = examSheet.examRef
        axios({
            method: 'delete',
            url: ApiHelper.get_api_path() + '/api/exam/' + examRef,
            data: examRef
        })
            .then((response) => {
                console.log(response)
                console.log(response.data)

                return response.data
            })
            .catch(console.log)

        return
    }
}

import UserSelectionEntry from "./user_selection_entry";

export default class UserSelection {
    private userSelectionEntries: UserSelectionEntry[] = [];
    private userSelectionEntryMap: { [key: string]: UserSelectionEntry } = {};
    private defaultProblemType: string = "";



    addUserSelectionEntry(userSelectionEntry: UserSelectionEntry) {
        this.userSelectionEntryMap[userSelectionEntry.getProblemType()] = userSelectionEntry
        this.userSelectionEntries.push(userSelectionEntry)
        this.defaultProblemType = userSelectionEntry.getProblemType()
    }

    findExamLevel() {
        if (this.userSelectionEntries != undefined && this.userSelectionEntries.length > 0) {
            return this.userSelectionEntries[0].getProblemLevel()
        }
        return -9999
    }

    determineTotalProblemCount() {
        var total = 0
        for (const [key, value] of Object.entries(this.userSelectionEntryMap)) {
            total += value.getProblemCount()
        }
        return total
    }

    findDefaultProblemType() {
        return this.defaultProblemType

    }

    // setDefaultProblemType(problemType){
    //     this.defaultProblemType=problemType
    // }
    findSelectedLevel() {
        return this.findEntryByProblemType(this.findDefaultProblemType()).getProblemLevel()
    }

    findSelectedCategory() {
        return this.findEntryByProblemType(this.findDefaultProblemType()).getProblemLevel()
    }

    findSelectedCount() {
        return this.findEntryByProblemType(this.findDefaultProblemType()).getProblemCount()
    }

    findSelectedCategories() {
        return this.findEntryByProblemType(this.findDefaultProblemType()).getFilterMetaCategoryKeyTags()
    }

    findSelectedSubCategories() {
        return this.findEntryByProblemType(this.findDefaultProblemType()).getFilterMetaKeyTags()
    }

    findSelectedEntry() {
        return this.findEntryByProblemType(this.defaultProblemType)
    }

    findEntryByProblemType(problemType: string) {
        return this.userSelectionEntryMap[problemType]
    }

    setProblemType(problemType: string) {
        let finalMap: { [key: string]: UserSelectionEntry } = {}
        let finalEntries = []
        console.log("starting default ")
        for (const [key, value] of Object.entries(this.userSelectionEntryMap)) {
            finalMap[problemType] = value
            value.setProblemType(problemType)
            finalEntries.push(value)
        }
        this.defaultProblemType = problemType
        this.userSelectionEntries = finalEntries
        this.userSelectionEntryMap = finalMap
        console.log("setting default ")
        console.log(problemType)
    }

}
import {Button, Col, Layout, Row, Select} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import strings from '../../resource/i18n_resource';
import * as ExamConfig from "../exam/exam_config";
import '../index.css';
import UserSelection from '../exam/user_selection.ts';
import UserSelectionEntry from '../exam/user_selection_entry';
import UserSession from '../user_session';
import PageUtil from './page_util';
import UserSubSelectionPage from './user_sub_selection_page';
import UIPageRouterCommon from './ui_page_router_common';

const {Option} = Select;
const {Header, Content, Footer, Sider} = Layout;

class UserSelectionPage extends React.Component {
    constructor(props) {
        super(props)


        this.problemTypeDDRef = React.createRef();
        this.state = {
            loaded: 'done', showCategory: false
        }
        this.typeRef = React.createRef()
        this.subTypeRef = React.createRef()

    }


    startExamNow() {

        var categoryKeys = null
        var subCategoryKeys = null
        if (this.state.showCategory) {
            categoryKeys = this.typeRef.current.getSelectionOptions()
            subCategoryKeys = this.subTypeRef.current.getSelectionOptions()
        }
        UIPageRouterCommon.startExamNow(this, categoryKeys, subCategoryKeys)


    }

    handleProblemTypeChange(value) {

        var userSelection:UserSelection = UserSession.getInstance().getUserSelection()
        userSelection.setProblemType(value)
        console.log("setting value-->")
        this.setState({loaded: 'done'})
    }

    handleSetProblemCount(value) {
        var entry = this.findSelectedEntry();
        if (entry != undefined) {
            entry.problemCount = value
        }
        this.setState({loaded: 'done'})
    }

    findSelectedEntry() {
        var userSelection = UserSession.getInstance().getUserSelection();
        var entry = userSelection.findSelectedEntry();
        return entry;
    }

    handleSetProblemLevel(value) {
        var entry = this.findSelectedEntry();
        if (entry != undefined) {
            entry.problemLevel = value
        }
        this.setState({loaded: 'done'})
    }


    render() {


        var userSelection = UserSession.getInstance().getUserSelection()
        // alert(userSelection.findDefaultProblemType())
        if (userSelection.findDefaultProblemType() == ExamConfig.PROBELM_TYPE_KEY.LogicMatrix) {
            this.state.showCategory = true
        } else {
            this.state.showCategory = false
        }
        console.log("render")
        if (PageUtil.isPageRefreshed()) {
            if (!UserSession.getInstance().isAuthenticated()) {
                PageUtil.setGoToPage(this, "/")
            }

        }
        var userSelection = UserSession.getInstance().getUserSelection()
        let defaultSetProblem = userSelection.findDefaultProblemType()
        let defaultSetLevel = ExamConfig.PROBLEM_LEVEL.ONE
        let defaultSetCount = ExamConfig.PROBLEM_COUNT.TEN
        var entry = userSelection.findEntryByProblemType(userSelection.findDefaultProblemType())
        console.log("entry------>")
        console.log(entry)
        console.log(userSelection)
        var categoryOption = ['numbers', 'shapes', 'grids', 'geometries']
        var selectedCategoryOption = ['shapes', 'grids', 'geometries']

        var subCategoryOption = ['color', 'rotation', 'position', 'none', 'increment']
        var selectedSubCategoryOption = ['color', 'rotation', 'position', 'none', 'increment']

        if (entry != undefined) {
            defaultSetLevel = userSelection.findSelectedLevel()
            defaultSetCount = userSelection.findSelectedCount()
            // var categorySelected = userSelection.findSelectedCategories()
            if (userSelection.findSelectedCategories() != undefined) {
                selectedCategoryOption = userSelection.findSelectedCategories()
            }
            if (userSelection.findSelectedSubCategories() != undefined) {
                selectedSubCategoryOption = userSelection.findSelectedSubCategories()
            }


        }
        // if(selectedCategoryOption)
        // alert(selectedCategoryOption)

        return <Content style={{margin: '0 16px', alignSelf: 'left'}}>
            <div style={{fontSize: '40px'}}>Select Exam Level</div>
            <Row>
                <Col style={{width: '100px'}}>{strings.SELECT_PROBLEM}</Col>
                <Col>
                    <Select ref={this.problemTypeDDRef} value={defaultSetProblem}
                            style={{width: 170, margin: '0px 0px 15px 15px'}}
                            onChange={(value) => this.handleProblemTypeChange(value)}>
                        {/* <Option value={ExamConfig.PROBELM_TYPE_KEY.NumberBox}>{ExamConfig.PROBELM_TYPE_KEY.NumberBox}</Option> */}
                        <Option
                            value={ExamConfig.PROBELM_TYPE_KEY.LogicMatrix}>{ExamConfig.PROBELM_TYPE_KEY.LogicMatrix}</Option>
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col style={{width: '100px'}}>{strings.SELECT_LEVEL}</Col>
                <Col>
                    <Select defaultValue={ExamConfig.PROBLEM_LEVEL.ONE} value={defaultSetLevel}
                            style={{width: 170, margin: '0px 0px 15px 15px'}}
                            onChange={(value) => this.handleSetProblemLevel(value)}>
                        <Option value={ExamConfig.PROBLEM_LEVEL.MINUS_1}>Elementry (Age &le;6) </Option>
                        <Option
                            value={ExamConfig.PROBLEM_LEVEL.ZERO}>Level {ExamConfig.PROBLEM_LEVEL.ZERO} (Age &le;7) </Option>
                        <Option
                            value={ExamConfig.PROBLEM_LEVEL.ONE}>Level {ExamConfig.PROBLEM_LEVEL.ONE} (Age &le;8) </Option>
                        <Option
                            value={ExamConfig.PROBLEM_LEVEL.TWO}>Level {ExamConfig.PROBLEM_LEVEL.TWO} (Age &le;10)</Option>
                        <Option
                            value={ExamConfig.PROBLEM_LEVEL.THREE}>Level {ExamConfig.PROBLEM_LEVEL.THREE} (Age &gt;10)</Option>
                        <Option
                            value={ExamConfig.PROBLEM_LEVEL.FOUR}>Level {ExamConfig.PROBLEM_LEVEL.FOUR} (Age &gt;10)</Option>
                        {/* <Option value={ExamConfig.PROBLEM_LEVEL.HIGHER}>{ExamConfig.PROBLEM_LEVEL.HIGHER}</Option> */}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col style={{width: '100px'}}>{strings.SELECT_PROBLM_COUNT}</Col>
                <Col>
                    <Select defaultValue={defaultSetCount} style={{width: 170, margin: '0px 0px 15px 15px'}}
                            onChange={(value) => this.handleSetProblemCount(value)}>
                        <Option value={ExamConfig.PROBLEM_COUNT.ONE}>{ExamConfig.PROBLEM_COUNT.ONE}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.FIVE}>{ExamConfig.PROBLEM_COUNT.FIVE}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.TEN}>{ExamConfig.PROBLEM_COUNT.TEN}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.TWENTY}>{ExamConfig.PROBLEM_COUNT.TWENTY}</Option>
                        <Option
                            value={ExamConfig.PROBLEM_COUNT.TWENTY_FIVE}>{ExamConfig.PROBLEM_COUNT.TWENTY_FIVE}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.THRITY}>{ExamConfig.PROBLEM_COUNT.THRITY}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.FIFTY}>{ExamConfig.PROBLEM_COUNT.FIFTY}</Option>
                        <Option value={ExamConfig.PROBLEM_COUNT.HUNDRED}>{ExamConfig.PROBLEM_COUNT.HUNDRED}</Option>
                        {/* <Option value={ExamConfig.PROBLEM_COUNT.ONE_THOUSAND}>{ExamConfig.PROBLEM_COUNT.ONE_THOUSAND}</Option> */}
                        {/* <Option value={ExamConfig.PROBLEM_COUNT.ONE_THOUSAND}>{ExamConfig.PROBLEM_COUNT.ONE_THOUSAND}</Option> */}
                        {/* <Option value={ExamConfig.PROBLEM_COUNT.HUNDRED}>{ExamConfig.PROBLEM_COUNT.HUNDRED}</Option> */}
                    </Select>
                </Col>
            </Row>
            <div>
                {this.state.showCategory && <UserSubSelectionPage ref={this.typeRef} plainOptions={categoryOption}
                                                                  selectedOptions={selectedCategoryOption}
                                                                  label="Type"/>}
                {this.state.showCategory && <UserSubSelectionPage ref={this.subTypeRef} plainOptions={subCategoryOption}
                                                                  selectedOptions={selectedSubCategoryOption}
                                                                  label="Category"/>}

            </div>


            <Button style={{fontSize: '20px', height: '40px', marginRight: '20px'}} type="primary"
                    onClick={() => this.startExamNow()}>{strings.START_EXAM}</Button>
            <Button style={{fontSize: '20px', height: '40px', marginRight: '20px'}} type="primary"
                    onClick={() => UIPageRouterCommon.getMyExamHistories(this)}>{strings.MY_EXAM_HISTORY}</Button>
        </Content>

    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    console.log("location----->")
    console.log(location)
    return (


        <UserSelectionPage location={location} history={history}/>)
});

import {Button, Input, Layout, Select, Space} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import strings from '../../resource/i18n_resource';
import '../index.css';
import UserSession from '../user_session';
import UIPageRouterCommon from './ui_page_router_common';
import FlowManager from "./flow_manager.js";
import AppConstant from "./app_constant.js";
import DataAccessApi from "./data_access_api.js";

const {Option} = Select;
const {Header, Content, Footer, Sider} = Layout;

class MyPage extends React.Component {
    constructor(props) {
        super(props)

    }



    render() {
        var userInfo =DataAccessApi.getUserInfo()
        console.log(userInfo._role)
        // console.log("userInfo")
        // console.log(JSON.parse(userInfo))
        console.log(localStorage)
        // console.log(DataAccessApi.getAccessToken())
        // console.log(DataAccessApi.getUserInfo())
        // console.log(DataAccessApi.getUserInfo()._role)

        // const dbPromise = idb.open('test-db1', 1);
        return <Content style={{margin: '0 16px', alignSelf: 'left'}}>
            <div style={{fontSize: '30px'}}>Logitica </div>
            <Space align="center" style={{fontSize: '20px'}}>
                {userInfo._role}

              {/*My Page {userInfo['_role']}*/}
              {/*My Page {userInfo}*/}
              {/*My Page {userInfo._role}*/}
            </Space>
            <p/>

        </Content>

    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <MyPage location={location} history={history}/>
    )
});

import {Button, Layout} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import ErrorMessages from '../exam/error_messages';
import '../index.css';
import AppConstant from './app_constant';
import FlowManager from './flow_manager';
import PageUtil from './page_util';
import UIPageRouterCommon from './ui_page_router_common';
import UIPageRouterCore from './ui_page_router_core';
import UIRouterStatusMessage from './ui_router_status_messgae';

const {Header, Content, Footer, Sider} = Layout;

class SimpleRouter extends React.Component {
    constructor(props) {
        super(props)
        // console.log(examSheet)
        // var currentExam = UserSession.getInstance().getUserExamSheet()

        console.log("props ------><")
        console.log(props)

        this.state = {
            'loadingStatus': 'loaded',
            'retryCount': 0,
            'pageAction': null,
            'errorMsg': null,
            serverError: ErrorMessages.ERROR_NONE
        }


    }

    notifyReady(uiRouter, examSheet, pageAction) {
        console.log("message received" + pageAction)

        // var handled = UIPageRouterCore.handleIncomeFromServer(uiRouter, examSheet, pageAction)
        // if (!handled) {
        //     console.log("no page action configured")
        // }
    }

    notifyError(self, serverError) {
        // console.log("error received" + pageAction)
        console.log("error happended ????", serverError)
        console.log(serverError)
        console.log(self)
        var currentRetry = this.state.retryCount
        var retryMsg = 'loading exam, failed, try again: retry count =' + currentRetry
        var status = 'Failed'
        this.setState({serverError: serverError})
        console.log("serverMessage")
        console.log("serverMessage")
        console.log(serverError)
        console.log(serverError)
        // alert('ready')
        if (currentRetry > 10) {
            retryMsg = 'loading exam, failed more than 10 times. Try closing browser and start over again.' + currentRetry
            status = 'Fatal'
        }

    }


    componentDidMount() {

        console.log("componentDidMount ui router2")
        var routerUI = this
        var pageActionValue = routerUI.props.history.location.pageAction
        console.log("page action " + pageActionValue)
        var callBackComponent = routerUI.props.history.location.callBackComponent
        var statusMessage = null
        routerUI.state.pageAction = pageActionValue
        var handled = this._handlePageAction(routerUI, pageActionValue, callBackComponent)

        if (!handled) {
            console.log("UI router has no action ")
        }

    }

    render() {
        console.log("status ")
        console.log(this.state.serverError)
        console.log(this.state.serverError['userMessage'])
        // alert('hi')
        console.log("rendering ui router")
        // alert('hi')
        // if (PageUtil.isPageRefreshed()) {
        //     PageUtil.setToGoToHomePage(this)
        //     return (<div/>)
        // }

        const maxRetry = this.state.serverError['maxRetry'];
        const retrySoFar = this.state.retryCount
        var allowRetry = retrySoFar < maxRetry
        var retryPossible = maxRetry > 0 && allowRetry
        var userErrorMessage = this.state.serverError['userMessage'] + ' retry count ' + retrySoFar
        var waiting = this.state.serverError == ErrorMessages.ERROR_NONE
        if (waiting) {
            userErrorMessage = "Please wait, while we are processing request"
        } else if (!retryPossible) {
            userErrorMessage = "Exceeded retry"
        }


        return (
            <div>
                <div>
                    Hello this is simple loading {this.state.pageAction}
                </div>
                <div>
                    Server Response {userErrorMessage}
                </div>
                <div>
                    {retryPossible &&
                        <Button style={{fontSize: '15px', height: '50px', marginRight: '20px'}} type="primary"
                                onClick={() => this._handlePageAction(this)}>Retry Again</Button>}

                </div>
            </div>
        )
    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <SimpleRouter location={location} history={history}/>
    )
});


import { Col, Layout, Radio, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import * as Icons_AI from "react-icons/ai";
import * as Icons_BI from "react-icons/bi";
import * as Icons_BS from "react-icons/bs";
import * as Icons_CG from "react-icons/cg";
import * as Icons_DI from "react-icons/di";
import * as Icons from "react-icons/fa";
import * as Icons_FI from "react-icons/fi";
import * as Icons_GI from "react-icons/gi";
import * as Icons_GO from "react-icons/go";
import * as Icons_GR from "react-icons/gr";
import * as Icons_HI from "react-icons/hi";
import * as Icons_IO from "react-icons/io5";
import * as Icons_MD from "react-icons/md";
import * as Icons_RI from "react-icons/ri";
import * as Icons_SI from "react-icons/si";
import * as Icons_TI from "react-icons/ti";
import IconFinder from "./Icon_finder"
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
// import '../index.css';
import ShapeBuilder from "../shape_builder";
import ShapeUtil from "./shape_util";
import Shapes from "./shapes";
import ShapeOptions from "./shape_options";
import STYLES from '../../../types/box_styles'
import LogicApi from '../../../common/api/logic_api';
import UserSession from '../../../common/user_session';
import { Divider } from 'antd';
import ExamApi from '../../../common/api/exam_api';
import UserAnswerSheet from '../../../common/screen/user_answer_sheet';
import { toHumanSize } from 'i18n-js';
import ResultRemarks from './result_remarks';
// import SSQaure from "./s_square";
// import STriangle from "./s_triangle";
// https://react-icons.github.io/react-icons/#/
require('react-web-vector-icons/fonts');

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

export default class LogicExamPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { examSheet: null }


    }
    createNewExamSheet(self, data) {
        // alert(data)
        console.log('got it')
        // console.log(data)
        self.setState({
            examSheet: data
        })
    }


    componentDidMount() {
        // if(UserSession.getInstance().getUserSelection()==null){
        //     return
        // }
        // if (this.state.examSheet == null) {
        //     var self = this
        //     // ExamApi.get(this, this.createNewExamSheet)
        //     // ExamApi.post()
        //     let body = {
        //         "userSelectionEntries": [
        //             {
        //                 "problemCount": 30,
        //                 "problemLevel": 1,
        //                 "problemType": "Logic Matrix"
        //             }
        //         ],
        //         "userSelectionEntryMap": {
        //             "Number Box": {
        //                 "problemCount": 30,
        //                 "problemLevel": 1,
        //                 "problemType": "Logic Matrix"
        //             }
        //         }
        //     }
        //     ExamApi.post(self, body, this.createNewExamSheet)

        // }
        // else {
        //     this.setQuestionPage()
        // }

    }
    drawText(textValue, textStyle) {
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}  >

                <label style={textStyle}>{textValue}</label>
            </Col>
        )
        // return 

    }
    findIconSet(iconName) {
        var IconComponent = Icons[iconName];
        if (IconComponent == undefined) {
            IconComponent = Icons_BS[iconName]
        }

        if (IconComponent == undefined) {
            IconComponent = Icons_BI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_MD[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_RI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_CG[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GI[iconName]

        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GR[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_HI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_SI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_TI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_FI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_IO[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_GO[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_DI[iconName]
        }
        if (IconComponent == undefined) {
            IconComponent = Icons_AI[iconName]
        }





        return IconComponent
    }
    drawPic2(moreItems) {
        var items = []
        // items.length
        for (var i = 0; i < moreItems.length; i++) {
            // alert(moreItems)
            var count = moreItems[i].count
            var iconName = moreItems[i].iconName
            var style = moreItems[i].style
            for (var j = 0; j < count; j++) {
                var IconComponent = this.findIconSet(iconName)


                items.push(<IconComponent size={40} style={style} />)

            }
        }
        return (
            this.wrapStack(items)
        )
    }
    drawPic(moreItems) {
        var items = []
        // items.length
        for (var i = 0; i < moreItems.length; i++) {
            // alert(moreItems)
            var count = moreItems[i].count
            var iconName = moreItems[i].iconName
            var style = moreItems[i].style
            for (var j = 0; j < count; j++) {
                var IconComponent = this.findIconSet(iconName)


                items.push(<IconComponent size={40} style={style} />)

            }
        }
        return (
            // this.wrap(items)
            items
        )
    }
    wrap(comp) {
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}  >

                {comp}
            </Col>
        )
    }
    wrapStack(comp) {
        var items = []
        for (var c in comp) {
            items.push(<div>{comp[c]}</div>)
        }
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'top', }}  >

                {items}
            </Col>
        )
    }
    circleStyle(overrides) {
        const defaultSettings = {
            // padding: 10,
            // margin: 20,
            display: "inline-block",
            // position:'absolute',
            backgroundColor: 'black',
            borderRadius: "50%",
            width: 80,
            height: 80,

            left: 0,
            top: 0,
            alignItems: 'center',
            display: 'flex', justifyContent: 'center'
        };

        // if(overrides!=undefined){
        //     for (const [key, value] of Object.entries(overrides)) {
        //         defaultSettings[key]=value
        //     }
        // }
        return defaultSettings

    }
    triangleStyle(overrides) {
        const defaultSettings = {
            width: 0,
            height: 0,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderTopWidth: 0,
            borderRightWidth: 20,
            borderBottomWidth: 35,
            borderLeftWidth: 20,
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: 'white',
            borderLeftColor: 'transparent',
        };
        if (overrides != undefined) {
            for (const [key, value] of Object.entries(overrides)) {
                defaultSettings[key] = value
            }
        }

        return defaultSettings

    }
    squareStyle(overrides) {
        const defaultSettings = {
            display: "inline-block",
            // position:'absolute',
            backgroundColor: 'red',
            // borderRadius: "50%",
            width: 30,
            height: 30,
            left: 0,
            top: 0
        };
        if (overrides != undefined) {
            for (const [key, value] of Object.entries(overrides)) {
                defaultSettings[key] = value
            }
        }
        return defaultSettings
    }

    drawShape(shape1, shape2) {


        return (
            <>
                {/* <div style={circleStyle} ></div> */}
                {/* <div style={circleStyle} >
                <div style={square}></div>
            </div> */}
                <div style={shape1} >
                    <div style={shape2}></div>
                </div>
            </>
        );
    }

    bulid_something() {

    }
    onChangeRadio(ref, actionCallBackFn) {
        return e => {
            let changedValue = e.target.value
            actionCallBackFn()(changedValue)
            // alert('radio3 checked :' + e.target.value + " ref:" + ref);
            this.setState({
                value3: e.target.value,
            });
        }

    };
    // onChangeRadio(ref) = e => {
    //     alert('radio3 checked :'+ e.target.value);
    //     this.setState({
    //         value3: e.target.value,
    //     });
    // };

    static drawIcon(moreItems) {
        var items = []
        // items.length
        for (var i = 0; i < moreItems.length; i++) {
            // alert(moreItems)
            var count = moreItems[i].count
            var iconName = moreItems[i].iconName
            var style = moreItems[i].style
            for (var j = 0; j < count; j++) {
                var IconComponent = IconFinder.findIconSet(iconName)


                items.push(<IconComponent size={40} style={style} />)

            }
        }
        return (ShapeUtil.wrap(items)
            // this.wrap(items)

        )
    }
    onChangeOption = e => {
        console.log('radio3 checked', e.target.value);
        this.setState({
            value3: e.target.value,
        });
    };


    render() {
        console.log("this.props logic matrix")
        console.log(this.props)
        var examSheet = UserSession.getInstance().getUserExamSheet()
        if (!examSheet) {
            return <div>loading....</div>
        }

        // examSheet.userAnswerSheet
        var userSelected = UserAnswerSheet.findUserAnswerFor(examSheet.userAnswerSheet, this.props.problemRef)
        // exam_sheet = this.props.examSheet
        var settings2 = examSheet.problemSheet.internalMap
        var userSelectedOption = ""
        if (userSelected) {
            userSelectedOption = userSelected.userAnswerValue
        }
        let remarks = ""
        let resultRemarks = <div />
        if (this.props.evaluatedAlready) {
            resultRemarks = ResultRemarks.findResultRemarksDiv(this.props)
            
            console.log("resultRemarks found")
            console.log(resultRemarks)
            // alert(resultRemarks)
        }

        // var answerAllowed = true
        // alert(userSelectedOption)

        var shapesList = []
        // var options = this.buildOptions
        var self = this
        var currentProblem = this.props.currentProblem
        var setting = currentProblem["problems"]
        var answerOptions = currentProblem["options"]
        shapesList.push(
            <Row>
                {ShapeUtil.drawIconAndShapes(setting)}
            </Row>)


        if (this.props.evaluatedAlready) {
            shapesList.push(resultRemarks)
        }
        else {
            shapesList.push(<div style={{ "fontSize": "20px" }}>Select one</div>)
        }
        shapesList.push(<Divider style={{ backgroundColor: 'grey', height: "2px" }} dashed></Divider>)
        shapesList.push(<Row>
            <Radio.Group disabled={this.props.evaluatedAlready} key={this.props.problemRef} defaultValue={userSelectedOption} onChange={self.onChangeRadio(this.props.problemRef, this.props.actionNameCallbackFn)}>
                {ShapeUtil.buildOptions(answerOptions)}
            </Radio.Group>
        </Row>)
        shapesList.push(<Divider style={{ backgroundColor: 'black', height: "10px" }}></Divider>)
        // Object.keys(settings2).map(function (key) {
        //     // alert(key)
        //     var setting = settings2[key]["problems"]
        //     var answerOptions = settings2[key]["options"]
        //     shapesList.push(
        //     <Row>
        //         {ShapeUtil.drawIconAndShapes(setting)}
        //     </Row>)
        //     shapesList.push(<div style={{ "fontSize": "20px" }}>Select one</div>)
        //     shapesList.push(<Row>
        //         <Radio.Group onChange={self.onChangeRadio(key)}>
        //             {ShapeUtil.buildOptions(answerOptions)}
        //         </Radio.Group>
        //     </Row>

        //     )
        // });
        return (
            <div>
                <div style={{ borderWidth: "10px", borderColor: 'red' }}    >
                    {shapesList}
                </div>
            </div>
        )

    }
}



// export default withRouter(({ location }) => {
//     // const classes = useStyles();
//     const history = useHistory();
//     return (
//         <LogicExamPage location={location} history={history} />
//     )
// });


export default  {
  PROBLEM_TYPE: {
    Number_Box: '숫자 상자',
    Number_Cross: '숫자 십자가',
    Number_Pyramid: '숫자 피라미드',
  },
  LOGITICA_HEADER_1: "LOGITICA:",
  LOGITICA_HEADER_2: "숫자 퍼즐" ,
  welcome: '계속하려면 다음 퍼즐 중 하나를 선택하세요.',
  logitica_logo: '함께 논리와 수학 배우기',
  wisgo_copyright: '**(c) Wisgo Limited**',

  Number_box_Description: "** 숫자 상자 **는 아래 예 -1에서와 같이 가로로 연결된 3 개의 _number-cells_를 포함하는 상자로 구성됩니다. 각 상자의 중간 수는 상자의 왼쪽과 오른쪽에 표시된 두 개의 숫자를 사용하여 계산됩니다.",
  Number_box_Description_1: "우리는 숫자 상자 문제를 만들기 위해 다양한 산술 연산을 사용했습니다.",
  ParamScreen_Exam: "시험",
  PAGE: "페이지",
  ParamScreen_Practice: "연습",
  ParamScreen_More: "자세히 ..",
  Level_1: '레벨 1',
  Level_2: '레벨 2',
  Level_3: '레벨 3',
  Level_4: '레벨 4',
  Level_5: '레벨 5',
  ParamScreen_Number_Of_Question: '질문 수',
  ParamScreen_Enable_Timer: '타이머 활성화',
  ParamScreen_Enable_Rating: '등급 활성화',
  ParamScreen_POSITIVE_NUMBER: '양수 만',
  Test_Find_Missing: '누락 된 번호를 찾으십시오.',
  Continue: '계속',
  Reivew_Your_Answer: ' 답변 다시 확인',
  NEXT_BUTTON: '다음',
  PREV_BUTTON: '이전',
  REVIEW_BUTTON: '검토',
  EVALUATE_BUTTON: '평가',
  SHOW_SUMMARY_BUTTON: '요약보기',
  HOME_BUTTON: '홈페',
  CONFIRM_BUTTON: '확인',
  CANCEL_BUTTON: '취소',
  OK_BUTTON: 'OK',
  ATTEMPTED_YES: '예',
  ATTEMPTED_NO: '아니오',
  REVIEW_HEADER: '검토?',
  YOUR_ANSWER_HEADER: '너의 답',
  CORRECT_ANSWER_HEADER: '올바른',
  EXIT_CONFIRM_MSG: '종료 하시겠습니까?',
  EXIT_EXAM_CONFIRM_MSG: '이 시험을 종료 하시겠습니까?',
  ADDITION: '덧셈',
  ADDITION_POSITIVE_NUMBER: '덧셈 (양수)',
  SUBTRACTION: '뺄셈',
  MULTIPLICATION: '곱셈',
  DIVISION: '나눗셈',
  MIXED_OPERATION: '기타 연산',
  SUM_OF_SQUARES: '제곱의 합',
  ABOUT_NUMBER_BOX_PAGE: "숫자 상자에 대해",
  ABOUT_NUMBER_CROSS_PAGE: "숫자 십자 정보",
  ABOUT_NUMBER_PYRAMID_PAGE: "숫자 피라미드에 대해",
  COPYRIGHT_PAGE: "저작권",
  DISCLAIMER_PAGE: "부인 성명",
  TOTAL_NUMBER_OF_QUESTION: '총 질문',
  ATTEMPTED: '시도한 질문',
  CORRECT: '옳은',
  CORRECT_YN: '옳은?',
  TIME_SECONDS: '时间（秒）',
  SCORE_PERCENTAGE: '得分了（％）',
  SCORE: '得分了',
  RATING: '评分',
  RESULT: '성적표',
  RESULT_ITEM: '안건',
  RESULT_DETAIL: '세부 정보',
  TRY_NEXT: '다른 시도',
  REVIEW_COMMENT: '댓글 검토',
  SKIPPED_QUESTION_MSG: '您跳过了这个问题。',
  BACK_BUTTON: '뒤로 버튼',
  GO_TO_HOME_MSG: '홈 페이지로 이동 하시겠습니까? 확인을 클릭하면 답변을 잃게됩니다.',
  CONFIRM_TO_EVALUATE_MSG: '답을 평가 하시겠습니까? 확인을 클릭하면 답변을 변경할 수 없습니다.',
  START_BUTTON: '시작',
  PREPARING_QUESTIONS: '처리 중 ..',
  PREPARING_QUESTIONS_MSG: '질문을 만드는 중입니다. 잠시 기다려주세요 ...',
  CLICK_START_CONTINUE: '계속하려면 시작을 클릭하십시오.',
  FOR_PAID_VERSION_ONLY_LABEL: '(유료 버전 만 해당)',
  PURCHASE_FROM_APP_STORE: 'App Store 에서 구입',
  PURCHASE_FROM_GOOGLE_PLAY_STORE: 'Google Play 스토어에서 구매',
  CLICK_TO_BUY_FULL_VERSION: 'Google Play 스토어에서 구매',
  DISMISS_BUTTON: '무시',
  CLICK_FOR_EVALUATION_MESSAGE: '평가 버튼을 클릭하여 답변을 확인하십시오.',
  ANSWER_CORRECT: '귀하의 답변은 정확했습니다.',
  ANSWER_WRONG: '당신의 대답은 틀 렸습니다.',
  NUMBER_BOX_EXPLANATION_5: "더 많은 숫자 상자 문제를 풀면 다른 유형의 산술 연산이 포함된다는 것을 알 수 있습니다. 더하기, 빼기, 곱하기, 제곱합 등",
  FOR_EXAMPLE: '예를 들면 :',
  AND_SO_ON: ', 등등.',
  NUMBER_BOX_EXPLANATION_6: " (1) 여기서 a와 b는 상자의 왼쪽 및 오른쪽 셀에있는 숫자를 나타냅니다.",
  NUMBER_BOX_EXPLANATION_7: ' (2) 지수를 나타 내기 위해 "^"를 사용합니다. 예 : 2 ^ 3 = 2 x 2 x 2 = 8',
  EXAMPLE: '예',
  EXPLANATION_OF_MIDDLE_NUMBER: '중간 숫자 설명 :',
  EXPLANATION_OF_CENTER_NUMBER: '중앙의 숫자 설명 :',
  NUMBER_PYRAMID_ROW_3: "행 -3",
  NUMBER_PYRAMID_ROW_4: "행 -4",
  NUMBER_PYRAMID_ROW_5: "행 -5",
  NUMBER_PYRAMID_ROW_3_POSITIVE: "행 -3 (양수)",
  NUMBER_PYRAMID_ROW_4_POSITIVE: "행 -4 (양수)",
  NUMBER_BOX_FIRST_ROW: "첫 번째 상자", //Doubt
  NUMBER_BOX_SECOND_ROW: "두 번째 상자",
  NUMBER_BOX_THIRD_ROW: "세 번째 상자",
  EXPLANATION_SOLUTION_MSG: '이것이 우리의 대답입니다. 따라서 누락 된 숫자는',
  NUMBER_CROSS_FIRST: '최초의 디지털 크로스',
  NUMBER_CROSS_SECOND: '두 번째 디지털 크로스',
  NUMBER_CROSS_THIRD: '세 번째 디지털 크로스',
  NUMBER_CROSS_EXPLANATION_6: '숫자 십자형은 아래 그림과 같이 십자 모양으로 배열 된 5 개의 숫자로 구성된 구조입니다.' +
    '각 십자수의 중간 숫자는 십자 안에있는 네 개의 숫자를 사용하여 계산됩니다..' +
    '우리는 [숫자 십자] 문제를 만들기 위해 다양한 산술 연산을 사용했습니다.',
  NUMBER_CROSS_EXPLANATION_7: '지수를 나타 내기 위해 "^"를 사용합니다. 예 : 2 ^ 3 = 2 x 2 x 2 = 8',
  NUMBER_CROSS_EXPLANATION_8: 'l, t, r, b를 십자가의 왼쪽, 위쪽, 오른쪽, 아래쪽에있는 숫자로 표시하면 중앙의 숫자는 문제에 따라 다음 연산 중 하나에 의해 결정될 수 있습니다.',
  NUMBER_CROSS_EXPLANATION_9: '더 많은 숫자의 십자 문제를 풀면 다른 유형의 산술 연산이 포함된다는 것을 알 수 있습니다. 더하기, 빼기, 곱하기, 제곱합 등',
  NUMBER_CROSS_EXPLANATION_10: '십자 중심의 숫자는 주변의 네 숫자에 산술 연산의 일부 조합을 적용하여 결정됩니다. 숫자 십자 문제는 세 개의 십자로 구성되며 처음 두 십자에있는 모든 숫자가 제공되었습니다. 이 정보를 사용하여 세 번째 십자가에서 누락 된 숫자를 찾아야합니다. 원칙적으로 중앙의 숫자를 결정하는 논리는 모든 십자가에 대해 동일해야합니다.',
  REVIEW_PAGE_NUMBER_BOX_MISSING_NUMBER: '누락 된 번호는 다음과 같습니다.',
  REVIEW_PAGE_NUMBER_CROSS_MISSING_NUMBER: '누락 된 번호는 다음과 같습니다.',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y: '누락 된 숫자가 y라고 가정합니다.',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y_AND_Z: '누락 된 숫자가 y와 z라고 가정합니다.',
  NUMBER_PYRAMID_USING_3_ROW_FORMULA: '3 행 공식 사용 :',
  NUMBER_PYRAMID_USING_4_ROW_FORMULA: '4 행 공식 사용 :',
  NUMBER_PYRAMID_USING_5_ROW_FORMULA: '5 행 공식 사용 :',
  NUMBER_PYRAMID_SHOW_CORRECT_ANSWER: '정답보기',
  NUMBER_PYRAMID_SHOW_MY_ANSWER: '내 대답을 보여줘',
  NOTE: '노트',
  NUMBER_PYRAMID_ABOUT: "숫자 피라미드는 피라미드에 배열 된 세포 모음입니다. 피라미드의 각 셀에는 숫자가 포함되어 있으며, 이는 바로 아래 셀에있는 두 숫자의 합입니다. 피라미드 문제에서 우리는 빠진 숫자를 찾아야합니다.",
  GO_TO_NUMBER_PYRAMID_MSG: '주제에 대해 자세히 알아 보려면 "** 숫자 피라미드 정보 **"로 이동하십시오.',
  NUMBER_PYRAMID_A_3_ROW: '3 행 피라미드',
  NUMBER_PYRAMID_A_4_ROW: '4 행 피라미드',
  NUMBER_PYRAMID_FORMULA_FOR_A_3_ROW: '3 행 피라미드의 공식',
  NUMBER_PYRAMID_FORMULA_FOR_A_4_ROW: '4 행 피라미드의 공식',
  EXPLANATION: '설명',
  NUMBER_PYRAMID_EXPLANATION_5: '더 많은 수 피라미드 문제를 풀면 간단한 선형 방정식을 푸는 방법을 배우게됩니다.',
  NUMBER_PYRAMID_ROW_2: '행 -2',
  NUMBER_PYRAMID_ROW_3: '행 3',
  FORMULA_FOR_3_ROW_MSG: '3 행의 공식은 다음과 같습니다.',
  FORMULA_FOR_4_ROW_MSG: '4 행의 공식은 다음과 같습니다.',
  NUMBER_PYRAMID_MSG_1: '모든 셀의 숫자는 아래와 같이 그 아래에있는 두 숫자의 합입니다.',
  NUMBER_PAYRMID_MSG_2: '자세한 내용을 보려면 다음을 클릭하십시오 : 계속-> 더보기 ..-> 숫자 피라미드 정보'
};
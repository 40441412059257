import {Button, Layout, Table} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import ErrorMessages from '../exam/error_messages';
import '../index.css';
import AppConstant from './app_constant';
import FlowManager from './flow_manager';
import PageUtil from './page_util';
import UIPageRouterCommon from './ui_page_router_common';
import UIPageRouterCore from './ui_page_router_core';
import UIRouterStatusMessage from './ui_router_status_messgae';

const {Header, Content, Footer, Sider} = Layout;

class MyExamHistoryPage extends React.Component {
    constructor(props) {
        super(props)
        // console.log(examSheet)
        // var currentExam = UserSession.getInstance().getUserExamSheet()

        console.log("props ------><")
        console.log(props)

        this.state = {
            rows: ""
        }


    }

    notifyReady(uiRouter, examSheet, pageAction) {
        console.log("exam history ready" + pageAction)
        this.state.pageAction = pageAction
        this.setState({rows: examSheet})
        // console.log("notified ready ......" + pageAction)
        // var handled = UIPageRouterCore.handleIncomeFromServer(uiRouter, examSheet, pageAction)
        // if (!handled) {
        //     console.log("no page action configured")
        // }
    }

    notifyError(self, serverError) {
        console.log("error happended ????", serverError)


    }

    loadSelectedExam(examRef) {
        // UIPa
        // UIPa
        UIPageRouterCore.goToUIRouterPage(this, AppConstant.LOAD_EXAM_REQUEST, {'examRef': examRef})
        // FlowManager.loadMyExam(this,examRef)
    }


    componentDidMount() {


    }

    render() {
        console.log(this.props.location.serverResult)
        const columns = [
            {
                title: 'Ref',
                dataIndex: 'examRef',
                key: 'examRef',
                width: 20,
                render: text => <a onClick={() => this.loadSelectedExam(text)}>{text}</a>,
            },
            {
                title: 'Status',
                dataIndex: 'examStatus',
                key: 'examStatus',
                width: 30

            },
            {
                title: 'Level',
                dataIndex: 'problemLevel',
                key: 'problemLevel',
                width: 30

            },
            {
                title: 'Type',
                dataIndex: 'problemType',
                key: 'problemType',
                width: 30

            },
            {
                title: 'Score',
                dataIndex: 'examScore',
                key: 'examScore',
                width: 30

            },
            {
                title: 'Total',
                dataIndex: 'problemCount',
                key: 'problemCount',
                width: 30

            },
            {
                title: 'Attempted',
                dataIndex: 'attemptCount',
                key: 'attemptCount',
                width: 30

            },


            {
                title: 'Time(sec)',
                dataIndex: 'totalTime',
                key: 'totalTime',
                width: 30

            },
            {
                title: 'Correct(%s)',
                dataIndex: 'percentageCorrect',
                key: 'percentageCorrect',
                width: 30

            },
        ]
        const rows = this.props.location.serverResult
        return (
            <div>
                <div>
                    Exam History {this.state.pageAction}
                </div>
                <div>
                    Server Response
                </div>
                <Table columns={columns} dataSource={rows} size="middle" pagination={true}/>

            </div>
        )
    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <MyExamHistoryPage location={location} history={history}/>
    )
});

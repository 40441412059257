
export default  { 
  PROBLEM_TYPE: {
    Number_Box: '数ボックス',
    Number_Cross: '数クロス',
    Number_Pyramid: '数ピラミッド',
  },
          LOGITICA_HEADER_1:"LOGITICA:",
          LOGITICA_HEADER_2:"数字パズル",
          welcome: '下からパズルの1つを選択してください' ,
          logitica_logo: 'ロジックと数学を一緒に学ぶ' ,
            wisgo_copyright: '**(c) Wisgo Limited**',
            Number_box_Description:"**数ボックス**は、以下の例1に示すように、水平に結合された三つの数字セルを含むボックスで構成されます。 各ボックスの中央の番号は、ボックスの左側と右側に示されている二つの数字を使用して計算されます。",
            Number_box_Description_1:"数ボックスの問題を作成するために、さまざまな算術演算を使用しました。",
            ParamScreen_Exam:"テスト",
            PAGE: "ページ",
            ParamScreen_Practice:"練習",
            ParamScreen_More:"より多くの..",
            Level_1:'レベル-1',
            Level_2:'レベル-2',
            Level_3:'レベル-3',
            Level_4:'レベル-4',
            Level_5:'レベル-5',
            ParamScreen_Number_Of_Question:'質問の数',
            ParamScreen_Enable_Timer:'タイマーの有効化',
            ParamScreen_Enable_Rating:'評価を有効にする',
            ParamScreen_POSITIVE_NUMBER: '正の数のみ',
            Test_Find_Missing:'欠けている数を見つける.',
            Continue:'続ける',
            Reivew_Your_Answer:'回答を確認する',
            NEXT_BUTTON:'次',
            PREV_BUTTON:'前',
            REVIEW_BUTTON:'レビュー',
            EVALUATE_BUTTON:'チェックする',
            SHOW_SUMMARY_BUTTON:'概要を表示',
            HOME_BUTTON:'ホーム',
            CONFIRM_BUTTON:'確認',
            CANCEL_BUTTON:'キャンセル',
            OK_BUTTON:'OK',
            ATTEMPTED_YES:'はい',
            ATTEMPTED_NO:'いいえ',
            REVIEW_HEADER:'レビュー？',
            YOUR_ANSWER_HEADER:'回答',
            CORRECT_ANSWER_HEADER:'正解',
            EXIT_CONFIRM_MSG:'本当に終了してもよろしいですか？',
            EXIT_EXAM_CONFIRM_MSG:'この試験を終了してもよろしいですか？',
            ADDITION:'足し算',
            ADDITION_POSITIVE_NUMBER:'足し算(正の数)',
            SUBTRACTION:'引算',
            MULTIPLICATION:'掛け算',
            DIVISION:'割り算',
            MIXED_OPERATION:'その他の演算',
            SUM_OF_SQUARES:'平方和',
            ABOUT_NUMBER_BOX_PAGE:"数ボックスについて",
            ABOUT_NUMBER_CROSS_PAGE:"数クロスについて",
            ABOUT_NUMBER_PYRAMID_PAGE:"数ピラミッドについて",
            COPYRIGHT_PAGE:"著作権",
            DISCLAIMER_PAGE:"免責事項",
            TOTAL_NUMBER_OF_QUESTION:'質問総数',
            ATTEMPTED:'試行された問題',
            CORRECT:'正解',
            CORRECT_YN:'正解?',
            TIME_SECONDS:'経過時間(秒)',
            SCORE:'スコア',
            SCORE_PERCENTAGE:'スコア(%)',
            RATING:'レイティング',
            RESULT:'レポートカード',
            RESULT_ITEM:'項目',
            RESULT_DETAIL:'細部',
            TRY_NEXT:' 次の ',
            REVIEW_COMMENT:'コメント',
            SKIPPED_QUESTION_MSG:'この質問をスキップしました。',
            BACK_BUTTON:'戻る',
            GO_TO_HOME_MSG:'ホームページに移動してもよろしいですか？[OK]をクリックすると、回答が失われます。',
            CONFIRM_TO_EVALUATE_MSG:'回答を評価してもよろしいですか？ [OK]をクリックすると、回答を変更できなくなります。',
            START_BUTTON:'スタート',
            PREPARING_QUESTIONS:'ローディング中..',
            PREPARING_QUESTIONS_MSG:'質問を生成しています。お待ちください...',
            CLICK_START_CONTINUE:'[ スタート ] をクリックしてください。',
            FOR_PAID_VERSION_ONLY_LABEL:' (有料版のみ)',
            PURCHASE_FROM_APP_STORE:'App Storeから購入 ',
            PURCHASE_FROM_GOOGLE_PLAY_STORE:'Google Playストアから購入',
            CLICK_TO_BUY_FULL_VERSION:'フルバージョンを購入するにはクリックしてください。',
            DISMISS_BUTTON:'ダイアログを閉じる',
            CLICK_FOR_EVALUATION_MESSAGE:'回答を確認するには、[チェックする]ボタンをクリックします。',
            ANSWER_CORRECT:'正解です:',
            ANSWER_WRONG:'回答が間違っています:',
            NUMBER_BOX_EXPLANATION_5:'数ボックスの問題をさらに解決すると、さまざまなタイプの算術演算が含まれることがわかります。加算、減算、乗算、二乗和など',
            FOR_EXAMPLE:'例えば：',
            AND_SO_ON:'など。',
            NUMBER_BOX_EXPLANATION_6:"（1）ここで、aとbは、ボックスの左右のセルにある番号を示します。",
            NUMBER_BOX_EXPLANATION_7:'（2）指数を示すために「^」を使用します。例：2 ^ 3 = 2 x 2 x 2 = 8。',
            EXAMPLE:'例',
            EXPLANATION_OF_MIDDLE_NUMBER:'真ん中の数字の説明：',
            EXPLANATION_OF_CENTER_NUMBER:'中央の数字の説明:',
            NUMBER_PYRAMID_ROW_3:"3段目",
            NUMBER_PYRAMID_ROW_4:"4段目",
            NUMBER_PYRAMID_ROW_5:"5段目",
            NUMBER_PYRAMID_ROW_3_POSITIVE:"3段目 (正の数)",
            NUMBER_PYRAMID_ROW_4_POSITIVE:"4段目 (正の数)",
            NUMBER_BOX_FIRST_ROW:"第1ボックス",
            NUMBER_BOX_SECOND_ROW:"第2ボックス",
            NUMBER_BOX_THIRD_ROW:"第2ボックス",
            EXPLANATION_SOLUTION_MSG:'そして、これが答えです。したがって、不足している数：',
            NUMBER_CROSS_FIRST:'第1クロス',
            NUMBER_CROSS_SECOND:'第2クロス',
            NUMBER_CROSS_THIRD:'第3クロス',
            NUMBER_CROSS_EXPLANATION_6:'数クロスは、下の図に示すように、十字のような形に配置された5つの数字で構成される構造です。'+
            '各クロス番号の真ん中の番号は、クロスにある4つの番号を使用して計算されます。'+
            '数クロスの問題を作成するために、さまざまな算術演算を使用しました。',
            NUMBER_CROSS_EXPLANATION_7:'指数を示すために「^」を使用します。例：2 ^ 3 = 2 x 2 x 2 = 8。',
            NUMBER_CROSS_EXPLANATION_8:'l、t、r、bをクロスの左、上、右、下側の数として表す場合、中央の数は問題に応じて次の操作のいずれかによって決定できます。',
            NUMBER_CROSS_EXPLANATION_9:'より多くの数のクロス問題に取り組むと、それがさまざまなタイプの算術演算を含むことがわかります。加算、減算、乗算、二乗和など。',
            NUMBER_CROSS_EXPLANATION_10:'クロスの中央の数は、周囲の4つの数に算術演算のいくつかの組み合わせを適用することによって決定されます。数クロス問題は3つのクロスで構成され、最初の2つのクロスのすべての数が提供されています。この情報を使用して、3番目のクロスで不足している番号を見つける必要があります。原則として、中央の数を決定するロジックは、すべてのクロスで同じでなければなりません。',
            REVIEW_PAGE_NUMBER_BOX_MISSING_NUMBER:'不足している数:',
            REVIEW_PAGE_NUMBER_CROSS_MISSING_NUMBER:'不足している数:',
            NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y:'不足している数をyとします。',
            NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y_AND_Z:'yとzを不足している数とします。',
            NUMBER_PYRAMID_USING_3_ROW_FORMULA:'3行の数式を使用：',
            NUMBER_PYRAMID_USING_4_ROW_FORMULA:'4行の数式を使用：',
            NUMBER_PYRAMID_USING_5_ROW_FORMULA:'5行の数式を使用：',
            NUMBER_PYRAMID_SHOW_MY_ANSWER:'試した答えを表示',
            NUMBER_PYRAMID_SHOW_CORRECT_ANSWER:'正解を表示',
            NOTE:'ご注意',
            NUMBER_PYRAMID_ABOUT:'数ピラミッドは、ピラミッドに配置されたセルのコレクションです。ピラミッドの各セルには数値が含まれています。これは、そのすぐ下のセルの2つの数値の合計です。ピラミッド問題では、不足している数を見つける必要があります。',
            GO_TO_NUMBER_PYRAMID_MSG:'このトピックの詳細については、「**数ピラミッドについて**」にアクセスしてください。',
            NUMBER_PYRAMID_A_3_ROW:'3列のピラミッド',
            NUMBER_PYRAMID_A_4_ROW:'4列のピラミッド',
            NUMBER_PYRAMID_ROW_2:'行2',
            NUMBER_PYRAMID_ROW_3:'行3',
            NUMBER_PYRAMID_FORMULA_FOR_A_3_ROW:'3列ピラミッドの式',
            NUMBER_PYRAMID_FORMULA_FOR_A_4_ROW:'4列ピラミッドの式',
            EXPLANATION:'説明',
            NUMBER_PYRAMID_EXPLANATION_5:'より多くの数ピラミッド問題を解くにつれて、単純な線形方程式の解法について学びます。',
            FORMULA_FOR_3_ROW_MSG:'3行の式を以下に示します。',
            FORMULA_FOR_4_ROW_MSG:'4行の式を以下に示します。',
            NUMBER_PYRAMID_MSG_1:'以下に示すように、任意のセルの数値は、その下の2つの数値の合計です。',
            NUMBER_PAYRMID_MSG_2:'詳細については、次のリンクをクリックしてください。続行->その他..-> "数値ピラミッドについて".'
            //成績
            //不正解
        
  };

export default class AppRoutes {
  static EXAM_PAGE_PATH = '/exam-page'
  static GRAPH_PAGE_PATH = '/graph-page'
  static ROOT_PATH = '/'
  static USER_SELECTION_PATH = '/user-selection'
  static EXAM_REULST_PATH = '/exam_result/'
  static NUMBER_BOX_PATH = '/number'
  static CONTACT_US_PATH = '/contact_us'
  static LOGIC_PAGE_PATH = '/logic'
  static UI_ROUTER_PATH = '/ui_router'
  static TEST_PAGE_PATH = '/test-page'
  static MY_EXAM_HISTORY_PATH = '/my_exam_history'
  static SIMPLE_ROUTER = '/simple_router'
  static MY_PAGE = '/my_page'
  static ADMIN_PAGE = '/admin_page'
}

import React from 'react';

class StopwatchDisplay extends React.Component {
    formatTime = (val, ...rest) => {
        let value = val.toString();
        if (value.length < 2) {
            value = '0' + value;
        }
        if (rest[0] === 'ms' && value.length < 3) {
            value = '0' + value;
        }
        return value;
    };

    render() {
        return (
            <div className={'stopwatch__display'}>
                <span>
                    Timer: {this.formatTime(this.props.currentTimeMin)}:
          {this.formatTime(this.props.currentTimeSec)}
                    {/* {this.props.formatTime(this.props.currentTimeMs, 'ms')} */}
                </span>
            </div>
        );
    }
}

export default StopwatchDisplay;

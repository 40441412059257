import { Col, Layout, Radio, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import * as io5 from 'react-icons/io5';
import * as bs from 'react-icons/bs';
import * as ai from 'react-icons/ai';
import * as fa from 'react-icons/fa';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import '../index.css';
import UserSession from '../user_session';
import { Divider } from 'antd';
import STYLES from '../../types/box_styles'

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;


require('react-web-vector-icons/fonts');

export default class GridShape extends React.Component {
    constructor(props) {
        super(props)
    }

    build_one() {
        var styles = this.props['style2']['style']
        var num =this.props['style2']['filledIndex']
        var value =this.props['style2']['_value']
        var items = []

     
        for (var i = 0; i < 9; i++) {
            if (i == num) {
                items.push(<div style={{ ...STYLES.GRID_ITEM_COLOR ,...styles}} >{value}</div>)
            }
            else {
                items.push(<div style={STYLES.GRID_ITEM} />)
            }

        }

        return ([
            <div style={{ ...STYLES.GRID_CONTAINER, }}>
                {items}
            </div>
        ])

    }
    render() {

        return (
            this.build_one()
        )

    }
}




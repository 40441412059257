import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { PolyChart } from 'poly-chart';
import React from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import '../index.css';



const { Header, Content, Footer, Sider } = Layout;

class GraphPage extends React.Component {
    constructor(props) {
        super(props)



    }

    componentDidMount() {



    }





    render() {
        // alert('hi')
        var demoData = [
            {
                creative: 24,
                analytical: 27,
                numerical: 27,
                logical: 20,
                accuracy: 100,
                speed: 190
            },
            {
                creative: 20,
                analytical: 20,
                numerical: 20,
                logical: 18,
                accuracy: 80,
                speed: 180
            },
            {
                creative: 4,
                analytical: 6,
                numerical: 6,
                logical: 2,
                accuracy: 20,
                speed: 120
            },
            // {
            //     HP: 150,
            //     ATTACK: 100,
            //     DEFENSE: 200,
            //     SPECIAL: 150,
            //     SPEED: 100,
            //     STAMINA: 120
            // }
        ];
        // console.log(evaluatedResult.evaluationSummaryNote.averageCalibration)
        // console.log(evaluatedResult.evaluationSummaryNote.problemCalibration)
        // console.log(evaluatedResult.evaluationSummaryNote.userCalibration)
        console.log("this.props.evaluationSummaryNote")
        console.log(this.props.location)
        demoData = [
            this.props.location.evaluationSummaryNote.problemCalibration,
            this.props.location.evaluationSummaryNote.averageCalibration,
            this.props.location.evaluationSummaryNote.userCalibration,
        ]

        const demoOptions = {
            increments: 10,

            animation: {
                tween: true,
                delay: [0, 500, 1000],
                duration: [2000, 1000, 5000],
                easingFunction: ["easeOutElastic", "easeOutBounce", "linear"]
            },

            style: {
                label: {
                    fontFamily: "Helvetica",
                    fontSize: 12.5
                },

                chart: {
                    background: true,
                    fill: "rgba(255, 255, 0, 1)",
                    stroke: "rgba(0, 0, 0, 1)"
                },

                polygon: {
                    contour: true,
                    fill: [
                        "rgba(3, 5, 183, 0.6)",
                        "rgba(255, 0, 0, 0.6)",
                        "rgba(0, 210, 79, 0.6)"
                    ],
                    stroke: ["rgb(3, 5, 183)", "rgb(255, 0, 0)", "rgb(0, 210, 79)"],
                    lineWidth: 2
                }
            }
        };
        const myStyles = {
            // background: "rgb(0, 141, 210)",
            position: "fixed",
            height: "50%",
            width: "50%"
        }
        const myData = demoData
        const myOptions = demoOptions
        return <div style={myStyles}>
            {/* chart */}
            <PolyChart data={myData} options={myOptions} labels={["hi"]} />
        </div>

    }
}


export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <GraphPage location={location} history={history} />
    )
});

import {Button, Input, Layout, Select, Space} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import strings from '../../resource/i18n_resource';
import '../index.css';
import UserSession from '../user_session';
import UIPageRouterCommon from './ui_page_router_common';
import FlowManager from "./flow_manager.js";
import AppConstant from "./app_constant.js";

const {Option} = Select;
const {Header, Content, Footer, Sider} = Layout;

class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            authenticated: false,
            username: '',
            password: ''
        }

    }

    onChange = (e) => {
        this.setState({inputValue: e.target.value})
        UserSession.getInstance().updateSessionCode(e.target.value)
    }
    onUserNameChange = (e) => {
        this.setState({username: e.target.value})
    }
    onPasswordChange = (e) => {
        this.setState({password: e.target.value})
        UserSession.getInstance().updateSessionCode(e.target.value)
    }

    notifyReady(action) {
        alert(action)
        FlowManager.goToMyPage(this)
    }
    notifyError(action) {
        alert('error' +action)
        alert(action)
        console.log(action)
        var self = this
        // UIPageRouterCommon.goToSelectionPage(self)
    }

    validate() {
        let authenticatedYn = UserSession.getInstance().validateCode(this.state.inputValue)
        this.setState({authenticated: authenticatedYn})

        var self = this
        FlowManager.tryLogin(self, this.state.username, this.state.password)
    }

    render() {

        return <Content style={{margin: '0 16px', alignSelf: 'left'}}>
            <div style={{fontSize: '30px'}}>Logitica</div>
            <Space align="center" style={{fontSize: '20px'}}>

                Username: <Input size="large" placeholder="input username" onChange={this.onUserNameChange}/>
            </Space>
            <p/>

            <Space align="center" style={{fontSize: '20px'}}>
                Password: <Input.Password size="large" placeholder="input password" onChange={this.onPasswordChange}/>
            </Space>
            <p/>
            <Space align="center" style={{fontSize: '20px'}}>
                <Button style={{fontSize: '20px', height: '40px', margin: '15px 15px 15px 15px'}} type="primary"
                        onClick={() => this.validate()}>{strings.LOGIN_TO_SITE}</Button>
            </Space>
            {/* hello {String(this.state.authenticated)} */}

            <Content style={{margin: '0 16px', alignSelf: 'left'}}>
                Please send email to <Space style={{color: 'blue'}}>reetu.wisgo@gmail.com</Space> to get code.
            </Content>

        </Content>

    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <HomePage location={location} history={history}/>
    )
});

export default class ResultRemarks {
    static findResultRemarks(props) {
        var remarks = ''
        // var colorStyle = {}
        if (props.evaluatedAlready) {
            let isAttempted = props.userAnswer.attempted
            let isCorrect = props.userAnswer.answerCorrect
            let userAnswerValue = props.userAnswer.userAnswerValue
            var colorStyle = { 'color': 'red' }
            if (!isAttempted) {
                colorStyle = { 'color': '#800020' }
                remarks = "You skipped the question, the correct answer is (" + props.correctAnswer + ")"
            }
            else if (isCorrect) {
                remarks = "Your answer is correct"
                colorStyle = { 'color': 'green' }
            }
            else {

                remarks = "You selected (" + userAnswerValue + ") but the correct answer is (" + props.correctAnswer + ")"
                colorStyle = { 'color': 'red' }
            }

        }
        return {
            "remarks": remarks,
            "colorStyle": colorStyle
        }
    }
    static findResultRemarksDiv(props) {
        let resultRemarks = ResultRemarks.findResultRemarks(props)
        let remarks = resultRemarks['remarks']
        let colorStyle = resultRemarks['colorStyle']
        return (<div style={colorStyle}>{remarks}</div>)
    }
}
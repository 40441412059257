import { Layout } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import UserSession from '../user_session';
import AppConstant from './app_constant';
import ExamApi from '../api/exam_api';
import ExamManager from '../exam/exam_manager';
import UIPageRouterCommon from './ui_page_router_common';
import AppRoutes from '../../app_routes';
const { Header, Content, Footer, Sider } = Layout;
export default class UIPageRouterCore {

    static handleIncomeFromServer(uiRouter, response, pageActionValue) {
        console.log("after loading " + pageActionValue)
        if (pageActionValue == AppConstant.EXAM_CREATED || pageActionValue == AppConstant.RETRY_EXAM_CREATED) {
            UIPageRouterCommon.goToExamReady(uiRouter, response, pageActionValue)
            return true
        }
        else if(pageActionValue ==AppConstant.LOAD_EXAM_RESPONSE){
            UserSession.getInstance().registerForExam(response);
            UIPageRouterCommon.examEvaluationCompleted(uiRouter, response.evaluatedExamSheet)
            UIPageRouterCommon.goToExamReady(uiRouter, response, pageActionValue)
        }
        else if (pageActionValue == AppConstant.EVALATE_EXAM_COMPLETED_RESPONSE) {
            UIPageRouterCommon.examEvaluationCompleted(uiRouter, response)
            return true
        }
        else if (pageActionValue == AppConstant.MY_EXAM_HISTORIES_RESPONSE) {
            UIPageRouterCommon.goToMyExamHistoryPage(uiRouter, response)
            return true
        }

        return false
    }

    static handleOutgoingToServer(selfCurrent, pageAction, otherAttributes) {
        //hack enable following
        // UIPageRouterCore.goToUIRouterPage(selfCurrent, pageAction, otherAttributes)
//hack disable following
        console.log("page action is "+pageAction)
        if (pageAction == AppConstant.START_EXAM_REQUEST
            || pageAction == AppConstant.EVALATE_EXAM_REQUEST) {
            UIPageRouterCore.goToUIRouterPage(selfCurrent, pageAction)
        }
        else {
            console.log("No matching action found " + pageAction)
        }
    }

    static handleInterPageRouting(selfCurrent, pagePath, pageAction) {
        selfCurrent.props.history.push({
            pathname: pagePath,
            pageAction: pageAction

        })
    }
    static goToUIRouterPage(selfCurrent, pageActionValue, otherAttributes) {

        const originalMap = {
            pathname: AppRoutes.UI_ROUTER_PATH,
            callBackComponent: selfCurrent,
            pageAction: pageActionValue
        };
        console.log(originalMap)
        // console.log(moreMap)
        // for (var i in moreMap)
        //     originalMap[i] = moreMap[i];
        console.log(" more map.....")
        console.log(originalMap)
        //HACK AGAIN FIXING
        // selfCurrent.props.history.push(otherAttributes)
        selfCurrent.props.history.push({pathname: AppRoutes.UI_ROUTER_PATH,
            callBackComponent: selfCurrent,
            pageAction: pageActionValue})
        console.log(selfCurrent)
    }



}

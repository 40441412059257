
import { Col, Layout, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import STYLES from '../../types/box_styles';
import '../index.css';
// https://react-icons.github.io/react-icons/#/
// https://stackoverflow.com/questions/39979325/partially-fill-a-shapes-border-with-colour
require('react-web-vector-icons/fonts');

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

export default class STriangle extends React.Component {
    constructor(props) {
        super(props)


    }
    render() {
        var extraProps = {}
        // if(this.props.style2!=undefined){
        //     alert(this.props.style2.transform)
        //     extraProps=this.props.style2
        //     extraProps['borderBottomColor']='red'

        // }
        // else{

        // }


        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}  >

                <div style={Object.assign({}, STYLES._TRIANGLE, this.props.style2)} />
            </Col>
        )
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../../../index.css'
import { Layout, Menu, Breadcrumb,Row,Col, Space } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import EachNumberBox from './each_number_box'
import EachNumberBoxSolve from './each_number_box_solve'
import EachNumberBoxEvaluated from './each_number_box_evaluated'
import { Input } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
const { Header, Content, Footer, Sider } = Layout;

export default class NumberBoxRow extends React.Component {
  
    render() {
      const userAnswer = this.props.userAnswer;
      let userAnswerValueInput = userAnswer.userAnswerValue
      
      // alert(this.props.evaluatedAlready)
        return (<div>
                <EachNumberBox  left={this.props.numbers[0]} middle={this.props.numbers[1]} right={this.props.numbers[2]} ></EachNumberBox>
                <EachNumberBox  left={this.props.numbers[3]} middle={this.props.numbers[4]} right={this.props.numbers[5]} ></EachNumberBox>
                {this.props.evaluatedAlready && <EachNumberBoxEvaluated solutionHint={this.props.solutionHint} userAnswer={userAnswer} userAnswerValueInput={userAnswerValueInput} left={this.props.numbers[6]} middle={this.props.correctAnswer} right={this.props.numbers[8]} actionNameCallbackFn={this.props.actionNameCallbackFn}></EachNumberBoxEvaluated>}
                {(!this.props.evaluatedAlready) && <EachNumberBoxSolve  userAnswerValueInput={userAnswerValueInput} left={this.props.numbers[6]} middle={this.props.numbers[7]} right={this.props.numbers[8]} actionNameCallbackFn={this.props.actionNameCallbackFn}></EachNumberBoxSolve>}
               </div>)
       
    }
}

import { Col, Layout, Radio, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import * as io5 from 'react-icons/io5';
import * as bs from 'react-icons/bs';
import * as ai from 'react-icons/ai';
import * as fa from 'react-icons/fa';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import '../index.css';
import UserSession from '../user_session';
import { Divider } from 'antd';
import STYLES from '../../types/box_styles'

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;


require('react-web-vector-icons/fonts');

export default class GridURevShape extends React.Component {
    constructor(props) {
        super(props)
    }

    build_one() {
        var styles = this.props['style2']['style']
        var num = this.props['style2']['filledIndex']
        var drawBoxIndex = this.props['style2']['_drawBoxIndex']
        var drawBoxMaxIndex = this.props['style2']['_drawBoxMaxIndex']
        var value = this.props['style2']['_value']
        var items = []
        var container_property = { 'transform': styles['transform'] }
        console.log("container_property")
        console.log(container_property)
        console.log(this.props)
        // delete styles['transform']
       

        for (var i = 0; i < drawBoxMaxIndex; i++) {
            
            if(num.indexOf(i) >= 0){
                items.push(<div style={{ ...STYLES.GRID_ITEM_COLOR, ...styles , ... { transform: ''}}} >{value}</div>)
            }
            // if (i == num) {
                // items.push(<div style={{ ...STYLES.GRID_ITEM_COLOR, ...styles }} >{value}</div>)
            // }
            // else if (i == 4 || i == 7) {
            else if(drawBoxIndex.indexOf(i)<0){
                items.push(<div style={{ ...styles, ...STYLES.GRID_ITEM_EMPTY }} ></div>)
            }
            else {
                items.push(<div style={STYLES.GRID_ITEM} />)
            }

        }

        return ([
            <div style={{ ...STYLES.GRID_CONTAINER, ...container_property }}>
                {items}
            </div>
        ])

    }
    render() {

        return (
            this.build_one()
        )

    }
}





import { FileOutlined, HeatMapOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { withRouter } from "react-router";
// import { Button, Form, Navbar, FormGroup, Nav, FormControl, NavDropdown, ListGroup, ListGroupItem, FormLabel, Container, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
// import ExamConfig from "./exam/exam_config"
import * as ExamConfig from "./exam/exam_config";
import './index.css';
import PageUtil from './screen/page_util';
import UserSession from './user_session';
import AppRoutes from '../app_routes';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class SiderDemo extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    // console.log(collapsed);
    this.setState({ collapsed });
  };

  handleProblemSelection(e, type) {
    if(this.handleMenuClick(e)){
      var userSelection = UserSession.getInstance().getUserSelection()
      userSelection.setProblemType(type)
    }
   
  }
  handleMenuClick(e) {
    // console.log(e)
    // console.log(e.target)
    var inprogres = UserSession.getInstance().isExamInProgress()
    if (inprogres) {
      alert("Please cancel the exising exam first")
      e.preventDefault()
      return false
    }
    return true

  }

  render() {
    // let pathName = PageUtil.getNextPage()
    const { collapsed } = this.state;
    
    return (
      <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
        <div className="logo" />

        <Menu theme="dark" defaultSelectedKeys={['2']} mode="inline">
          <Menu.Item icon={<UserOutlined />} key="2" >
            <Link to={{
              pathname: AppRoutes.USER_SELECTION_PATH,
              problemType: ExamConfig.PROBELM_TYPE_KEY.LogicMatrix
            }} className="nav-link" onClick={(e) => this.handleProblemSelection(e,ExamConfig.PROBELM_TYPE_KEY.LogicMatrix)}> Logic Exam </Link>

          </Menu.Item>

          <Menu.Item icon={<FileOutlined />}>
            <Link to={'/contact_us'} className="nav-link" onClick={(e) => this.handleMenuClick(e)}> Contact Us </Link>
          </Menu.Item>
          <Menu.Item icon={<FileOutlined />}>
            <Link to={AppRoutes.GRAPH_PAGE_PATH} className="nav-link" onClick={(e) => this.handleMenuClick(e)}> Graph </Link>
          </Menu.Item>
          {/* <Menu.Item icon={<HeatMapOutlined />}>
            <Link to={'/logic'} className="nav-link">Logic </Link>
          </Menu.Item> */}
          {/* <Menu.Item icon={<HeatMapOutlined />}>
            <Link to={'/test-page'} className="nav-link">TestPage </Link>
          </Menu.Item> */}
          {/* <Menu.Item icon={<HeatMapOutlined />}>
            <Link to={'/logic-exam'} className="nav-link">Logic Exam </Link>
          </Menu.Item> */}
          {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Number Box">
            <Menu.Item key="Logicial_1" >
              <Link to={{
                pathname: '/user-selection',
              }} className="nav-link"> Level1 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_22" onClick={this.handleMenuClick}>
              Hello
            </Menu.Item>
            <Menu.Item key="Logicial_2" >
            <Link to={{
                pathname: '/logic',
                examLevel: 2
              }} className="nav-link"> Level2 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_3" >
            <Link to={{
                pathname: '/logic',
                examLevel: 3
              }} className="nav-link"> Level3 </Link>
            </Menu.Item>
          </SubMenu> */}
          {/* <SubMenu key="sub2" icon={<UserOutlined />} title="Number Cross">
            <Menu.Item key="Logicial_1" >
              <Link to={'/logic/1'} className="nav-link"> Level1 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_2" >
              <Link to={'/logic'} className="nav-link"> Level2 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_3" >
              <Link to={'/logic'} className="nav-link"> Level3 </Link>
            </Menu.Item>
          </SubMenu> */}
          {/* <SubMenu key="sub3" icon={<UserOutlined />} title="Logical">
            <Menu.Item key="Logicial_1" >
              <Link to={'/'} className="nav-link"> Level1 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_2" >
              <Link to={'/'} className="nav-link"> Level2 </Link>
            </Menu.Item>
            <Menu.Item key="Logicial_3" >
              <Link to={'/'} className="nav-link"> Level3 </Link>
            </Menu.Item>
          </SubMenu> */}


          {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
              <Menu.Item key="3">Tom</Menu.Item>
              <Menu.Item key="4">Bill</Menu.Item>
              <Menu.Item key="5">Alex</Menu.Item>
            </SubMenu> */}
          {/* <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="8">Team 2</Menu.Item>
          </SubMenu> */}
          {/* <Menu.Item key="9" icon={<FileOutlined />}>
            Files
            </Menu.Item> */}
        </Menu>
      </Sider>
    );
  }
}
export default withRouter(({ location }) => {
  // const classes = useStyles();
  const history = useHistory();
  return (
    <SiderDemo location={location} history={history} />
  )
});


// ReactDOM.render(<SiderDemo />, document.getElementById('container'));
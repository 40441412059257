import { Input, Layout } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import './index.css';
const { Header, Content, Footer, Sider } = Layout;

export class QuestionNumber extends React.Component {
  render() {
    return <>
      <Input disabled style={{ fontSize: 20,  backgroundColor: 'transparent', color: 'black', border: 'none', width: '120px', height: '60px', textAlign: 'center' }}
        value={"("+this.props.questionNumber+"/"+this.props.questionCount+")"} />

    </>

  }
}




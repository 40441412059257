import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import NumberUI from './common/number_ui';
import NumberBox from './types/number_box/screen/number_box';
import ExamPage from './common/screen/exam_page';
import MyPage from './common/screen/my_page.js';
import GraphPage from './common/screen/graph_page';
import LogicExamPage from './types/logic/screen/logic_exam_page';
import MainScreen from './common/main_screen';
import AdminPage from './common/screen/admin_page.js';
import Home from './common/home';
import { Layout, Menu, Breadcrumb } from 'antd';
import ExamResultPage from './common/exam_result_page';
import UserSelectionPage from './common/screen/user_selection_page';
import UserSubSelectionPage from './common/screen/user_sub_selection_page';
import HomePage from './common/screen/home_page2';
import ContactUsPage from './common/screen/contact_us_page';
import LogicPage from './common/screen/logic_page';
import TestPage from './common/screen/test_page';
import SIMPLE_ROUTER from './common/screen/simple_router.js';
import UIRouter from './common/screen/ui_router';
import AppRoutes from './app_routes';
import MyExamHistoryPage from './common/screen/my_exam_history';
const { Header, Content, Footer, Sider } = Layout;
class App extends Component {
  render() {

    return (
      <Router>
        <Layout>
          <Sider><MainScreen /></Sider>
          <Layout style={{ minHeight: '100vh' }}>
            <Header className="site-layout-background" style={{ padding: 0 }} >
              <Content>

                <div>
                  <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                    <Menu.Item key="Report">Report</Menu.Item>
                    <Menu.Item key="Report">User Details</Menu.Item>
                    <Menu.Item key="Report">Settings</Menu.Item>

                  </Menu>
                </div>

              </Content>
            </Header>
            {/* <Content  style={{ height: "calc(100vh - 155px)"  ,background: "blue",}}> */}
            <Content >

              <Switch>
                <Route exact path={AppRoutes.EXAM_PAGE_PATH} component={ExamPage} />
                <Route exact path={AppRoutes.GRAPH_PAGE_PATH} component={GraphPage} />
                {/* <Route exact path={AppRoutes.ROOT_PATH} component={HomePage} /> */}
                <Route exact path={AppRoutes.ROOT_PATH} component={UserSelectionPage} />
                <Route exact path={AppRoutes.MY_PAGE} component={MyPage} />
                <Route exact path={AppRoutes.USER_SELECTION_PATH} component={UserSelectionPage} />
                <Route exact path={AppRoutes.EXAM_REULST_PATH} component={ExamResultPage} />
                <Route path='/number' component={NumberBox} />
                <Route path='/contact_us' component={ContactUsPage} />
                <Route path='/logic' component={LogicPage} />
                <Route path={AppRoutes.UI_ROUTER_PATH} component={UIRouter} />
                <Route path={AppRoutes.MY_EXAM_HISTORY_PATH} component={MyExamHistoryPage} />
                <Route path={AppRoutes.SIMPLE_ROUTER} component={SIMPLE_ROUTER} />
                <Route path={AppRoutes.ADMIN_PAGE} component={AdminPage} />
                {/* <Route path='/logic-exam' component={LogicExamPage} /> */}
                <Route path='/test-page' component={TestPage} />
              </Switch>
              {/* <Footer style={{ textAlign: 'center' }}>Logic & Math © Logitica</Footer> */}
              {/* <Footer className="site-layout-background" style={{ textAlign: 'center', verticalAlign: 'top', background: '#123456', height: '80px', color: 'white' }}>Logic & Math © Logitica</Footer>  */}
            </Content>
            <Footer className="site-layout-background" style={{ textAlign: 'center', verticalAlign: 'top', background: '#123456', height: '80px', color: 'white' }}>Logic & Math © Logitica</Footer>
          </Layout>
        </Layout>



        {/* <h2>Welcome to React Router Tutorial</h2>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ul className="navbar-nav mr-auto">
            <li><Link to={'/'} className="nav-link"> Home </Link></li>
            <li><Link to={'/logic'} className="nav-link">Contact</Link></li>
            <li><Link to={'/number'} className="nav-link">About</Link></li>
          </ul>
          </nav>
          <hr /> */}


      </Router>
    );
  }
}

export default App;
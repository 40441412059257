import React from 'react';
import { Menu, Dropdown,Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export default class UserAgeUI extends React.Component {


    redner() {
        const menu = <Menu>
            <Menu.Item key="0">
                >7 Years
        </Menu.Item>
            <Menu.Item key="1">
                8-9 Years
        </Menu.Item>
            <Menu.Item key="1">
                9-10 Years
        </Menu.Item>
            <Menu.Item key="1">
                >10 Years
        </Menu.Item>
            <Menu.Divider />
        </Menu>
        return (
            <>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                    <Button>bottomLeft</Button>
                </Dropdown>

            </>
        )
    }
}

import {Button, Layout, Table, Row, Col} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import '../index.css';
import strings from '../resource/i18n_resource';
import ExitExamDialog from './screen/exit_exam_dialog';
import PageUtil from './screen/page_util';
import GraphPage from './screen/graph_page';
import ExamHandler from './api/exam_handler';
import UserSession from './user_session';
import AppConstant from './screen/app_constant';
import UIPateRouterCommon from './screen/ui_page_router_common';
import AppRoutes from '../app_routes';

const {Header, Content, Footer, Sider} = Layout;

class ExamResultPage extends React.Component {

    goBackToExamPage() {
        console.log("this.props")
        // console.log(props)
        this.props.history.push({
            pathname: AppRoutes.EXAM_PAGE_PATH,
            pageAction: AppConstant.EXAM_EVALUATED
        })

    }

    retryThisExam() {
        UIPateRouterCommon.retryThisExam(this)

    }

    myExamHistories() {
        UIPateRouterCommon.getMyExamHistories(this)

    }


    render() {
        if (PageUtil.isPageRefreshed()) {

            PageUtil.setToGoToHomePage(this)
            return (<div/>)
        }

        // alert(Math.PI)
        // alert(Math.PI)
        var result = this.props.location.examResult
        if (result == undefined) {
            console.log('no result found')
            console.log(this.props)
            return (<div>waiting .........</div>)
        }

        console.log("Evaluated  ......")
        console.log("summary ====>")
        console.log(this.props.location.evaluationSummaryNote)
        const columns = [
            {
                title: 'Item',
                dataIndex: 'entryName',
                key: 'entryName',
                width: 200
            },
            {
                title: 'Value',
                dataIndex: 'entryValue',
                key: 'entryValue',
                width: 200

            },
        ]
        var rows = []
        rows.push(
            {

                entryName: strings.EXAM_RESULT_PROBLEM_TYPE,
                entryValue: result.problemType,
            },
            {

                entryName: strings.EXAM_RESULT_PROBLEM_LEVEL,
                entryValue: result.problemLevel,
            },
            {

                entryName: strings.EXAM_RESULT_QUESTION_COUNT,
                entryValue: result.problemCount,
            },
            {

                entryName: strings.EXAM_RESULT_ATTEMPTED_COUNT,
                entryValue: result.attemptCount,
            },

            {

                entryName: strings.EXAM_RESULT_CORRRECT_COUNT,
                entryValue: result.correctCount,
            },
            {

                entryName: strings.EXAM_RESULT_CORRRECT_PERCENTAGE,
                entryValue: result.percentageCorrect + ' %',
            },
            {

                entryName: strings.EXAM_RESULT_TIME_TAKEN,
                entryValue: result.totalTime,
            },
        )

        console.log(result)


        return (
            <div>
                <Content style={{margin: '0px 0px 0px 20px', alignSelf: 'left', fontSize: 20,}}>
                    Result
                    <br></br>
                    <Content style={{width: '30%',}}>
                        <Row>
                            <Col>
                                <Table columns={columns} dataSource={rows} size="middle" pagination={false}/>
                            </Col>
                            <Col><GraphPage
                                evaluationSummaryNote={this.props.location.evaluationSummaryNote}></GraphPage></Col>
                        </Row>


                    </Content>

                    <Button style={{fontSize: '20px', height: '50px', margin: '15px 15px 0px 0px'}} type="primary"
                            onClick={() => this.goBackToExamPage()}>{strings.REVIEW_ANSWER}</Button>
                    <Button style={{fontSize: '20px', height: '50px', margin: '15px 15px 0px 0px'}} type="primary"
                            onClick={() => this.retryThisExam()}>{strings.RETRY_THIS_EXAM}</Button>
                    <Button style={{fontSize: '20px', height: '50px', margin: '15px 15px 0px 0px'}} type="primary"
                            onClick={() => this.myExamHistories()}>{strings.MY_EXAM_HISTORY}</Button>

                    <ExitExamDialog/>
                </Content>
            </div>
        )

    }
}


export default withRouter(({location}) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <ExamResultPage location={location} history={history}/>
    )
});

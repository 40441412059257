
import i18n from 'i18n-js';
import ja from "./language/jp"
import zh from "./language/zh"
import zh_Hant_HK from "./language/zh_Hant_HK"
import en from "./language/en"
import ko from "./language/ko"
// import * as Localization from 'expo-localization';
import LocalizedStrings from 'react-localization'
//TODO fix this local
let strings = new LocalizedStrings({
  'en' : en,
  'ja' : ja,
  'zh' : zh,
  'ko' : ko,
  //zh-TW
  //zh-HK, zh-CN
  'zh-Hant-HK' : zh_Hant_HK,
  // ja,
  // zh,
  // 'zh-Hant-HK'
})
// i18n.translations = {
 
// };
// i18n.locale = Localization.locale;
// i18n.fallbacks = true;

export default strings
import { Col, Layout, Radio, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import * as io5 from 'react-icons/io5';
import * as bs from 'react-icons/bs';
import * as ai from 'react-icons/ai';
import * as fa from 'react-icons/fa';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import '../index.css';
import UserSession from '../user_session';
import { Divider } from 'antd';
import STYLES from '../../types/box_styles'
import GridShape from './grid_shape';
import UIPageRouterCommon from './ui_page_router_common';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;


require('react-web-vector-icons/fonts');

class LogicPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            authenticated: false
        }

    }

    onChange = (e) => {
        this.setState({ inputValue: e.target.value })
        // alert(e.target.value)
    }
    validate() {
        let authenticatedYn = UserSession.getInstance().validateCode(this.state.inputValue)
        this.setState({ authenticated: authenticatedYn })
        var self = this
        UIPageRouterCommon.goToSelectionPage(self)
        
    }
    render() {

        return (
            <Content style={{ margin: '0 16px', alignSelf: 'left' }}>
                <div style={{ fontSize: '30px' }}>Logitica</div>
                <div style={STYLES._TRIANGLE}>Logitica</div>
                

                <Row>
                <div style={STYLES.TEE}></div>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowBack size={100} style={{ margin: '0 0 0 10px' }} />
                        <div style={STYLES.TEE}/>
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowUp size={100} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowForward size={100} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{
                        border: '2px solid', margin: '0px 10px 0px 0px', fontSize: 50, verticalAlign: 'center', width: '110px', height: '110px',
                        alignItems: 'center', alignContent: 'center'
                    }}>
                        ?
                    </Col>

                </Row>
                <br />
                <br />
                <br />
                <br />
                <Row>
                    <div style={{...STYLES.GRID_CONTAINER, transform:'rotate(0deg)','outline':'1px solid red'}}>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={{...STYLES.GRID_ITEM_COLOR,'outline':'1px solid red'}}></div>
                        <div style={{...STYLES.GRID_ITEM}}></div>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM_EMPTY}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        <div style={STYLES.GRID_ITEM_EMPTY}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        
                    </div>
                    <div style={{...STYLES.GRID_CONTAINER}}>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={STYLES.GRID_ITEM_COLOR}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        
                    </div>
                    <div style={{...STYLES.GRID_CONTAINER,transform:'rotate(90deg)'}}>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={STYLES.GRID_ITEM_COLOR}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        {/* <div style={STYLES.GRID_ITEM_COLOR}></div> */}
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        {/* <div style={STYLES.GRID_ITEM}></div> */}
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        
                    </div>
                </Row>
                <Row>
                    <GridShape style2={{"filledIndex":0}} filledIndex="0" filledBackGroundColor={{'backgroundColor':'black'}}></GridShape>
                    <GridShape style2={{"filledIndex":1}} filledIndex="0" filledBackGroundColor={{'backgroundColor':'black'}}></GridShape>
                    <GridShape style2={{"filledIndex":2,'backgroundColor':'pink'}} filledIndex="0" filledBackGroundColor={{'backgroundColor':'red'}}></GridShape>
                    {/* <GridShape filledIndex="1" filledBackGroundColor={{'backgroundColor':'red'}}></GridShape> */}
                    {/* <GridShape filledIndex="2" filledBackGroundColor={{'backgroundColor':'red'}}></GridShape> */}
                    {/* <GridShape filledIndex="3" filledBackGroundColor={{'backgroundColor':'red'}}></GridShape> */}

                </Row>
                <Row>
                    <Col>
                    <div style={STYLES.TEE}/>
                    </Col>
                    TEST
                    <Col>
                    <div style={{...STYLES.GRID_CONTAINER, transform:'rotate(90deg)'}}>
                        <div style={{...STYLES.GRID_ITEM_COLOR}}></div>
                        <div style={{...STYLES.GRID_ITEM}}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM_EMPTY}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                        <div style={STYLES.GRID_ITEM_EMPTY}></div>
                        <div style={STYLES.GRID_ITEM}></div>
                    </div>
                    </Col>
                    <Col>
                    <div style={STYLES.RECTANGLE_SMALL}/>
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', alignItems: 'center' }}>
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />

                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 0px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{
                        border: '2px solid', margin: '0px 10px 0px 0px', fontSize: 35, verticalAlign: 'center', width: '50px', height: '60px',
                        alignItems: 'center', alignContent: 'center', textAlign: 'center', justifyContent: 'center'
                    }}>
                        ?
                    </Col>

                </Row>
                <Row>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', alignItems: 'center', width: 400, height: 200, }}>
                        <FaArrowRight size={40} />
                        <FaArrowDown size={40} />
                        <FaArrowLeft size={40} />

                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 0px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>
                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                    </Col>
                    <Col style={{
                        border: '2px solid', margin: '0px 10px 0px 0px', fontSize: 35, width: '50px', height: '60px',
                        verticalAlign: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', justifyContent: 'center'
                    }}>
                        ?
                    </Col>

                </Row>
                <br />
                <Row>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', width: '160px', height: '100px' }}>

                        <fa.FaArrowUp style={{ top: '10px', left: '10px', position: 'absolute' }} />
                        <fa.FaArrowUp style={{ top: '20px', left: '10px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='black' style={{ top: '10px', left: '120px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='green' style={{ top: '60px', left: '10px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='pink' style={{ top: '60px', left: '120px', position: 'absolute' }} />
                       
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', width: '160px', height: '100px' }}>

                        <io5.IoArrowUp size={40} color='red' style={{ 'top': '10px', left: '10px', position: 'absolute' }} />
                        
                        {/* <io5.IoArrowBack size={40} color='black' style={{ top: '10px', left: '120px', position: 'absolute', transform: 'rotate(90deg)' }} />
                        <io5.IoArrowBack size={40} color='green' style={{ top: '60px', left: '10px', position: 'absolute' , transform: 'rotate(90deg)'}} />
                        <io5.IoArrowBack size={40} color='pink' style={{ top: '60px', left: '120px', position: 'absolute', transform: 'rotate(90deg)' }} /> */}
                       
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', width: '160px', height: '100px' }}>

                        {/* <io5.IoArrowBack size={40} color='red' style={{ top: '10px', left: '10px', position: 'absolute' }} /> */}
                        <io5.IoArrowUp size={40} color='black' style={{ top: '10px', left: '120px', position: 'absolute', transform: 'rotate(45deg)' }} />
                        {/* <io5.IoArrowBack size={40} color='green' style={{ top: '60px', left: '10px', position: 'absolute' , transform: 'rotate(90deg)'}} /> */}
                        {/* <io5.IoArrowBack size={40} color='pink' style={{ top: '60px', left: '120px', position: 'absolute', transform: 'rotate(90deg)' }} /> */}
                       
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', width: '160px', height: '100px' }}>

                        <io5.IoArrowUp size={40} color='green' style={{ top: '60px', left: '10px', position: 'absolute' , transform: 'rotate(45deg)'}} />
                        
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center', width: '200px', height: '100px' }}>

                        <ai.AiTwotoneDownCircle size={20} color='red' style={{ margin: '0 0 0 0px', left: '10px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='red' style={{ margin: '0 0 0 0px', left: '120px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='red' style={{ margin: '0 0 0 0px', top: '60px', left: '10px', position: 'absolute' }} />
                        <ai.AiTwotoneDownCircle size={20} color='red' style={{ margin: '0 0 0 0px', top: '60px', left: '120px', position: 'absolute' }} />
                        {/* <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} /> */}
                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>

                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px', transform: 'rotate(90deg)' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px', transform: 'rotate(45deg)' }} />
                    </Col>
                </Row>
                <Row>

                </Row>


                <br />

                <Row>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>

                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <br />
                        <Content style={{ fontSize: 35, textAlign: 'center', }}>(A)<Radio style={{ fontSize: 35 }} value={1}></Radio></Content>

                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>

                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <br />
                        <Content style={{ fontSize: 35, textAlign: 'center', }}>(B)<Radio style={{ fontSize: 35 }} value={1}></Radio></Content>

                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>

                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <br />
                        <Content style={{ fontSize: 35, textAlign: 'center', }}>(C)<Radio style={{ fontSize: 35 }} value={1}></Radio></Content>

                    </Col>
                    <Col style={{ border: '2px solid', margin: '0px 50px 0px 0px', fontSize: 20, verticalAlign: 'center' }}>

                        <io5.IoArrowBack size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowDown size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowForward size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <io5.IoArrowUp size={50} style={{ margin: '0 0 0 10px' }} />
                        <br />
                        <Content style={{ fontSize: 35, textAlign: 'center', }}>(D)<Radio style={{ fontSize: 35 }} value={1}></Radio></Content>

                    </Col>

                </Row>
                <Row>


                </Row>




            </Content>
        )

    }
}


export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <LogicPage location={location} history={history} />
    )
});

import UserSession from '../user_session'
import ExamManager from '../exam/exam_manager'
import ExamApi from './exam_api'
import MyExamAPi from './my_exam_api'
import AppConstant from '../screen/app_constant'
import UIPageRouterCommon from '../screen/ui_page_router_common'
import ApiHelper from "./api_helper.js";
import ServerApi from "./server_api.js";
import UserInfo from "../screen/use_user_info.js";
import DataAccessApi from "../screen/data_access_api.js";

export default class LoginHandler {

    static tryLogin(self, username,password){
        alert('trying login')
        const sessionCode=-999999
        // const { userInfo, setUserInfo} = UserInfo();
        const updateSuccessFn = (serverResponse) => {
            console.log(serverResponse)
            var userInfo = JSON.parse(serverResponse.user_info)
            var userInfo = serverResponse.user_info
            localStorage.removeItem('access_token')
            localStorage.removeItem('user_info2')
            localStorage.removeItem('user_info')
            localStorage.removeItem('user_info_role')

            DataAccessApi.putUserInfo(serverResponse.user_info)
            DataAccessApi.putAccessToken(serverResponse.access_token)
            console.log("userInfo -->")
            console.log(userInfo)
            console.log(userInfo._role)
            self.notifyReady(AppConstant.LOGIN_SUCCESSFUL)

            // localStorage.setItem('access_token',serverResponse.access_token)
            // localStorage.setItem('user_info',userInfo)
            // localStorage.setItem('user_info_role',userInfo._role)
            // setUserInfo(userInfo)

            // let url = ApiHelper.get_api_path() + "/api/token/example"
            // const headers = {
            //     'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            // }
            // ServerApi.sendToServer("", url, 'get', sessionCode, null, null, headers)
        };

        const updateErrorFn = (serverMessage) => {
            console.log(serverMessage)
            self.notifyError(AppConstant.LOGIN_FAILED)
            localStorage.removeItem('access_token')
        };

        let url = ApiHelper.get_api_path() + "/api/token/auth"
        const body = {
            "sessionCode": 101,
            "json": {'username': username, 'password': password}
        };
        ServerApi.sendToServer(body, url, 'post', sessionCode, updateSuccessFn, updateErrorFn)
    }

}

import { Col, Layout, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { BiRectangle } from "react-icons/bi";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// https://codedaily.io/tutorials/The-Shapes-of-React-Native
import { FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import STYLES from '../../types/box_styles';
import IconFinder from "../../types/logic/screen/Icon_finder";
import ShapeBuilder from "../../types/logic/shape_builder";
import '../index.css';
import Shapes from "./shapes";
import SSQaure from "./s_square";
import STriangle from "./s_triangle";
// https://react-icons.github.io/react-icons/#/
require('react-web-vector-icons/fonts');

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

class TestPage extends React.Component {
    constructor(props) {
        super(props)


    }

    componentDidMount() {

    }
    drawText(textValue, textStyle) {
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}  >

                <label style={textStyle}>{textValue}</label>
            </Col>
        )
        // return 

    }
    findIconSet(iconName) {
        return IconFinder.findIconSet(iconName)
        // var IconComponent = Icons[iconName];
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_BS[iconName]
        // }

        // if (IconComponent == undefined) {
        //     IconComponent = Icons_BI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_MD[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_RI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_CG[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_GI[iconName]

        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_GR[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_HI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_SI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_TI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_FI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_IO[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_GO[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_DI[iconName]
        // }
        // if (IconComponent == undefined) {
        //     IconComponent = Icons_AI[iconName]
        // }





        // return IconComponent
    }
    drawPic2(moreItems) {
        var items = []
        // items.length
        for (var i = 0; i < moreItems.length; i++) {
            // alert(moreItems)
            var count = moreItems[i].count
            var iconName = moreItems[i].iconName
            var style = moreItems[i].style
            for (var j = 0; j < count; j++) {
                var IconComponent = IconFinder.findIconSet(iconName)(iconName)


                items.push(<IconComponent size={40} style={style} />)

            }
        }
        return (
            this.wrapStack(items)
        )
    }
    drawPic(moreItems) {
        var items = []
        // items.length
        for (var i = 0; i < moreItems.length; i++) {
            // alert(moreItems)
            var count = moreItems[i].count
            var iconName = moreItems[i].iconName
            var style = moreItems[i].style
            for (var j = 0; j < count; j++) {
                var IconComponent = IconFinder.findIconSet(iconName)


                items.push(<IconComponent size={40} style={style} />)

            }
        }
        return (
            this.wrap(items)
        )
    }
    wrap(comp) {
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}  >

                {comp}
            </Col>
        )
    }
    wrapStack(comp) {
        var items = []
        for (var c in comp) {
            items.push(<div>{comp[c]}</div>)
        }
        return (
            <Col type="flex" style={{ width: 200, height: 100, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'top', }}  >

                {items}
            </Col>
        )
    }
    circleStyle(overrides) {
        const defaultSettings = {
            // padding: 10,
            // margin: 20,
            display: "inline-block",
            // position:'absolute',
            backgroundColor: 'black',
            borderRadius: "50%",
            width: 80,
            height: 80,

            left: 0,
            top: 0,
            alignItems: 'center',
            display: 'flex', justifyContent: 'center'
        };

        // if(overrides!=undefined){
        //     for (const [key, value] of Object.entries(overrides)) {
        //         defaultSettings[key]=value
        //     }
        // }
        return defaultSettings

    }
    triangleStyle(overrides) {
        const defaultSettings = {
            width: 0,
            height: 0,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderTopWidth: 0,
            borderRightWidth: 20,
            borderBottomWidth: 35,
            borderLeftWidth: 20,
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: 'white',
            borderLeftColor: 'transparent',
        };
        if (overrides != undefined) {
            for (const [key, value] of Object.entries(overrides)) {
                defaultSettings[key] = value
            }
        }

        return defaultSettings

    }
    squareStyle(overrides) {
        const defaultSettings = {
            display: "inline-block",
            // position:'absolute',
            backgroundColor: 'red',
            // borderRadius: "50%",
            width: 30,
            height: 30,
            left: 0,
            top: 0
        };
        if (overrides != undefined) {
            for (const [key, value] of Object.entries(overrides)) {
                defaultSettings[key] = value
            }
        }
        return defaultSettings
    }

    drawShape(shape1, shape2) {


        return (
            <>
                {/* <div style={circleStyle} ></div> */}
                {/* <div style={circleStyle} >
                <div style={square}></div>
            </div> */}
                <div style={shape1} >
                    <div style={shape2}></div>
                </div>
            </>
        );
    }

    bulid_something() {

    }

    render() {
        // NumberBox
        var triangle_drawer = this.wrap(<div style={STYLES._TRIANGLE}></div>)
        const mystyle = {
            background: 'linear-gradient(to right, #430089, #82ffa1)'
        }
        var settings = [
            { _obj: 'RECTANGLE', backgroundColor: 'white', width: '20px' },
            { _obj: 'RECTANGLE', transform: 'rotate(-45deg)', backgroundColor: 'black', width: '20px' },
            { _obj: 'RECTANGLE', transform: 'rotate(-90deg)', backgroundColor: 'white', width: '20px' },
            { _obj: 'RECTANGLE', transform: 'rotate(-135deg)', backgroundColor: 'black', width: '20px' }
        ]

        var shapes = new ShapeBuilder().build(settings)
        console.log("00000000")
        console.log(shapes)
        var rectList = []
        for (var i in shapes) {
            rectList.push(<Col><Shapes style2={shapes[i]}></Shapes></Col>)
        }
        //   console.log("final_prop")
        //   console.log(final_prop)
        return (
            <div>

                {/* <FaHeart />   */}
                {/* https://react-icons.github.io/react-icons/icons?name=fa */}
                <div style={{ borderWidth: "10px", borderColor: 'red' }}    >
                    {/* <Row type="flex" align="middle" > */}

                    <Row>
                        <Col>{this.wrap(this.drawShape(this.circleStyle({ backgroundColor: 'white' }), this.triangleStyle({ borderBottomColor: 'white' })))}</Col>
                        <Col>{this.wrap(this.drawShape(this.circleStyle(), this.squareStyle({ backgroundColor: 'white' })))}</Col>
                        <Col>{this.wrap(this.drawShape(this.triangleStyle({ borderBottomColor: 'grey' }), this.squareStyle({ backgroundColor: 'red' })))}</Col>

                    </Row>
                    <Row>
                        {rectList}
                    </Row>
                    <Row>
                        {/* <Col>{this.wrap(<div style={STYLES._TRIANGLE}></div>)}</Col> */}
                        {/* <Col>{this.wrap(<div style={STYLES._TRIANGLE}></div>)}</Col> */}
                        {/* <div style={mystyle}>
                            Linear Gradient</div> */}
                        {/* <Col><STriangle></STriangle></Col> */}
                        {/* <Col><SSQaure></SSQaure></Col> */}
                        <Col><SSQaure style2_small={STYLES.RECTANGLE_SMALL}></SSQaure></Col>
                        <Col><SSQaure style2={{ transform: 'rotate(-45deg)' }} style2_small={STYLES.RECTANGLE_SMALL_FILL}></SSQaure></Col>
                        <Col><SSQaure style2={{ transform: 'rotate(-90deg)' }} style2_small={STYLES.RECTANGLE_SMALL}></SSQaure></Col>
                        <Col><SSQaure style2={{ transform: 'rotate(-135deg)' }} style2_small={STYLES.RECTANGLE_SMALL_FILL}></SSQaure></Col>

                        <Col><SSQaure style2={{ transform: 'rotate(-45deg)' }}></SSQaure></Col>
                        <Col><SSQaure style2={{ transform: 'rotate(-180deg)' }}></SSQaure></Col>
                        <Col><SSQaure style2={{ transform: 'rotate(-135deg)' }}></SSQaure></Col>
                        <Col><STriangle style2={{ transform: 'rotate(-270deg)', borderBottomColor: 'black' }} ></STriangle></Col>
                        {/* <Col><STriangle style2={{}} ></STriangle></Col> */}
                        <Col>{this.wrap(this.drawShape(this.circleStyle(), this.squareStyle({ backgroundColor: 'white' })))}</Col>
                        <Col>{this.wrap(this.drawShape(this.triangleStyle({ borderBottomColor: 'grey' }), this.squareStyle({ backgroundColor: 'red' })))}</Col>

                    </Row>
                    <Row>

                        {/* <Col><SArrow></SArrow></Col>
                        <Col><SArrow style2={STYLES._RIGHT_CC}></SArrow></Col> */}

                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'FaArrowUp', count: 1 }])}
                        {this.drawPic([{ iconName: 'FaArrowRight', count: 1 }])}
                        {this.drawPic([{ iconName: 'FaArrowDown', count: 1 }])}
                        {this.drawPic([{ iconName: 'FaArrowLeft', count: 1 }])}
                    </Row>
                    <Row   >




                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(-90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(-180deg)' } }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(-270deg)' } }])}


                    </Row>
                    <Row   >



                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BsArrow90DegLeft', count: 1, style: { transform: 'rotate(270deg)' } }])}


                    </Row>
                    <Row>
                        {this.drawText('E', { transform: 'rotate(-0deg)', fontSize: 70 })}
                        {this.drawText('E', { transform: 'rotate(-45deg)', fontSize: 70 })}
                        {this.drawText('E', { transform: 'rotate(-90deg)', fontSize: 70 })}
                        {this.drawText('E', { transform: 'rotate(-90deg)', fontSize: 70 })}
                    </Row>
                    <Row   >



                        {this.drawPic([{ iconName: 'BiSmile', count: 1, }])}
                        {this.drawPic([{ iconName: 'BiSmile', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BiSmile', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BiSmile', count: 1, style: { transform: 'rotate(270deg)' } }])}


                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BsFillFileArrowUpFill', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsFillFileArrowUpFill', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsFillFileArrowUpFill', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BsFillFileArrowUpFill', count: 1, style: { transform: 'rotate(270deg)' } }])}
                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BiWalletAlt', count: 1, }])}
                        {this.drawPic([{ iconName: 'BiWalletAlt', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BiWalletAlt', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BiWalletAlt', count: 1, style: { transform: 'rotate(270deg)' } }])}
                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BiRegistered', count: 1, }])}
                        {this.drawPic([{ iconName: 'BiRegistered', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BiRegistered', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BiRegistered', count: 1, style: { transform: 'rotate(270deg)' } }])}
                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'MdSubdirectoryArrowLeft', count: 1, }])}
                        {this.drawPic([{ iconName: 'MdSubdirectoryArrowLeft', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'MdSubdirectoryArrowLeft', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'MdSubdirectoryArrowLeft', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BsTriangle', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsSquare', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsPentagon', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsHexagon', count: 1, }])}

                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BsTriangle', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsSquareFill', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsPentagon', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsHexagonFill', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsHeptagon', count: 1, }])}

                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BsTriangle', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1 }])}
                        {this.drawPic([{ iconName: 'BsPentagon', count: 1, }])}
                        {this.drawPic([{ iconName: 'BsHexagonHalf', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BsHeptagon', count: 1, }])}

                    </Row>
                    <Row   >
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1, style: { transform: 'rotate(180deg)' } }])}
                        {this.drawPic([{ iconName: 'BsSquareHalf', count: 1, }])}

                    </Row>
                    <Row   >
                        {this.wrap(<BiRectangle size={90}><BiRectangle size={20} /></BiRectangle>)}
                    </Row>

                    <Row   >


                        {this.drawPic([{ iconName: 'FaSquare', count: 1 }])}
                        {this.drawPic([{ iconName: 'FaRegSquare', count: 2 }])}
                        {this.drawPic([{ iconName: 'FaRegCircle', count: 3 }])}
                        {this.drawPic([{ iconName: 'FaCircle', count: 4 }])}
                    </Row>
                    <Row   >


                        {this.drawPic([{ iconName: 'RiSpeakerLine', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSpeakerLine', count: 2, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSpeakerLine', count: 3, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSpeakerLine', count: 4, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'FaArrowUp', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaArrowRight', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaArrowDown', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaArrowLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'RiUmbrellaLine', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiUmbrellaLine', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiUmbrellaLine', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiUmbrellaFill', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgArrowBottomLeftR', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowBottomLeftR', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowBottomLeftR', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowBottomLeftR', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgArrowTopLeftO', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowTopLeftO', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowTopLeftO', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgArrowTopLeftO', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >
                        {/* 
                        {this.drawPic2([{ iconName: 'FaArrowUp', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic2([{ iconName: 'FaArrowUp', count: 2, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic2([{ iconName: 'FaArrowUp', count: 3, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic2([{ iconName: 'FaArrowUp', count: 4, style: { transform: 'rotate(135deg)' } }])} */}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaCandyCane', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCandyCane', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCandyCane', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCandyCane', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaCircleNotch', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCircleNotch', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCircleNotch', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCircleNotch', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaCross', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCross', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCross', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCross', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaCube', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCube', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCube', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaCube', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaDivide', count: 1, style: { transform: 'rotate(10deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDivide', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDivide', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDivide', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'FaDizzy', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDizzy', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDizzy', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaDizzy', count: 1, style: { transform: 'rotate(135deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'FaFish', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FaFish', count: 2, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FaFish', count: 3, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FaFish', count: 4, style: { transform: 'rotate(135deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'GiBattery0', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery25', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery50', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery75', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery100', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'GiBattery0', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery25', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery50', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery75', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'GiBattery100', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'GrLocal', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GrLocal', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'GrLocal', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'GrLocal', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'GrLocal', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'HiOutlineViewGridAdd', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'HiOutlineViewGridAdd', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'HiOutlineViewGridAdd', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'HiOutlineViewGridAdd', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'HiOutlineViewGridAdd', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'RiLayoutLeft2Line', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiLayoutLeft2Fill', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiLayoutLeft2Line', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiLayoutLeft2Fill', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'RiLayoutLeft2Line', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'RiScanLine', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiScanFill', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiScanLine', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiScanFill', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'RiScanLine', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'SiKongregate', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKongregate', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKongregate', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKongregate', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKongregate', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'SiKotlin', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKotlin', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKotlin', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKotlin', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiKotlin', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'SiShadow', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiShadow', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiShadow', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiShadow', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiShadow', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>


                    <Row   >

                        {this.drawPic([{ iconName: 'SiSquareenix', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSquareenix', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSquareenix', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSquareenix', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSquareenix', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'SiSubstack', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSubstack', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSubstack', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSubstack', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiSubstack', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'SiZotero', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiZotero', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiZotero', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiZotero', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiZotero', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'SiWritedotas', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiWritedotas', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiWritedotas', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiWritedotas', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiWritedotas', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'TiPinOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPin', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPinOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPin', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPinOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'TiPlaneOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPlane', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPlaneOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPlane', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'TiPlaneOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'CgEditExposure', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgEditExposure', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgEditExposure', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgEditExposure', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'CgEditExposure', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'CgLock', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 2, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 3, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 4, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 5, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgLock', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLockUnlock', count: 2, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 3, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLockUnlock', count: 4, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgLock', count: 5, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgUnsplash', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgUnsplash', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgUnsplash', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgUnsplash', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'CgUnsplash', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgStudio', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgStudio', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgStudio', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgStudio', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'CgStudio', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'CgThermostat', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'CgThermostat', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'CgThermostat', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'CgThermostat', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'CgThermostat', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'TiAnchor', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'TiAnchor', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'TiAnchor', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'TiAnchor', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'TiAnchor', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'RiSendPlane2Line', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSendPlane2Fill', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSendPlane2Line', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSendPlane2Fill', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'RiSendPlane2Line', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BiBorderNone', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BiBorderLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BiBorderTop', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BiBorderRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BiBorderBottom', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'FiUnderline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'FiUnderline', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'FiUnderline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'FiUnderline', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'FiUnderline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'RiClockwise2Line', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiClockwise2Line', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiClockwise2Line', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiClockwise2Line', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'RiClockwise2Line', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'RiAnticlockwiseFill', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'RiAnticlockwiseFill', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'RiAnticlockwiseFill', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'RiAnticlockwiseFill', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'RiAnticlockwiseFill', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigate', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'IoNavigateOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'IoPowerOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPowerOutline', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPowerOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPowerOutline', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPowerOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BsCaretUpSquareFill', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCaretUpSquare', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCaretUpSquareFill', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCaretUpSquare', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCaretUpSquareFill', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BsRecordCircle', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsRecordCircle', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'BsCircleSquare', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>

                    <Row   >

                        {this.drawPic([{ iconName: 'IoPlanetOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPlanetSharp', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPlanetOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPlanetSharp', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'IoPlanetOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>


                    <Row   >

                        {this.drawPic([{ iconName: 'SiPlanet', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'SiPlanet', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'SiPlanet', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'SiPlanet', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'SiPlanet', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'GoDeviceCameraVideo', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GoDeviceCameraVideo', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'GoDeviceCameraVideo', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'GoDeviceCameraVideo', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'GoDeviceCameraVideo', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'GoThreeBars', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThreeBars', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThreeBars', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThreeBars', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThreeBars', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'GoThumbsup', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThumbsup', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThumbsup', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThumbsup', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'GoThumbsup', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BsBoxArrowInRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowInDownRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowInDown', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowInDownLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowInLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BsBoxArrowLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowUpLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowUp', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowUpRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowDownRight', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowDown', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BsBoxArrowDownLeft', count: 1, style: { transform: 'rotate(0deg)' } }])}

                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'DiAndroid', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'DiAndroid', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'DiAndroid', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'DiAndroid', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'DiAndroid', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'DiBootstrap', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'DiBootstrap', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'DiBootstrap', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'DiBootstrap', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'DiBootstrap', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'HiChartPie', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'HiChartPie', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'HiChartPie', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'HiChartPie', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'HiChartPie', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'AiOutlinePieChart', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'AiOutlinePieChart', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'AiOutlinePieChart', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'AiOutlinePieChart', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'AiOutlinePieChart', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'BiPieChart', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'BiPieChart', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'BiPieChart', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'BiPieChart', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'BiPieChart', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>
                    <Row   >

                        {this.drawPic([{ iconName: 'MdOutlinePieChartOutline', count: 1, style: { transform: 'rotate(0deg)' } }])}
                        {this.drawPic([{ iconName: 'MdOutlinePieChartOutline', count: 1, style: { transform: 'rotate(45deg)' } }])}
                        {this.drawPic([{ iconName: 'MdOutlinePieChartOutline', count: 1, style: { transform: 'rotate(90deg)' } }])}
                        {this.drawPic([{ iconName: 'MdOutlinePieChartOutline', count: 1, style: { transform: 'rotate(135deg)' } }])}
                        {this.drawPic([{ iconName: 'MdOutlinePieChartOutline', count: 1, style: { transform: 'rotate(180deg)' } }])}
                    </Row>





























                    BiBorderNone







































                </div>
                <FaArrowRight size={40} />
                <FaArrowDown size={40} />
                <FaArrowLeft size={40} />

                Test page
                </div>
        )

    }
}


export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <TestPage location={location} history={history} />
    )
});

import { paginateArray } from "./common_util"

export default class QuestionPage {
    constructor(rows, pageSize) {
        this.rows = rows
        // if(rows.length>0){
        //     console.log(rows[0])
        //     pageSize=rows[0]._problemType
        // }
        this.currentPage = 1
        this.totalCount = rows.length
        this.pageSize = pageSize
        this.pageCount = Math.ceil(this.rows.length/this.pageSize)
        // this.adjustView()
        // this.viewRows = paginateArray(rows, 5, currentPage)
        console.log("rows ---->")
        console.log(rows)
    }
    getTotalCount(){
        return this.totalCount
    }

    goToNextPage() {
        if (this.isLastPage()) {
            return this
        }
        this.currentPage = this.currentPage + 1
        return this
    }
    getStartQuestionNumber() {
        return (this.currentPage - 1) * this.pageSize
    }
    goToFirstPage() {
        this.currentPage = 1
        return this
    }
    goToLastPage() {
        while (!this.isLastPage()) {
            this.goToNextPage()
        }
    }
    isLastPage() {
        return !this.peekView(this.currentPage + 1).length
    }
    isFirstPage() {
        return this.currentPage == 1
    }
    getCurrentPage(){
        return this.currentPage
    }
    getPageCount(){
        return this.pageCount
    }
    goToPreviousPage() {
        if (this.isFirstPage()) {
            return this
        }
        this.currentPage = this.currentPage - 1
        return this.getView()
    }
    getView() {
        return this.peekView(this.currentPage)
    }
    peekView(currentPage) {
        return paginateArray(this.rows, this.pageSize, currentPage)
    }
}
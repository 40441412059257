import { Layout } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import UserSession from '../user_session';
import AppConstant from './app_constant';
import ExamApi from '../api/exam_api';
import ExamManager from '../exam/exam_manager';
import UIPageRouterCore from './ui_page_router_core';
import AppRoutes from '../../app_routes';
const { Header, Content, Footer, Sider } = Layout;
export default class UIPageRouterCommon {


    static goToExamReady(uiRouter, examSheet, pageActionValue) {
        UserSession.getInstance().registerForExam(examSheet);
        uiRouter.props.history.push({
            pathname: AppRoutes.EXAM_PAGE_PATH,
            pageAction: pageActionValue
        })
    }
    
    static examEvaluationCompleted(uiRouter, evaluatedResult) {
        let examSheet = UserSession.getInstance().getUserExamSheet()
        examSheet.examResult = evaluatedResult.examResult
        examSheet.answerSheet = evaluatedResult.answerSheet
        examSheet.status = evaluatedResult.examStatus
        examSheet.userAnswerSheet = evaluatedResult.userAnswerSheet
        examSheet.evaluationSummaryNote = evaluatedResult.evaluationSummaryNote
        console.log("setting evaluation summary note")
        console.log(examSheet)
        UserSession.getInstance().registerForExam(examSheet)
        UserSession.getInstance().registerPostEvaulationAnswer(examSheet.answerSheet)
        UIPageRouterCommon.goToResultPage(uiRouter)
        console.log("evaluatedResult ---->")
        console.log("evaluatedResult ---->")
    }

    static retryThisExam(selfCurrent) {
        console.log("this.props")
        let examRef = UserSession.getInstance().getUserExamSheet().examRef
        selfCurrent.props.history.push({
            pathname: AppRoutes.UI_ROUTER_PATH,
            pageAction: AppConstant.RETRY_THIS_EXAM_REQUEST,
            origExamRef: examRef

        })
    }

    static goToMyExamHistoryPage(uiRouter, serverResult) {
        console.log("going to goToMyExamHistoryPage")
        uiRouter.props.history.push({
            pathname: AppRoutes.MY_EXAM_HISTORY_PATH,
            serverResult: serverResult
        })
    }
    static getMyExamHistories(selfCurrent) {
        selfCurrent.props.history.push({
            pathname: AppRoutes.UI_ROUTER_PATH,
            pageAction: AppConstant.MY_EXAM_HISTORIES_REQUEST,
        })
    }
    static startABrandNewExam(selfCurrent) {
        console.log("starting a brand new")
        UIPageRouterCore.handleOutgoingToServer(selfCurrent, AppConstant.START_EXAM_REQUEST)
    }
    // static tryLogin(selfCurrent, otherAttributes) {
    //     const originalMap = {
    //         pathname: AppRoutes.SIMPLE_ROUTER,
    //         callBackComponent: selfCurrent,
    //         pageAction: pageActionValue
    //     };
    //     console.log(originalMap)
    //     // console.log(moreMap)
    //     // for (var i in moreMap)
    //     //     originalMap[i] = moreMap[i];
    //     console.log(" more map.....")
    //     // console.log(originalMap)
    //     selfCurrent.props.history.push(otherAttributes)
    //
    //     // UIPageRouterCore.handleOutgoingToServer(selfCurrent, AppConstant.TRY_LOGIN, otherAttributes)
    // }

    static submitForEvaluation(selfCurrent) {
        UIPageRouterCore.handleOutgoingToServer(selfCurrent, AppConstant.EVALATE_EXAM_REQUEST)

    }

    static goToSelectionPage(selfCurrent) {
        UIPageRouterCore.handleInterPageRouting(selfCurrent, '/user-selection', null)
    }
    static goToResultPage() {
        const studentRef = UserSession.getInstance().getStudentRef()
        let examSheet = UserSession.getInstance().getUserExamSheet()
        var result = examSheet.examResult
        this.props.history.push({
            pathname: AppRoutes.EXAM_REULST_PATH,
            examResult: result,
            evaluationSummaryNote: examSheet.evaluationSummaryNote,
        });

    }

    // static goToTryLoginPage(selfCurrent, extraAttributeMap){
    //     var submissionPath=AppRoutes.UI_ROUTER_PATH
    //     var onSuccessPath=AppRoutes.UI_ROUTER_PATH
    //     var onFailurePath=AppRoutes.UI_ROUTER_PATH
    //     // var pageAction=AppRoutes.UI_ROUTER_PATH
    //
    //     const originalMap = {
    //         pathname: submissionPath,
    //         callBackComponent: selfCurrent,
    //         pageAction: pageActionValue,
    //         extraAttributeMap:extraAttributeMap
    //     };
    //     console.log(originalMap)
    //     // console.log(moreMap)
    //     // for (var i in moreMap)
    //     //     originalMap[i] = moreMap[i];
    //     console.log(" more map.....")
    //     // console.log(originalMap)
    //     selfCurrent.props.history.push(originalMap)
    //
    // }

    findSelectedEntry() {
        var userSelection = UserSession.getInstance().getUserSelection();
        var entry = userSelection.findSelectedEntry();
        return entry;
    }

    static startExamNow(self, categlorySelected, subCategorySelected) {
        // UserSession.getInstance().setUserSelection(userSelection)
        var userSelection = UserSession.getInstance().getUserSelection();
        var entry = userSelection.findSelectedEntry();
        entry.setFilterMetaCategoryKeyTags(categlorySelected)
        entry.setFilterMetaKeyTags(subCategorySelected)
        var userSelection = UserSession.getInstance().getUserSelection()
        userSelection.setProblemType(userSelection.findDefaultProblemType())
        UIPageRouterCommon.routeToExamPage(self)


    }

    static routeToExamPage(self) {
        self.props.history.push({
            pathname: AppRoutes.EXAM_PAGE_PATH,
        })
    }



    static closeTheCurrentExam(self) {
        var examSheet = UserSession.getInstance().getUserExamSheet()
        ExamApi.closeExam()
        ExamManager.getInstance().deregisterExam(examSheet)
        UIPageRouterCommon.goToSelectionPage(self)
    }





    static goToResultPage(currentSelf) {
        let examSheet = UserSession.getInstance().getUserExamSheet()
        var result = examSheet.examResult
        currentSelf.props.history.push({
            pathname: AppRoutes.EXAM_REULST_PATH,
            examResult: result,
            evaluationSummaryNote: examSheet.evaluationSummaryNote,
        });

    }

}

import { Col, Layout } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import '../../../index.css'
import NumberBoxRow from './number_row';
const { Header, Content, Footer, Sider } = Layout;
export default class NumberBox extends React.Component {
  constructor(props) {
    super(props)
    console.log("number box props")
    console.log(props)
    console.log(this.props)
  }

  buildNumberRowsData(currentProblem) {
    var numbers = []
    numbers.push(currentProblem._firstRow)
    numbers.push(currentProblem._secondRow)
    numbers.push(currentProblem._thirdRow)
    numbers = numbers.flat()
    return numbers
  }

  render() {
    console.log("this.props number box")
    console.log(this.props)
    var numbers = this.buildNumberRowsData(this.props.currentProblem)
    return (
      <Col  >
        <NumberBoxRow solutionHint={this.props.solutionHint}
        answerCorrect={this.props.answerCorrect} 
        evaluatedAlready={this.props.evaluatedAlready} 
        key={this.props.problemRef} userAnswer={this.props.userAnswer} 
        numbers={numbers} actionNameCallbackFn={this.props.actionNameCallbackFn()} />
      </Col>
    )
  }
}



import { Input, Tooltip } from 'antd';
import React from 'react';/*  */
function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };
  // handleFocus = (event) => {
  //   // alert('h')
  //   event.target.select();
  //   // event.target.value="5"
  // }

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
        <Input
        {...this.props}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholder="?"
        maxLength={25}
        onFocus={this.props.onFocus2} 
      />
    );
  }
}

export default class NumericInputUI extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { value: String(this.getValidValue(this.props.value)) };
    // this.state = { value: 5};
    
  }
  getValidValue(value){
    if(value){
      return String(value)
    }
    return "?"
  }

  onChange = originalValue => {
    var changedValue = this.getValidValue(originalValue)
    this.setState({ value:changedValue });
    this.props.actionNameCallbackFn(changedValue)
    // alert(value)
  };
  handleFocus = (event) => {
    // alert('h')
    this.setState({value:""})
    // event.target.select();
    
  }
  

  render() {
    return (
      <NumericInput style={this.props.style} value={this.state.value} 
      onChange={this.onChange} onFocus2={this.handleFocus}
      />
    );
  }
}

// ReactDOM.render(<NumericInputDemo />, mountNode);
// /* to prevent the arrow overflow the popup container,
// or the height is not enough when content is empty */
// .numeric-input .ant-tooltip-inner {
//   min-width: 32px;
//   min-height: 37px;
// }

// .numeric-input .numeric-input-title {
//   font-size: 14px;
// }
import { Button, Col, Layout, Row, Select } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import strings from '../../resource/i18n_resource';
import * as ExamConfig from "../exam/exam_config";
import '../index.css';
import UserSelection from '../exam/user_selection.ts';
import UserSelectionEntry from '../exam/user_selection_entry';
import UserSession from '../user_session';
import { Input, Space } from 'antd';
import PageUtil from './page_util';
import UIPageRouterCommon from './ui_page_router_common';
const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            authenticated: false
        }

    }

    onChange = (e) => {
        this.setState({ inputValue: e.target.value })
        // alert(e.target.value)
    }
    validate() {
        let authenticatedYn = UserSession.getInstance().validateCode(this.state.inputValue)
        this.setState({ authenticated: authenticatedYn })
        var self = this
        UIPageRouterCommon.goToSelectionPage(self)
        // alert(authenticatedYn)
    }
    render() {

        return (
            <Content style={{ margin: '0 16px', alignSelf: 'left' }}>
                <div style={{ fontSize: '30px' }}>Logitica</div>
                Please send email to <Space style={{ color: 'blue' }}>reetu.wisgo@gmail.com</Space> to get code.
        </Content>
        )

    }
}


export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <ContactUsPage location={location} history={history} />
    )
});

import AppConstant from "../screen/app_constant"


export default class ErrorMessages {

    static ERROR_500(serverMsg, serverStatusCode) {
        return {
            retryMsg: 'Retry again',
            errorType: AppConstant.ERROR_TYPE_UNAVAILABLE,
            serverMsg: serverMsg,
            serverStatusCode: serverStatusCode,
            userMessage: "Service Unavilable, retry",
            maxRetry: 10
        }
    }
    static ERROR_401(serverMsg, serverStatusCode) {
        return {
            retryMsg: 'Unauthroised',
            errorType: AppConstant.ERROR_TYPE_UNAUTHORISED,
            serverMsg: serverMsg,
            serverStatusCode: serverStatusCode,
            userMessage: "Unauthorised, go to login page",
            maxRetry: -1
        }
    }
    static ERROR_UNKNOWN(serverMsg, serverStatusCode) {
        return {
            retryMsg: 'Unauthroised',
            errorType: AppConstant.ERROR_TYPE_UNAUTHORISED,
            serverMsg: serverMsg,
            userMessage: "unknown error, close your browser",
            serverStatusCode: serverStatusCode,
            maxRetry: -1
        }
    }
    static ERROR_NONE(){
        return {
            retryMsg: '',
            errorType: AppConstant.ERROR_TYPE_NONE,
            serverMsg: "",
            userMessage: "",
            serverStatusCode: -99,
            maxRetry: -1
        }
    }

}
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import UserSession from '../user_session';
import ExamHandler from '../api/exam_handler';
import UIPageRouterCommon from './ui_page_router_common';
import ExamManager from '../exam/exam_manager';
import LoginHandler from "../api/login_handler.js";
import AppRoutes from "../../app_routes.js";
const { Header, Content, Footer, Sider } = Layout;
export default class DataAccessApi {

        static addToStore(key, objValue){
        DataAccessApi.addJsonToStore(key, JSON.stringify(objValue))
    }
    static addJsonToStore(key, objValue){
        localStorage.setItem(key,objValue)
    }
    static readJsonFromStore(key){
        return JSON.parse(localStorage.getItem(key))
    }
    static readFromStore(key){
        return localStorage.getItem(key)
    }
    static putUserInfo(objValue){
        DataAccessApi.addJsonToStore('user_info', objValue)
    }
    static getUserInfo(){
        return DataAccessApi.readJsonFromStore('user_info')
    }

    static putAccessToken(objValue){
         DataAccessApi.addJsonToStore('access_token', objValue)
    }
    static getAccessToken(){
        return DataAccessApi.readFromStore('access_token')
    }



}

import strings from '../../resource/i18n_resource';
// let NumberBox=""
// export ()
export const PROBELM_TYPE = {

    NumberBox: strings.PROBLEM_TYPE.Number_Box,
    LogicMatrix: strings.PROBLEM_TYPE.Logic_Matrix
}

export const PROBELM_TYPE_KEY = {

    NumberBox: "Number Box",
    LogicMatrix: "Logic Matrix"
}
export const COMMON_CONSTANTS = {
    PROBLEM_PER_PAGE : 1,
    PROBLEM_PER_PAGE_MAP : {
        [PROBELM_TYPE_KEY.NumberBox]:10,
        [PROBELM_TYPE_KEY.LogicMatrix]:1,
    }


}



export const PROBLEM_LEVEL = {
    MINUS_1: -1,
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    HIGHER: 5
}
export const PROBLEM_COUNT = {
    ONE: 1,
    TEN: 10,
    FIVE: 5,
    TWENTY: 20,
    TWENTY_FIVE: 25,
    THRITY: 30,
    FIFTY: 50,
    HUNDRED: 100,
    ONE_THOUSAND: 1000,
}

export const EXAM_SHEET_STATUS = {
    INITIATED: "Initiated",
    ATTEMPTING: "Attempting",
    EVALUATED: "EVALUATED",
    COMPLETED: "COMPLETED",
    
}

import axios from 'axios';
import ErrorMessages from '../exam/error_messages';

export default class ServerApi {


    static sendToServer(body, url, submitMethod, sessionCode, updateResultFn, updateErrorFn, header) {
        axios({
            method: submitMethod,
            url: url,
            headers:header,
            data: {
                'body': body,
                'sessionCode': sessionCode
            },
            sessionCode: sessionCode,
        })
            .then((response) => {
                console.log("response received")
                console.log(response)
                console.log(response.data)
                return response.data
            })
            .then((data) => {
                console.log("data received")
                console.log(data)
                if(updateResultFn!=null){
                    updateResultFn(data)
                }

            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                console.log(error.response)
                console.log("---------")
                var serverResponseCode = error.response.status
                var serverMessage = ErrorMessages.ERROR_UNKNOWN(error.response, serverResponseCode)
                if (serverResponseCode == 401) {
                    serverMessage = error.response['data']['serverMessage']
                    serverMessage = ErrorMessages.ERROR_401(serverMessage, serverResponseCode)
                }
                else if (serverResponseCode == 500) {
                    serverMessage = error.response['data']['serverMessage']
                    serverMessage = ErrorMessages.ERROR_500(serverMessage, serverResponseCode)
                }
                if(updateErrorFn!=null){
                    updateErrorFn(serverMessage)
                }
                // updateErrorFn(serverMessage)
            });

    }
}
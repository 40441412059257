import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import { withRouter } from "react-router";
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link, useHistory } from "react-router-dom";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import UserAgeUI from './preferences/age_ui';
const { Header, Content, Footer, Sider } = Layout;
 class Home extends React.Component {
    render() {
        return <Content style={{ margin: '0 16px',alignSelf:'left' }}>
           
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            Welcome to Logitica. Select your level
            <UserAgeUI/>
            </div>
            
          </Content>
       
    }
}


  
  export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
      <Home location={location}  history={history} />
    )
  });
  
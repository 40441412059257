import AbstractSheet from "../exam/Abstract_sheet"

export default class UserAnswerSheet extends AbstractSheet{

    constructor(userAnswers){
        super(userAnswers)
        
    }
   
    findUserAnswer(problemRef) {
        // console.log("problemRef")
        // console.log(super.getAllThings())
        return super.findThing(problemRef)
    } 
    updateUserAnswer(problemRef, value){
        
        var userAnswer = this.findUserAnswer(problemRef)
        if(value){
            userAnswer.setUserAnswerValue(value)
        }   
        else{
            userAnswer.reset()
        }

    }
    static updateUserAnswerFor(userAnswerSheet, problemRef, value){
        
        var userAnswer = UserAnswerSheet.findUserAnswerFor(userAnswerSheet, problemRef)
        if(value){
            userAnswer.userAnswerValue=value
            userAnswer.attempted=true
        }   
        else{
            userAnswer.userAnswerValue = "??????"
            userAnswer.attempted=false
        }

    }
    getAllUserAnswers(){
        return super.getAllThings()
    }
    static findUserAnswerFor(userAnswerSheet,problemRef) {
        // console.log("problemRef")
        // console.log(super.getAllThings())
        return AbstractSheet.findThingFor(userAnswerSheet, problemRef)
    } 

}
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import UserSession from '../user_session';
import ExamHandler from '../api/exam_handler';
const { Header, Content, Footer, Sider } = Layout;
export default class AppConstant {
    static TRY_LOGIN='TRY_LOGIN'

    static START_EXAM_REQUEST='START_EXAM_REQUEST'
    static EXAM_CREATED='EXAM_CREATED_RESPONSE'
    
    static RETRY_THIS_EXAM_REQUEST='RETRY_EXAM_REQUEST'
    static RETRY_EXAM_CREATED='RETRY_EXAM_DONE_RESPONSE'
    
    static MY_EXAM_HISTORIES_REQUEST='MY_EXAM_HISTORIES_REQUEST'
    static MY_EXAM_HISTORIES_RESPONSE='MY_EXAM_HISTORIES_RESPONSE'

    static LOAD_EXAM_REQUEST='LOAD_EXAM_REQUEST'
    static LOAD_EXAM_RESPONSE='LOAD_EXAM_RESPONSE'


    static EXAM_EVALUATED='EXAM_EVALUATED_RESPONSE'
    static EVALATE_EXAM_REQUEST='EXAM_EVALUATED_REQUEST'
    static EVALATE_EXAM_COMPLETED_RESPONSE='EVALATE_EXAM_COMPLETED_RESPONSE'

    static GO_TO_RESULT_FOR_ALREDY_EVALUTED_EXAM='GO_TO_RESULT_FOR_ALREDY_EVALUTED_EXAM'

    static ERROR_TYPE_UNAUTHORISED ='ERROR_TYPE_UNAUTHORISED'
    static ERROR_TYPE_NONE ='ERROR_TYPE_NONE'
    static ERROR_TYPE_UNAVAILABLE ='ERROR_TYPE_UNAVAILABLE'
    static ERROR_TYPE_UNKNOWN ='ERROR_TYPE_UNKNOWN'

    static LOGIN_SUCCESSFUL='LOGIN SUCCESSFUL'
    static LOGIN_FAILED='LOGIN FAILED'

    static ADMIN_ROLE='ADMIN'
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../../../index.css'
import NUMBER_BOX_STYLES  from '../../box_styles';
import { Layout, Menu, Breadcrumb,Row,Col } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Input,InputNumber } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import NumericInputUI from './number_input';
const { Header, Content, Footer, Sider } = Layout;
const box_styles={
  margin:'3px',
  width:'60px',
  borderColor:'black', 
  borderWidth:'3px', 
  height:'50px',
  textAlign:'center',
  marginBottom:'40px'
  
 
}
const box_styles_middle={
  ...box_styles,
  color:'black',
  fontSize: 20,
  background:'black',
  color:'white',
}
const box_styles_left={
  ...box_styles,
  fontSize: 20,
  color:'black',
  
}
const box_styles_right={
  ...box_styles_left,
  marginRight:'50px'
  
}

export default class EachNumberBoxSolve extends React.Component {
    render() {
        return (<>
                <Input disabled style={NUMBER_BOX_STYLES.BOX_STYLES_LEFT} value={this.props.left}></Input>
                <NumericInputUI  actionNameCallbackFn={this.props.actionNameCallbackFn} style={NUMBER_BOX_STYLES.BOX_STYLES_MIDDLE_SOLVE}  value={this.props.userAnswerValueInput}></NumericInputUI>
                <Input disabled style={NUMBER_BOX_STYLES.BOX_STYLES_LEFT} value={this.props.right}></Input>
              </>)
       
    }
}

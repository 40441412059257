
import { Col, Radio, Row } from 'antd';
import ShapeBuilder from '../shape_builder';
import IconFinder from './Icon_finder';
import Shapes from './shapes';
import ShapeOptions from './shape_options';
import ShapesText from './shapes_text';
import GridShape from '../../../common/screen/grid_shape';
import GridURevShape from '../../../common/screen/grid_u_rev_shape';
export default class ShapeUtil {

    static wrap(comp) {
        return (
            <Col type="flex" style={{
                width: 200, height: 200,
                border: '2px solid black',
                margin: '20px', alignItems: 'center',
                display: 'flex',
                justifyContent: 'center', verticalAlign: 'middle'
            }}  >

                <div style={{ alignItems: 'center', justifyContent: 'center' }}>{comp}</div>
            </Col>)
    }

    static drawThisItem(eachSequence, wrap) {
        // wrap=false
        var itemList = []
        console.log("eachSequence-->")
        console.log(eachSequence)
        if (eachSequence.length > 0) {
            var shapesCreated = ShapeUtil.drawEachIcon(eachSequence)
            
            var builtItems = itemList.concat(shapesCreated)
            builtItems = wrap ? ShapeUtil.wrap(builtItems) : builtItems
            itemList.push(builtItems)
           
        }

        return itemList
    }
    static drawThis(item) {
        console.log("_objType -->"+item._objType)
        if (item._objType == 'Icon') {
            return ShapeUtil.drawIconOne(item)
        }
        else if (item._objType == 'Text') {
            console.log("Text type")
            console.log(item)
            return ShapeUtil.drawSingleText(item, true)
        }
        else if (item._objType == 'CustomShape_Grid') {
            console.log("Text type")
            console.log(item)
            return (<GridShape style2={item}/>)
            // return ShapeUtil.drawSingleText(item, true)
        }
        else if (item._objType == 'CustomShape_GridURev') {
            console.log("Text type")
            console.log(item)
            return (<GridURevShape style2={item}/>)
            // return ShapeUtil.drawSingleText(item, true)
        }

    }

    static drawSingleText(item, wrap) {
        var itemEntries = []
        var count = item.count;
        for (var j = 0; j < count; j++) {
            itemEntries.push(<font style={item['style']}>{item['_value']}</font>)
        }
        return itemEntries
    }

    static drawIconOne(item) {
        var entries = []
        var count = item.count;
        var iconName = item.iconName;
        var style = item.style;
        var itemSize = item.size
        var size = (itemSize === undefined || itemSize === null) ? 40 : item.size

        for (var j = 0; j < count; j++) {
            var IconComponent = IconFinder.findIconSet(iconName);
            entries.push(<IconComponent size={size} style={style} />);
        }
        return entries

    }

    static drawEachIcon(items) {
        var itemList = []
        for (var i in items) {
            var item = items[i]
            if (Array.isArray(item)) {
                for (var j in item) {
                    itemList.push(ShapeUtil.drawThis(item[j]))
                }
            }
            else {
                itemList.push(ShapeUtil.drawThis(item))
            }
        }
        return itemList
    }
    static buildOptions(shapeProperties) {


        var rectList = []
        var compList = []

        Object.keys(shapeProperties).map(function (key) {
            var shapeProperty = shapeProperties[key]
            var shapes = shapeProperty["shapes"]
            console.log("shapes----->")
            console.log(shapes)

            var _comp = ShapeUtil.drawThisItem(shapes, false)
            rectList.push(<ShapeOptions _comp={_comp} _value={shapeProperty['_value']} _label={shapeProperty['_radioLabel']}></ShapeOptions>)

            // compList = compList.concat(_comp)

        });

        var empty_options = []
        var current_options_len = rectList.length
        for(var index=0;index<(8 - current_options_len);index++){
            empty_options.push(<ShapeOptions noBorder={true}></ShapeOptions>)
        }
        var finalList = []
        finalList.push(
            <div>
               
            <Row style={{ width:'100%',  justifyContent: 'left', alignItems:'left'}}>
            
                {rectList}
                {empty_options}

                
            </Row>
            
            </div>

        )
        return finalList
        //
    }
    static drawIconAndShapes(moreItems) {
        var itemList = []

        for (var i = 0; i < moreItems.length; i++) {
            itemList = itemList.concat(ShapeUtil.drawThisItem(moreItems[i], true))
        }

        return (itemList)


    }

    static wrapOptions(comp) {
        return (
            <Col style={{ width: 200, height: 130, border: '2px solid black', padding: '20px', margin: '20px', alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle' }}  >
                {comp}
            </Col>
        )
    }

    static drawShapes(setting) {
        var shapeProperties = new ShapeBuilder().build(setting)
        var rectList = []

        for (var i in shapeProperties) {
            var shapPeroperty = shapeProperties[i]
            rectList.push(<Shapes style2={shapPeroperty} ></Shapes>)
        }

        return (<Row>
            {rectList}
        </Row>)
    }




}
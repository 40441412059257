import { Button, Col, Layout, Popconfirm, Row, Space } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import strings from '../../resource/i18n_resource';
import STYLES from '../../types/box_styles';
import CommonConstants from '../common_constant';
import * as ExamConfig from "../exam/exam_config";
import '../index.css';
import { QuestionNumber } from '../question_number';
import UserSession from '../user_session';
import StopwatchUI from '../util/stopwatch_ui';
import AppConstant from './app_constant';
import ExamPageMiddle from './exam_page_middle';
import ExitExamDialog from './exit_exam_dialog';
import FlowManager from './flow_manager';
import PageUtil from './page_util';
import UIPageRouterCommon from './ui_page_router_common';
import UserAnswerSheet from './user_answer_sheet';
import UIPageRouterCore from './ui_page_router_core';
const { Header, Content, Footer, Sider } = Layout;
class ExamPage extends React.Component {
    constructor(props) {
        super(props)
        console.log("props of number box")
        console.log(props)

        // var examSheet = PageUtil.retrievedExamSheet()
        // alert(examSheet)
        console.log("exam2____")
        // console.log(examSheet)
        // var currentExam = UserSession.getInstance().getUserExamSheet()

        this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPerviousPage = this.goToPerviousPage.bind(this);
        this.state = {
            'examLoadingStatusMessage': 'loading exam, please wait ...',
            'examLoadingStatus': 'No Started',
            'retryCount': 0
        }


    }
    refrehsPageForNextPrev() {
        this.setState({ 'retryCount': 0 })
    }
    notifyReady(examSheet) {
        if (examSheet.problemCount == 0) {
            this.setState(
                {
                    'examLoadingStatusMessage': 'Adjust your selection, no matching problem found',
                    'examLoadingStatus': 'No Problem Matched',
                    'retryCount': 0

                }
            )
        }
        else {
            this.setState(
                {
                    'examLoadingStatusMessage': 'Done',
                    'examLoadingStatus': 'Ready',
                    'retryCount': 0

                }
            )
        }
        // alert('ready')

    }
    notifyError(serverMessage) {
        var currentRetry = this.state.retryCount
        var retryMsg = 'loading exam, failed, try again: retry count =' + currentRetry
        var status = 'Failed'
        if (serverMessage != "") {
            retryMsg = serverMessage
        }
        // alert('ready')
        if (currentRetry > 10) {
            retryMsg = 'loading exam, failed more than 10 times. Try closing browser and start over again.' + currentRetry
            status = 'Fatal'
        }
        this.setState(
            {
                'examLoadingStatusMessage': retryMsg,
                'examLoadingStatus': status,
                'retryCount': currentRetry + 1

            }
        )

    }


    goToUserSelection() {
        // this.setState({ 'examLoadingStatusMessage': 'loading exam, please wait ...' })
        // alert('hi')
        UIPageRouterCommon.goToSelectionPage(this)

    }

    startExamForThis() {

        var self = this
        UIPageRouterCommon.startABrandNewExam(self)
    }
    componentDidMount() {
        console.log("This props---->")
        console.log(this.props)
        console.log(this.props.history.location)
        console.log(this.props.location.pageAction)
        console.log("This props action ---->")
        console.log(this.props.history.location.action)
        var pageAction = this.props.location.pageAction
        console.log("pageAction--->")
        console.log(pageAction)

        if (pageAction == AppConstant.EXAM_CREATED ||
            pageAction == AppConstant.RETRY_EXAM_CREATED ||
            pageAction == AppConstant.EXAM_EVALUATED ||pageAction==AppConstant.LOAD_EXAM_RESPONSE   

        ) {
            console.log("Exam is finally created, let us start")
        }

        else {

            this.startExamForThis()
        }




    }


    updateAnswerValue(examSheet, problemRef) {
        return (value) => {
            UserAnswerSheet.updateUserAnswerFor(examSheet.userAnswerSheet, problemRef, value)

            // examSheet.userAnswerSheet.updateUserAnswer(problemRef, value)

        }
    }




    okToEvaluate() {
        UIPageRouterCommon.submitForEvaluation(this)
    }
    
    getStudentRef() {
        return CommonConstants.STUDENT_REF;
    }

    buildVisibleRows() {
        let currentExam = UserSession.getInstance().getCurrentExam()

        // let quest = currentExam.getExamSheet()
        if (!currentExam || !currentExam.getExamSheet() || currentExam.getQuestionPage() == null) {
            return
        }
        let examSheet = currentExam.getExamSheet()
        var questionPage = currentExam.getQuestionPage()
        var problems = questionPage.getView()

        var userAnswerSheet = examSheet.userAnswerSheet
        var questionCount = problems.length
        var totalProblemCount = questionPage.getTotalCount()
        console.log("questionCount " + questionCount)
        console.log("problems ")
        console.log(problems)


        var questionNumber = questionPage.getStartQuestionNumber() + 1;
        var rows = []
        for (var count = 0; count < questionCount; count++) {
            var element = problems[count]
            console.log("element")
            console.log(element)
            var problemRef = element.problemRef.ref
            // console.log(element);
            var userAnswerValueInput = CommonConstants.EMPTY_ANSWER
            var userAnswer = UserAnswerSheet.findUserAnswerFor(userAnswerSheet, problemRef)
            if (userAnswer.attempted) {
                userAnswerValueInput = userAnswer.userAnswerValue
            }

            rows.push(
                <Row >
                    <Col style={{ fontSize: 20, marginRight: '10px', justifyContent: 'center' }}>
                        <QuestionNumber questionNumber={questionNumber++} questionCount={totalProblemCount} />
                    </Col>

                    {this.buildProblem(problemRef, userAnswer, element, examSheet)}
                </Row>

            )
        }

        return rows
    }
    buildProblem(problemRef, userAnswer, currentProblem, examSheet) {

        return (
            <ExamPageMiddle problemRef={problemRef} examSheet={examSheet} userAnswer={userAnswer} currentProblem={currentProblem} actionNameCallbackFn={() => this.updateAnswerValue(examSheet, problemRef)}></ExamPageMiddle>
        )
    }
    buildNumberRowsData(currentProblem) {
        var numbers = []
        numbers.push(currentProblem._firstRow)
        numbers.push(currentProblem._secondRow)
        numbers.push(currentProblem._thirdRow)
        numbers = numbers.flat()
        return numbers
    }

    goToNextPage() {
        // alert(this)
        UserSession.getInstance().getCurrentExam().getQuestionPage().goToNextPage()
        this.refrehsPageForNextPrev()

    }
    goToPerviousPage() {
        UserSession.getInstance().getCurrentExam().getQuestionPage().goToPreviousPage()
        this.refrehsPageForNextPrev()

    }




    pagerComponent() {
        const currentPage = UserSession.getInstance().getCurrentExam().getQuestionPage().getCurrentPage();
        const totalPage = UserSession.getInstance().getCurrentExam().getQuestionPage().getPageCount();
        const isFirstPage = currentPage == 1
        const isLastPage = (currentPage == totalPage)
        return (
            <>

                <Button disabled={isFirstPage} style={{ fontSize: '15px', height: '50px', margin: '0px' }} type="link" onClick={this.goToPerviousPage}>{strings.PREV_PAGE} {currentPage}/{totalPage}</Button>
                <Button disabled={isLastPage} style={{ fontSize: '15px', height: '50px', margin: '0px' }} type="link" onClick={this.goToNextPage}>{strings.NEXT_PAGE}</Button>
            </>
        )
    }

    render() {
        // alert('hi')
        if (PageUtil.isPageRefreshed()) {
            PageUtil.setToGoToHomePage(this)
            return (<div />)

        }
        if (this.state.examLoadingStatus == 'No Problem Matched') {
            return (
                <>

                    <div style={{ fontSize: '30px' }}>{this.state.examLoadingStatusMessage}</div>
                    <Button style={{ fontSize: '25px', height: '50px', width: '250px', marginLeft: '30px' }} type="primary" onClick={() => this.goToUserSelection()} >Selection Page >></Button>
                </>
            )

        }
        if (this.state.examLoadingStatus == 'Failed') {
            return (
                <>

                    <div style={{ fontSize: '30px' }}>{this.state.examLoadingStatusMessage}</div>
                    <Button style={{ fontSize: '25px', height: '50px', width: '200px', marginLeft: '30px' }} type="primary" onClick={() => this.startExamForThis()} >Click for Retry..</Button>
                </>
            )
        }
        var currentExam = UserSession.getInstance().getCurrentExam()
        // console.log(currentExam)
        // console.log(currentExam.getExamSheet())
        // console.log(currentExam.getQuestionPage())

        if (currentExam == null || currentExam.getExamSheet() == null || currentExam.getQuestionPage() == null) {
            return (
                <div style={{ fontSize: '30px' }}>{this.state.examLoadingStatusMessage}</div>
            )
        }
        var examSheet = currentExam.getExamSheet()
        // var questionPage = currentExam.getQuestionPage() 
        // let examSheet = examSheet /.getExamSheet()

        var msgText = strings.EXAM_IN_PROGRESS
        var msgText = ""




        var rowsForPage = this.buildVisibleRows()

        console.log("rowsForPage2")
        console.log(rowsForPage)
        // let alertText1=(<div>hello<br></br>hello</div>)
        // let alertText = (strings.EXAM_EXIT_CONFIRM)
        var examInProgress = examSheet.status == ExamConfig.EXAM_SHEET_STATUS.ATTEMPTING
        // var resultButtonText = examInProgress ? strings.EVALUATE_EXAM : strings.EVALUATE_EXAM
        // alert(examSheet.status)
        console.log("examSheet.status")
        console.log(examSheet)
        console.log("examSheet.status " + examSheet.status)
        console.log("examSheet.status " + examSheet.status)
        var levelSelected = UserSession.getInstance().determineProblemLevel()
        if (levelSelected == -1) {
            levelSelected = "Elementary"
        }
        return (
            <div style={STYLES.EXAM_PAGE_STYLE_MAIN}>
                <div style={{ verticalAlign: 'center' }}>
                    <Content style={{ margin: '0px 10px 0px 10px', alignSelf: 'center' }}>
                        <div style={{ fontSize: '20px', align: 'left' }}>{msgText} Level:{levelSelected} &nbsp;&nbsp;
                    <Space><ExitExamDialog /><StopwatchUI />{this.pagerComponent()} </Space>
                        </div>
                        {/* <div style={{ fontSize: '20px', align: 'left' }}>{msgText} Level Selected:{examSheet.userSelection.defaultLevel} <Space><ExitExamDialog /></Space></div> */}
                        {/* <Content>{this.pagerComponent()}</Content> */}

                        <Content style={{ margin: '0px', alignSelf: 'left' }}>
                            {rowsForPage}
                        </Content>

                    </Content>



                </div>
                <Content style={{ margin: '0', alignSelf: 'right', alignContent: 'right', alignItems: 'right' }, STYLES.MAIN_COLOR}>
                    {this.pagerComponent()}
                    {examInProgress &&
                        <Popconfirm placement="topLeft" title={<div>{strings.EVALUATE_CONFIRM}</div>} onCancel={() => this.okToEvaluate()} okText={strings.EXAM_EVALUATE_NO} cancelText={strings.EXAM_EVALUATE_YES}>
                            <Button style={{ fontSize: '15px', height: '50px', marginRight: '20px' }} type="primary"  >{strings.EVALUATE_EXAM}</Button>

                        </Popconfirm>}

                    {(!examInProgress) &&
                        <Button style={{ fontSize: '15px', height: '50px', marginRight: '20px' }} type="primary" onClick={() => UIPageRouterCore.goToUIRouterPage(this, AppConstant.GO_TO_RESULT_FOR_ALREDY_EVALUTED_EXAM)} >{strings.REVIEW_RESULT}</Button>
                    }
                    <ExitExamDialog />



                </Content>
            </div>
        )

    }
}


export default withRouter(({ location }) => {
    // const classes = useStyles();
    const history = useHistory();
    return (
        <ExamPage location={location} history={history} />
    )
});

import UserSession from "../user_session"
import AppRoutes from "../../app_routes"

export default class PageUtil {

    static isPageRefreshed() {
        let noExamInProgress = !UserSession.getInstance().isExamInProgress()
        let userSelectionDone = UserSession.getInstance().getUserSelection() !== undefined
        // alert(userSelectionDone)
        return !userSelectionDone
    }
    static getNextPage() {
        
        let authenticated = UserSession.getInstance().isAuthenticated()
        
        return UserSession.getInstance().isExamInProgress() ? AppRoutes.EXAM_PAGE_PATH: authenticated ? AppRoutes.USER_SELECTION_PATH:AppRoutes.ROOT_PATH
    }
    static setToGoToHomePage(self) {
        // alert(PageUtil.getNextPage())
        PageUtil.setGoToPage(self,PageUtil.getNextPage())
        // self.props.history.push({
        //     pathname: PageUtil.getNextPage(),
        // })
    }
    static setGoToPage(self, path) {
        // alert(PageUtil.getNextPage())
        self.props.history.push({
            pathname: path,
        })
    }
    
    
}

export default {
  PROBLEM_TYPE: {
    Number_Box: 'Number Box',
    Number_Cross: 'Number Cross',
    Logic_Matrix: 'Logic Matrix',

  },
  LOGITICA_HEADER_1: "LOGITICA:",
  LOGITICA_HEADER_2: "Number Puzzles",
  welcome: 'Select one of the following puzzles to continue',
  logitica_logo: 'Learn Logic & Math Together',
  wisgo_copyright: '**(c) Wisgo Limited**',

  Number_box_Description: "A **Number Box** consists of a box containing three _number-cells_ joined horizontally as shown below in Example-1. The middle number of each box is calculated by using the two numbers indicated on the left and the right side of the box.",
  Number_box_Description_1: "We have used a variety of arithmetical operations to build Number Box problems.",
  ParamScreen_Exam: "Exam",
  PAGE: "Page",
  ParamScreen_Practice: "Practice",
  ParamScreen_More: "More..",
  Level_1: 'Level-1',
  Level_2: 'Level-2',
  Level_3: 'Level-3',
  Level_4: 'Level-4',
  Level_5: 'Level-5',
  ParamScreen_Number_Of_Question: 'Number of Questions',
  ParamScreen_Enable_Timer: 'Enable Timer',
  ParamScreen_Enable_Rating: 'Enable Rating',
  ParamScreen_POSITIVE_NUMBER: 'Positive number only',
  Test_Find_Missing: 'Find the missing number.',
  Continue: 'Continue',
  Reivew_Your_Answer: ' Review your answer',
  NEXT_BUTTON: 'Next',
  PREV_BUTTON: 'Prev',
  REVIEW_BUTTON: 'Review',
  EVALUATE_BUTTON: 'Evaluate',
  SHOW_SUMMARY_BUTTON: 'Show Summary',
  HOME_BUTTON: 'Home',
  CONFIRM_BUTTON: 'Confirm',
  CANCEL_BUTTON: 'Cancel',
  OK_BUTTON: 'OK',
  ATTEMPTED_YES: 'Yes',
  ATTEMPTED_NO: 'No',
  REVIEW_HEADER: 'Review?',
  YOUR_ANSWER_HEADER: 'Your Answer',
  CORRECT_ANSWER_HEADER: 'Correct',
  EXIT_CONFIRM_MSG: 'Are you sure you want to exit?',
  EXIT_EXAM_CONFIRM_MSG: 'Are you sure you want exit this exam?',
  ADDITION: 'Addition',
  ADDITION_POSITIVE_NUMBER: 'Addition (Positive Numbers)',
  SUBTRACTION: 'Subtraction',
  MULTIPLICATION: 'Multiplication',
  DIVISION: 'Division',
  MIXED_OPERATION: 'Mixed Operations',
  SUM_OF_SQUARES: 'Sum of Squares',
  ABOUT_NUMBER_BOX_PAGE: "About Number Box",
  ABOUT_NUMBER_CROSS_PAGE: "About Number Cross",
  ABOUT_NUMBER_PYRAMID_PAGE: "About Number Pyramid",
  COPYRIGHT_PAGE: "Copyright",
  DISCLAIMER_PAGE: "Disclaimer",
  TOTAL_NUMBER_OF_QUESTION: 'Total Questions',
  ATTEMPTED: 'Attempted',
  CORRECT: 'Correct',
  CORRECT_YN: 'Correct?',
  TIME_SECONDS: 'Time (seconds)',
  SCORE_PERCENTAGE: 'Score(%)',
  SCORE: 'Score',
  RATING: 'Rating',
  RESULT: 'Result',
  RESULT_ITEM: 'Item',
  RESULT_DETAIL: 'Detail',
  TRY_NEXT: 'Try another',
  REVIEW_COMMENT: 'Review Comment',
  SKIPPED_QUESTION_MSG: 'You skipped this question.',
  BACK_BUTTON: 'Back',
  GO_TO_HOME_MSG: 'Are you sure you want to go to home page? Once you click ok, you will loose your answers.',
  CONFIRM_TO_EVALUATE_MSG: 'Are you sure you want to  evaluate your answer? Once you click ok, you are not able to change your answer.',
  START_BUTTON: 'START',
  PREPARING_QUESTIONS: 'Processing..',
  PREPARING_QUESTIONS_MSG: 'Creating questions, please wait...',
  CLICK_START_CONTINUE: 'Click on Start to continue.',
  FOR_PAID_VERSION_ONLY_LABEL: ' (For paid version only)',
  PURCHASE_FROM_APP_STORE: 'Purchase from App Store ',
  PURCHASE_FROM_GOOGLE_PLAY_STORE: 'Purchase from Google Play Store',
  CLICK_TO_BUY_FULL_VERSION: 'Click to purchase the full version.',
  DISMISS_BUTTON: 'Dismiss',
  CLICK_FOR_EVALUATION_MESSAGE: 'Click on Evaluate button to check your answers.',
  ANSWER_CORRECT: 'Your answer was correct:',
  ANSWER_WRONG: 'Your answer was wrong:',
  NUMBER_BOX_EXPLANATION_5: "As you solve more Number Box problems,"
    + "you will see that it involves different types of arithmetic operations,"
    + "e.g. addition, subtraction, multiplication, the sum of squares, etc.",
  FOR_EXAMPLE: 'For example:',
  AND_SO_ON: ', and so on.',
  NUMBER_BOX_EXPLANATION_6: " (1) Here a and b refer to the numbers in the left and right cells of the box.",
  NUMBER_BOX_EXPLANATION_7: ' (2) We use "^" to indicate exponent. For example: 2^3 = 2 x 2 x 2 = 8.',
  EXAMPLE: 'Example',
  EXPLANATION_OF_MIDDLE_NUMBER: 'Explanation of the middle numbers:',
  EXPLANATION_OF_CENTER_NUMBER: 'Explanation of the number in the center:',
  NUMBER_PYRAMID_ROW_3: "Row-3",
  NUMBER_PYRAMID_ROW_4: "Row-4",
  NUMBER_PYRAMID_ROW_5: "Row-5",
  NUMBER_PYRAMID_ROW_3_POSITIVE: "Row-3 (Positive numbers)",
  NUMBER_PYRAMID_ROW_4_POSITIVE: "Row-4 (Positive numbers)",
  NUMBER_BOX_FIRST_ROW: "First Box",
  NUMBER_BOX_SECOND_ROW: "Second Box",
  NUMBER_BOX_THIRD_ROW: "Third Box",
  EXPLANATION_SOLUTION_MSG: 'And that is our answer. Hence the missing number is',
  NUMBER_CROSS_FIRST: 'First Cross',
  NUMBER_CROSS_SECOND: 'Second Cross',
  NUMBER_CROSS_THIRD: 'Third Cross',
  NUMBER_CROSS_EXPLANATION_6: 'A Number Cross is a structure consisting of ﬁve numbers that are arranged in a cross-like shape as shown in ￼the figure below.' +
    'The middle number of each Cross Number is calculated by using the four numbers present in the cross.' +
    'We have used a variety of arithmetical operations to build Number Cross problems. ',
  NUMBER_CROSS_EXPLANATION_7: 'We use "^" to indicate exponent. For example: 2^3 = 2 x 2 x 2 = 8.',
  NUMBER_CROSS_EXPLANATION_8: 'If we denote l, t, r, b as number on the left, top, right, bottom side of a cross, then the number in the center can be determined by any one of the following operations depending on the problem:',
  NUMBER_CROSS_EXPLANATION_9: 'As you practice with more Number Cross problems, you will see that it involves different types of arithmetic operations, e.g. addition, subtraction, multiplication, the sum of squares, etc.',
  NUMBER_CROSS_EXPLANATION_10: 'The number in the center of a cross is determined by applying some combinations of arithmetic operations on the four surrounding numbers. A Number Cross problem consists of three crosses, and all the numbers in the first two crosses have been provided. Using this information, we need to find the missing number in the third cross. As a rule, the logic to determine the number in the center must be the same for all of the crosses.',
  REVIEW_PAGE_NUMBER_BOX_MISSING_NUMBER: 'The missing number is:',
  REVIEW_PAGE_NUMBER_CROSS_MISSING_NUMBER: 'The missing number is:',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y: 'Assume the missing number is y.',
  NUMBER_PYRAMID_ASSUME_MISSING_NUMBER_Y_AND_Z: 'Assume the missing numbers are y and z.',
  NUMBER_PYRAMID_USING_3_ROW_FORMULA: 'Using 3-Row formula:',
  NUMBER_PYRAMID_USING_4_ROW_FORMULA: 'Using 4-Row formula:',
  NUMBER_PYRAMID_USING_5_ROW_FORMULA: 'Using 5-Row formula:',
  NUMBER_PYRAMID_SHOW_CORRECT_ANSWER: 'Show correct answer',
  NUMBER_PYRAMID_SHOW_MY_ANSWER: 'Show my answer',
  NOTE: 'Note',
  NUMBER_PYRAMID_ABOUT: "A Number Pyramid is a collection of cells arranged in a pyramid. Each cell in the pyramid contains a number, which is the sum of two numbers in the cells directly below it. In a pyramid problem, we need to find the missing numbers.",
  GO_TO_NUMBER_PYRAMID_MSG: 'Go to "**About Number Pyramid**" to learn more about the topic.',
  NUMBER_PYRAMID_A_3_ROW: 'A 3-Row Pyramid',
  NUMBER_PYRAMID_A_4_ROW: 'A 4-Row Pyramid',
  NUMBER_PYRAMID_FORMULA_FOR_A_3_ROW: 'Formula for a 3-Row Pyramid',
  NUMBER_PYRAMID_FORMULA_FOR_A_4_ROW: 'Formula for a 4-Row Pyramid',
  EXPLANATION: 'Explanation',
  NUMBER_PYRAMID_EXPLANATION_5: 'As you solve more Number Pyramid problems, you will learn about solving simple linear equations.',
  NUMBER_PYRAMID_ROW_2: 'Row-2',
  NUMBER_PYRAMID_ROW_3: 'Row-3',
  FORMULA_FOR_3_ROW_MSG: 'Formula for 3 Row is shown below:',
  FORMULA_FOR_4_ROW_MSG: 'Formula for 4 Row is shown below:',
  NUMBER_PYRAMID_MSG_1: 'The number in any cell is the sum of the two numbers below it, as shown below.',
  NUMBER_PAYRMID_MSG_2: 'For more details, click: continue-> More..->About Number Pyramid',
  EXAM_RESULT_QUESTION_COUNT: 'Question #',
  EXAM_RESULT_PROBLEM_TYPE: 'Type',
  EXAM_RESULT_PROBLEM_LEVEL: 'Level',
  EXAM_RESULT_ATTEMPTED_COUNT: 'Attempted',
  EXAM_RESULT_CORRRECT_COUNT: 'Correct',
  EXAM_RESULT_TIME_TAKEN: 'Time(Sec)',
  EXAM_RESULT_CORRRECT_PERCENTAGE: 'Correct(%)',
  PROBLEM_TRY_SOLVE_INST: 'Solve the following problems',
  NEXT_PAGE: 'Next>>',
  PREV_PAGE: '<<Prev',
  START_EXAM: 'Start Exam',
  EXAM_IN_PROGRESS: 'Exam in progress..',

  SELECT_PROBLEM:'Select Type',
  SELECT_LEVEL:'Select Level',
  SELECT_PROBLM_COUNT:'# of Problems',
  REVIEW_ANSWER: 'Review your answer',
  LOGIN_TO_SITE: 'Login',
  CANCEL_EXAM: 'Cancel this exam',
  START_A_NEW_EXAM: 'New Exam',
  RETRY_THIS_EXAM: 'Retry this',
  MY_EXAM_HISTORY: 'My Exam History',
  EVALUATE_EXAM: 'Evaluate Exam',
  REVIEW_RESULT: 'Review Result',
  EXAM_EXIT_CONFIRM: 'Do you want to exit this exam?'
    + ' If you exit, your questions/answers will be erased.',
  EXIT_EXAM_YES: 'Exit Exam',
  EXIT_NO: 'No',
  EVALUATE_CONFIRM:'Do you want to evaluate? Once you press ok, you cannot change your answer.',
  EXAM_EVALUATE_YES: 'Evaluate',
  EXAM_EVALUATE_NO: 'No',

};
import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';
// import './node_modules/antd/dist/antd.css';
import '../../../index.css'
import NUMBER_BOX_STYLES  from '../../box_styles';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
import { Layout, Menu, Breadcrumb,Row,Col } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Input,InputNumber } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import NumericInputUI from './number_input';
const { Header, Content, Footer, Sider } = Layout;


export default class EachNumberBoxEvaluated extends React.Component {
    render() {
      let isCorrect = this.props.userAnswer.correctAnswer
      let isAttempted = this.props.userAnswer.attempted
      let userAnswerValueInput = this.props.userAnswer.userAnswerValue
      let hint = 'Hint:'+this.props.solutionHint
      let initialMsg = 'Your answer '+userAnswerValueInput+' is incorrect.'+hint
      let message =ReactHtmlParser('<div style="font-size: 15px; color:red;">'+initialMsg+'</div><br/><br/>') 
      let messageText = initialMsg;
      let color_value = {'color':'red'}
      if(!isAttempted){
         message=ReactHtmlParser('<div style="font-size: 15px; color:#800020; margin-top:"20px"">You skipped this question.'+hint+'</div><br/><br/>')
         messageText='You skipped this question.'+hint
         color_value={'color':'#800020'}
      }
      else if(isCorrect){
        message=ReactHtmlParser('<div style="font-size: 15px; color:green;">Your answer is correct</div><br/><br/>')
        messageText='Your answer is correct'
        color_value={'color':'green'}
      }
     
      
        return (<>
                <Input disabled style={NUMBER_BOX_STYLES.BOX_STYLES_LEFT_EVALUATED} value={this.props.left}></Input>
                <Input disabled style={NUMBER_BOX_STYLES.BOX_STYLES_MIDDLE_EVALUATED} value={this.props.middle}></Input>
                <Input disabled style={NUMBER_BOX_STYLES.BOX_STYLES_RIGHT_EVALUATED} value={this.props.right}></Input>
               <div style={color_value}>{messageText}</div>
               {}
               <br></br>
              </>)
       
    }
}

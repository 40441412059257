
// export const API_URL = "serv"; //TODO for prod
// export const API_URL = "";
export default class ApiHelper {

    static get_api_path() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return ""
        } else {
            return "serv"
        }
    }


}

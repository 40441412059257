import CommonConstants from '../common_constant'
import QuestionPage from '../question_page'
import * as ExamConfig from '../exam/exam_config'
import ExamSheet from "../exam_sheet";
import Problem from "../screen/problem";

export default class CurrentExam {
    private readonly _examSheet: ExamSheet;
    private _timeTakenMin: number;
    private readonly _questionPage: QuestionPage;
    private _timeTakenSec: number;
    private _problems: Problem[] = []

    constructor(_examSheet: ExamSheet, _timeTakenMin: number, _timeTakenSec: number) {
        this._examSheet = _examSheet
        this._problems = _examSheet == null ? [] : Object.values(_examSheet.problemSheet.internalMap)
        console.log("this._problems")
        let count = this._examSheet == null ? 0 : _examSheet.countOfProblems
        var problemPerPage = ExamConfig.COMMON_CONSTANTS.PROBLEM_PER_PAGE
        if (count > 0) {
            let problemType = _examSheet.userSelection.findDefaultProblemType()
            problemPerPage = ExamConfig.COMMON_CONSTANTS.PROBLEM_PER_PAGE_MAP[problemType]
        }
        console.log(this._problems)
        this._questionPage = new QuestionPage(this._problems, problemPerPage)
        this._timeTakenMin = _timeTakenMin
        this._timeTakenSec = _timeTakenSec
    }

    getExamSheet() {
        return this._examSheet
    }

    getQuestionPage() {
        return this._questionPage
    }

    setTime(m: number, s: number) {
        this._timeTakenMin = m
        this._timeTakenSec = s
    }

    getTimeTakenMin() {
        return this._timeTakenMin
    }

    getTimeTakenSec() {
        return this._timeTakenSec
    }

    isEvaluated() {
        return this._examSheet != null && (this._examSheet.status == ExamConfig.EXAM_SHEET_STATUS.EVALUATED || this._examSheet.status == ExamConfig.EXAM_SHEET_STATUS.COMPLETED)
    }

    isStarted() {
        return this._examSheet != null && this._examSheet.status == ExamConfig.EXAM_SHEET_STATUS.ATTEMPTING
    }

    isNotStarted() {
        return this._examSheet != null
    }

}